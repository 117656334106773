import React from "react";
import { useTranslation } from "react-i18next";
import PanelScafoldingPrivate from "../../../PanelScafolding/components/private/PanelScafoldingPrivate";
// import styles from "./profile.module.scss";

type IProfileProps = {};

const Profile: React.FC<IProfileProps> = (props) => {
  const { t } = useTranslation();

  return (
    <PanelScafoldingPrivate>
      <h1>{t("Profile")}</h1>
    </PanelScafoldingPrivate>
  );
};

Profile.defaultProps = {};

export default Profile;
