import cx from "classnames";
import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { history } from "../../../../services/history";
import loginStyles from "./Login.module.scss";
import * as loginConstants from "../../loginConstants";
import LoginQR from "../../components/LoginQR";
import { ReactComponent as AppleBrandButtonIcon } from "../../../../assets/modalImages/appleBrandButton.svg";
import { ReactComponent as GoogleBrandButtonIcon } from "../../../../assets/modalImages/googleBrandButton.svg";
import { ReactComponent as ScanButtonIcon } from "../../../../assets/modalImages/scanButtonIcon.svg";
import styles from "./Login.module.scss";
import PanelScafoldingPublic from "../../../PanelScafolding/components/public/PanelScafoldingPublic";
import GaImage from "../../../../components/atoms/Image/Image";
import { loginLink } from "../../loginConstants";
import { gaColors } from "../../../../data/display";
import { gaImages } from "../../../../data/images";
import { setSelectedRoute } from "../../../PanelScafolding/store/scafoldingActions";
import { deleteSession } from "../../../Settings/Profile/store/sessionActions";
import { showNotification } from "../../../Notification/store/notificationActions";

type ILoginProps = {};

const Login: React.FC<ILoginProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToLoginAndDeleteSession = () => {
    dispatch(setSelectedRoute("login"));
    dispatch(deleteSession());
    history.push("/login");
  };
  const notifyNoPermissionsAndLogout = () => {
    goToLoginAndDeleteSession();
    dispatch(showNotification("login.noAccessPermissions", "error", ""));
  };

  useEffect(() => {
    goToLoginAndDeleteSession();
    dispatch(setSelectedRoute("login"));
  }, []);

  return (
    <PanelScafoldingPublic link={loginLink}>
      <div className={styles.sections}>
        <div className={loginStyles.qrModule}>
          <div className={loginStyles.infoContainer}>
            <GaImage image={gaImages?.unlockImg} />

            <p className={cx("heading3")}>
              <Trans
                defaults={"login.headline"}
                values={{
                  studio: "Gataca Studio",
                }}
                components={{
                  bold: <span />,
                }}
              />
            </p>
            <p
              className={cx("bodyBoldLG neutral700 marginBottom2 marginTop30")}
            >
              {"1."}
              <span className={cx("bodyRegularLG neutral700")}>
                <Trans
                  i18nKey={"login.subHeadline"}
                  values={{
                    walletName: "Gataca Wallet",
                  }}
                />
              </span>
            </p>

            <div className={cx("margin-top", loginStyles.marketsContainer)}>
              <div>
                <a
                  href={loginConstants.marketIosURI}
                  target="_blank"
                  className={`${styles.marketButton} ${cx("marginRight12")}`}
                >
                  <AppleBrandButtonIcon />
                </a>
                <a
                  className={`${styles.marketButton}`}
                  href={loginConstants.marketAndroidURI}
                  target="_blank"
                >
                  <GoogleBrandButtonIcon />
                </a>
              </div>
            </div>
            <p
              className={cx(
                "bodyBoldLG neutral700 marginBottom22 marginTop22",
                styles.scanStep
              )}
            >
              {"2."}
              <span className={cx("bodyRegularLG neutral700")}>
                {t("login.secondSubHeadline")}
              </span>
              <ScanButtonIcon />
            </p>
            <p className={cx("bodyBoldLG neutral700")}>
              {"3."}
              <span className={cx("bodyRegularLG neutral700")}>
                {t("login.thirdSubHeadline")}
              </span>
            </p>
          </div>
          {/* TODO: Uncomment when login by email is available
          <Link className={loginStyles.emailLoginLink} to={loginLink.route}>{t(loginLink.label)}</Link> */}
        </div>
        <div className={styles.qrSection}>
          <LoginQR
            qrColor={gaColors?.primary700}
            logoSrc={gaImages?.logoPlatform?.src}
            notifyNoPermissionsAndLogout={notifyNoPermissionsAndLogout}
          ></LoginQR>
        </div>
      </div>
    </PanelScafoldingPublic>
  );
};

Login.defaultProps = {};

export default Login;
