import { NumberFormatOptions } from "../interfaces/interfaces";

export enum ActionStatusTypes {
  loading = "loading",
  success = "success",
  failed = "failed",
  neutral = "neutral",
}

export enum subscriptionStatusOptions {
  active = "active",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  canceled = "canceled",
  trialing = "trialing",
  unpaid = "unpaid",
}

export const subscriptionStatusDropdownOptions = {
  active: {
    text: "clients.active",
    options: [{ text: "clients.suspend", value: "SUSPENDED", color: "grey" }],
    color: "green",
  },
  incomplete: {
    text: "clients.incomplete",
    options: undefined,
    color: "yellow",
  },
  incomplete_expired: {
    text: "clients.incompleteExpired",
    options: undefined,
    color: "yellow",
  },
  past_due: {
    text: "clients.pastDue",
    options: undefined,
    color: "grey",
  },
  canceled: {
    text: "clients.canceled",
    options: [{ text: "clients.activate", value: "ACTIVATE", color: "green" }],
    color: "grey",
  },
  trialing: {
    text: "clients.trialing",
    options: undefined,
    color: "green",
  },
  unpaid: {
    text: "clients.unpaid",
    options: [{ text: "clients.activate", value: "ACTIVATE", color: "green" }],
    color: "red",
  },
};

export enum extProviderActionsTypes {
  cancel = "cancel",
  activate = "activate",
}

export const subscriptionExtProviderOptions = {
  active: {
    text: "general.cancel",
    action: undefined,
    /* TODO DEV-3869  */
    // action: extProviderActionsTypes.cancel,
    color: "red",
  },
  incomplete: {
    text: "clients.activate",
    action: extProviderActionsTypes.activate,
    color: "purple",
  },
  incomplete_expired: {
    text: "clients.incompleteExpired",
    action: undefined,
    color: undefined,
  },
  past_due: {
    text: "clients.activate",
    action: extProviderActionsTypes.activate,
    color: "purple",
  },
  canceled: {
    text: "clients.reactivate",
    action: extProviderActionsTypes.activate,
    color: "purple",
  },
  /* TODO DEV-3869  */
  trialing: {
    text: "general.cancel",
    action: undefined,
    // action: extProviderActionsTypes.cancel,
    color: "red",
  },
  unpaid: {
    text: "clients.activate",
    action: extProviderActionsTypes.activate,
    color: "purple",
  },
};

export const orgServicesEndpoints = [
  {
    id: "Connect Saas",
    type: "Verifier",
    serviceEndpoint: process.env["REACT_APP_CONNECT_HOST"] || "",
  },
  {
    id: "Certify Saas",
    type: "Issuer",
    serviceEndpoint: process.env["REACT_APP_CERTIFY_HOST"] || "",
  },
];

export const formatNumbersDefaultOptions: NumberFormatOptions = {
  notation: "compact",
  compactDisplay: "short",
  minimumFractionDigits: 0,
};
