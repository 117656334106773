import * as React from "react";
import cx from "classnames";
import { ButtonModel } from "../../../../interfaces/interfaces";
import styles from "./redButton.module.scss";

const RedButton: React.FC<ButtonModel> = (props) => {
  const {
    label,
    outlined,
    size,
    icon,
    IconComponent,
    className,
    disabled,
    action,
  } = props;

  const sizeFontClass = {
    LG: "buttonLG",
    MD: "buttonMD",
    SM: "buttonSM",
  };

  const sizeClass = {
    LG: styles.large,
    MD: styles.medium,
    SM: styles.small,
  };

  return (
    <button
      className={`${outlined ? styles?.outlined : styles?.filled}
      ${cx(sizeClass[size || "LG"])} 
      ${
        disabled
          ? outlined
            ? styles.outlinedDisabled
            : styles.filledDisabled
          : undefined
      }  
      ${styles.redButton} ${className && className} `}
      onClick={action}
      disabled={disabled}
    >
      <span className={cx(sizeFontClass[size || "LG"])}>{label}</span>
      {icon && <img src={icon} alt={""} />}
      {IconComponent && IconComponent}
    </button>
  );
};

export default RedButton;
