import React, { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import "./App.scss";
import Profile from "./features/Settings/Profile/views/Profile";
import Clients from "./features/Clients/views/list/Clients";
import PlatformConfig from "./features/PlatformConfig/views/PlatformConfig";
import Home from "./features/Home/views/Home";
import Login from "./features/Login/views/Login/Login";
import EmailLogin from "./features/Login/views/EmailLogin/EmailLogin";
import {
  deleteSession,
  readSession,
} from "./features/Settings/Profile/store/sessionActions";
import { setSelectedRoute } from "./features/PanelScafolding/store/scafoldingActions";
import { useDispatch, useSelector } from "react-redux";
import { sessionSelectors } from "./features/Settings/Profile/store";
import { history } from "./services/history";
import CustomRouter from "./components/elements/navigation/CustomRouter/CustomRouter";
import { userHasScopes } from "./utils/permissionsUtil";
import { showNotification } from "./features/Notification/store/notificationActions";
import ClientDetail from "./features/Clients/views/detail/ClientDetail";
import TierDetail from "./features/PlatformConfig/views/detail/TierDetail";

const App: React.FC<any> = () => {
  const dispatch = useDispatch();
  const user = useSelector(sessionSelectors.getUserEmail_s);
  const dataloaded = useSelector(sessionSelectors.getSessionRead);
  const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes);

  const userCanReadProviders = () => {
    return (
      allowedScopes?.length && userHasScopes(["readProviders"], allowedScopes)
    );
  };

  const goToHome = () => {
    dispatch(setSelectedRoute("home"));
    history.push("/home");
  };
  const goToLoginAndDeleteSession = () => {
    dispatch(setSelectedRoute("login"));
    history.push("/login");
    dispatch(deleteSession());
  };
  const notifyNoPermissionsAndLogout = () => {
    goToLoginAndDeleteSession();
    dispatch(showNotification("login.noAccessPermissions", "error", ""));
  };

  const initApp = async () => {
    if (!!user && allowedScopes) {
      userCanReadProviders() ? goToHome() : notifyNoPermissionsAndLogout();
    } else {
      goToLoginAndDeleteSession();
    }
  };

  useEffect(() => {
    if (!dataloaded) {
      dispatch(readSession());
    }
    dataloaded && history.location?.pathname !== "/status" && initApp();
  }, [dataloaded]);

  return (
    <CustomRouter history={history}>
      <I18nextProvider i18n={i18n}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/email-login" element={<EmailLogin />} />
          {/* <Route path="/status" element={<Status/>} /> */}

          <Route path="/home" element={<Home />} />
          <Route path="/clients" element={<Outlet />}>
            <Route index element={<Clients />} />
            <Route path=":clientName" element={<ClientDetail />}></Route>
          </Route>
          <Route path="/platform-configuration" element={<Outlet />}>
            <Route index element={<PlatformConfig />} />
            <Route path=":tierName" element={<TierDetail />}></Route>
            <Route
              path="/platform-configuration/newTier"
              element={<TierDetail />}></Route>
          </Route>

          <Route path="/settings" element={<Profile />} />
        </Routes>
      </I18nextProvider>
    </CustomRouter>
  );
};

export default App;
