import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { countriesAndStates, domains } from "../../../../../../data/forms";
import { initialLang } from "../../../../../../i18n";
import { showScreenLoaderAction } from "../../../../../Loader/loaderActions";
import styles from "./information.module.scss";
import { OrganizationModel } from "../../../../../../interfaces/interfaces";
import {
  defaultCountry,
  defaultCountryIndex,
} from "../../../../../../utils/formatDataUtil";
import { orgServicesEndpoints } from "../../../../../../data/common";
import { updateOrgAction } from "../../../../store/clientsActions";
import { getOrgCountryTranslated } from "../../../../../../utils/organizationUtil";
import ProfileForm from "./forms/profileForm/ProfileForm";
import InformationForm from "./forms/informationForm/InformationForm";

type IInformationProps = { org?: OrganizationModel };

const Information: React.FC<IInformationProps> = (props) => {
  const { org } = props;
  const dispatch = useDispatch();

  // States
  const [firstFormActive, setFirstFormActive] = useState(false);
  const [secondFormActive, setSecondFormActive] = useState(false);
  const [firstFormDataModified, setFirstFormDataModified] = useState(false);
  const [secondFormDataModified, setSecondFormDataModified] = useState(false);

  // Variables
  const orgInfo = org?.orgInfo;
  const orgBilling = org?.billingInfo;
  const orgAddress = orgBilling?.legalAddress;
  const defaultStates = defaultCountry?.states;
  const currentCountry = orgAddress
    ? orgAddress?.country
    : defaultCountry[("name_" + initialLang) as keyof typeof defaultCountry];
  const orgCountry = countriesAndStates.find((el) => {
    return (
      currentCountry === el.name_en ||
      currentCountry === el.name_es ||
      currentCountry === el.name_pt
    );
  });
  const orgCountryTranslated =
    getOrgCountryTranslated(defaultCountryIndex, orgCountry) || "";

  const emptyFirstFormData = {
    name: orgInfo?.name || "",
    legalName: orgBilling?.LegalName || "",
    domainName: orgInfo?.domainName || "",
    VATRegistration: org?.billingInfo?.vatRegistration || "",
    discoveryURL: orgInfo?.discoveryURL || "",
    photo: undefined,
  };
  const emptySecondFormData = {
    street: orgAddress?.street || "",
    suite: orgAddress?.suite || "",
    number: orgAddress?.number || "",
    locality: orgAddress?.locality || "",
    country: orgCountryTranslated || "",
    region: orgAddress?.region || orgCountry?.states[0] || "",
    postalCode: orgAddress?.postalCode || "",
  };

  // STATES
  const [states, setStates] = useState(orgCountry?.states || defaultStates);
  const [firstFormData, setFormState] = useState(emptyFirstFormData);
  const [secondFormData, setLegalAddressState] = useState(emptySecondFormData);

  const { name, legalName, domainName, VATRegistration, discoveryURL } =
    firstFormData;
  const { street, suite, number, locality, region, country, postalCode } =
    secondFormData;
  const firstFormIsValid = !!(
    name?.trim()?.length && domainName?.trim()?.length
  );

  // Effects
  useEffect(() => {
    setFormState(emptyFirstFormData), setLegalAddressState(emptySecondFormData);
  }, [org?.id]);

  useEffect(() => {
    if (JSON.stringify(firstFormData) === JSON.stringify(emptyFirstFormData)) {
      setFirstFormDataModified(false);
    }
  }, [firstFormData]);

  useEffect(() => {
    if (
      JSON.stringify(secondFormData) === JSON.stringify(emptySecondFormData)
    ) {
      setFirstFormDataModified(false);
    }
  }, [secondFormData]);

  useEffect(() => {
    if (
      JSON.stringify(secondFormData) === JSON.stringify(emptySecondFormData)
    ) {
      setSecondFormDataModified(false);
    }
  }, [secondFormData]);

  useEffect(() => {
    if (country && orgCountryTranslated && orgCountry) {
      orgCountry && setStates(orgCountry?.states);
      setLegalAddressState(emptySecondFormData);
    }
  }, [orgAddress]);

  useEffect(() => {
    setFormState({
      ...emptyFirstFormData,
      domainName: orgInfo?.domainName || domains[0]?.value || "",
    });
  }, [
    orgBilling?.vatRegistration?.length,
    orgInfo?.domainName?.length,
    domains,
  ]);

  // Functions
  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const currentValue =
      emptyFirstFormData[name as keyof typeof emptyFirstFormData];

    if (currentValue !== value) {
      !firstFormDataModified && setFirstFormDataModified(true);
    }

    setFormState({
      ...firstFormData,
      [name]: value,
    });
  };

  function handleAddressInputsChange(event: any) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const currentValue =
      emptySecondFormData[name as keyof typeof emptySecondFormData];

    if (currentValue !== value) {
      !secondFormDataModified && setSecondFormDataModified(true);
    }

    setLegalAddressState({
      ...secondFormData,
      [name]: value,
    });
  }

  function getStates(name: string) {
    setStates([]);
    const selectedCountry = countriesAndStates.find((el) => {
      return name === el.name_en || name === el.name_es || name === el.name_pt;
    });

    setStates(selectedCountry?.states || []);
    setLegalAddressState({
      ...secondFormData,
      country: name,
      region: selectedCountry?.states[0] || "",
    });
  }

  function updateWithFirstFormData() {
    dispatch(showScreenLoaderAction());
    const formattedOrg = buildFirstFormDataObject();
    dispatch(updateOrgAction(formattedOrg));
  }

  function updateWithSecondFormData() {
    dispatch(showScreenLoaderAction());
    const formattedOrg = buildSecondFormDataObject();
    dispatch(updateOrgAction(formattedOrg));
  }

  function buildFirstFormDataObject() {
    const orgAddress = org?.billingInfo?.legalAddress;
    const data = {
      id: org?.id || "",
      providerId: org?.providerId,
      orgInfo: {
        name: name,
        domainName: domainName,
        discoveryURL: discoveryURL,
        services: orgServicesEndpoints,
      },
      billingInfo: {
        legalAddress: {
          street: orgAddress?.street || "",
          suite: orgAddress?.suite || "",
          number: orgAddress?.number || "",
          locality: orgAddress?.locality || "",
          region: orgAddress?.region || "",
          country: orgAddress?.country || "",
          postalCode: orgAddress?.postalCode || "",
        },
        LegalName: legalName,
        vatRegistration: VATRegistration,
      },
    };
    return data;
  }

  function buildSecondFormDataObject() {
    const data = {
      id: org?.id || "",
      providerId: org?.providerId || "",
      orgInfo: {
        name: org?.orgInfo?.name || "",
        domainName: org?.orgInfo?.domainName || "",
        discoveryURL: org?.orgInfo?.discoveryURL || "",
        services: org?.orgInfo?.services,
      },
      billingInfo: {
        legalAddress: {
          street,
          suite,
          number,
          locality,
          region,
          country,
          postalCode,
        },
        LegalName: org?.billingInfo?.LegalName || "",
        vatRegistration: org?.billingInfo?.vatRegistration || "",
      },
    };
    return data;
  }

  // View
  return (
    <React.Fragment>
      <div className={styles.panels}>
        <ProfileForm
          formData={firstFormData}
          firstFormActive={firstFormActive}
          emptyFirstFormData={emptyFirstFormData}
          firstFormDataModified={firstFormDataModified}
          firstFormIsValid={firstFormIsValid}
          updateWithFirstFormData={updateWithFirstFormData}
          setFirstFormActive={setFirstFormActive}
          handleInputChange={handleInputChange}
          setFormState={setFormState}
        />

        <InformationForm
          formData={secondFormData}
          secondFormActive={secondFormActive}
          orgAddress={orgAddress}
          states={states}
          getStates={getStates}
          handleAddressInputsChange={handleAddressInputsChange}
          secondFormDataModified={secondFormDataModified}
          orgCountry={orgCountry}
          defaultStates={defaultStates}
          emptySecondFormData={emptySecondFormData}
          setLegalAddressState={setLegalAddressState}
          setSecondFormActive={setSecondFormActive}
          setFormState={setFormState}
          setStates={setStates}
          updateWithSecondFormData={updateWithSecondFormData}
        />
      </div>
    </React.Fragment>
  );
};

Information.defaultProps = {};

export default Information;
