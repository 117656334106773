import React from "react";
import cx from "classnames";
import Moment from "react-moment";
import { subscriptionExpDateColorByStatus } from "../../../../data/clients.constants";

type DateCellProps = {
  date?: string;
  className?: string;
};

export const DateCell: React.FC<DateCellProps> = (props) => {
  const { date, className } = props;

  return (
    <td>
      {date ? (
        <Moment
          className={cx(
            "buttonMD",
            className
              ? subscriptionExpDateColorByStatus[
                  className as keyof typeof subscriptionExpDateColorByStatus
                ]
              : "neutral1000"
          )}
          format="DD/MM/YYYY"
        >
          {date}
        </Moment>
      ) : (
        <span className={cx("buttonMD neutral1000")}>-</span>
      )}
    </td>
  );
};
