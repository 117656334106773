import {
    IScafoldingState,
    ScafoldingActions,
    ScafoldingActionsTypes,
} from './scafoldingTypes'

// NAVIGATION
const initialScafoldingState: IScafoldingState = {
    activeRoute: 'home',
    isSettingsPanel: false,
}

export function navigationReducer(
    state = initialScafoldingState,
    action: ScafoldingActions
): IScafoldingState {
    switch (action.type) {
        case ScafoldingActionsTypes.SET_ACTIVE_ROUTE:
            return {
                ...state,
                activeRoute: action.payload?.route,
            }
        case ScafoldingActionsTypes.SET_SETTINGS_PANEL:
            return {
                ...state,
                isSettingsPanel: action.payload?.settings,
            }
        default:
            return state
    }
}
