import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Transition from "react-transition-group/Transition";
import styles from "./notification.module.scss";
import { hideNotification } from "./store/notificationActions";
import ErrorNotification from "./notificationTypes/error/ErrorNotification";
import InfoNotification from "./notificationTypes/info/InfoNotification";
import SuccessNotification from "./notificationTypes/success/SuccessNotification";
import WarningNotification from "./notificationTypes/warning/WarningNotification";
import { activeRoute } from "../PanelScafolding/store/scafoldingSelectors";
import { getNotification } from "./store/notificationsSelectors";
import { INotificationItem } from "./store/notificationTypes";

const duration = 300;

const Notification = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  let defaultTimeOut = 4000;
  const notification = useSelector(getNotification);
  const currentRoute = useSelector(activeRoute);

  if (notification) {
    setTimeout(() => {
      dispatch(hideNotification());
    }, props.timeout || defaultTimeOut);
  }

  const getNotificationType = (notification: INotificationItem) => {
    let NotificationComponent = InfoNotification;

    if (notification && notification?.kind) {
      const kind = notification?.kind;
      switch (kind) {
        case "error":
          NotificationComponent = ErrorNotification;
          break;
        case "info" || "info-square":
          NotificationComponent = InfoNotification;
          break;
        case "success":
          NotificationComponent = SuccessNotification;
          break;
        case "warning" || "warning-alt":
          NotificationComponent = WarningNotification;
          break;
        default:
          NotificationComponent = InfoNotification;
      }
    }

    return (
      <NotificationComponent
        classname={`${styles.gaNotificationPanel} ${
          props?.className && props.className
        }`}
        title={notification?.title || ""}
        label={notification?.subtitle ? t(notification?.subtitle) : ""}
      />
    );
  };

  return (
    <Transition
      className={`${props?.className && props.className}`}
      in={notification ? true : false}
      timeout={
        notification?.duration && notification?.duration > 0
          ? notification?.duration
          : duration
      }
      unmountOnExit
    >
      {(notificationState: any) => (
        <div
          className={`${styles.gaNotification} ${styles[notificationState]}  ${
            currentRoute === "login" && styles.gaPublicNotification
          }
                    `}
        >
          {notification && getNotificationType(notification)}
        </div>
      )}
    </Transition>
  );
};

export default Notification;
