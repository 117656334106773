import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./subTiers.module.scss";
import LightPanel from "../../../../components/elements/panels/LightPanel/LightPanel";
import LicenseCard from "../../../../components/elements/cards/licenseCard/LicenseCard";
import cx from "classnames";
import { ButtonSizes } from "../../../../data/display";
import InfoPanel from "../../../../components/elements/panels/infoPanel/infoPanel";
import PurpleButton from "../../../../components/atoms/buttons/purple/PurpleButton";
import { ITierModel } from "../../../../interfaces/interfaces";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getTiers } from "../../store/tiersActions";
import { tiersActions, tiersSelectors } from "../../store";
import { ActionStatusTypes } from "../../../../data/common";
import { sessionSelectors } from "../../../Settings/Profile/store";
import CardListSkeleton from "../../../../components/elements/skeletons/cardListSkeleton/CardListSkeleton";

type ISubTiersProps = {};

const SubTiers: React.FC<ISubTiersProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selectors
  const loadingTiers =
    useSelector(tiersSelectors.getTiersStatus) === ActionStatusTypes?.loading;
  const tiers = useSelector(tiersSelectors.getSortedTiers);
  const tiersUnsroted = useSelector(tiersSelectors.getTiers);
  const providerId = useSelector(sessionSelectors.getCurrentTenant);

  // Effects
  useEffect(() => {
    dispatch(tiersActions.selectTier(undefined));
    !loadingTiers && dispatch(getTiers(providerId || ""));
  }, []);

  // Functions
  const editTier = (tier: ITierModel) => {
    dispatch(tiersActions.selectTier(tier));
  };

  const getTiersCardStyle = () => {
    const tiersNumber = tiers?.length;
    let tierClass: string | undefined = styles.twoTiersContainer;
    switch (tiersNumber) {
      case 1:
        tierClass = styles.oneTierContainer;
        break;
      case 3:
        tierClass = styles.threeTiersContainer;
        break;
      default:
        tierClass = styles.twoTiersContainer;
    }
    return tierClass;
  };

  const navigateToEditTier = () => {
    navigate("/platform-configuration/newTier");
  };

  return (
    <>
      <LightPanel className={styles.subTiers}>
        <div className={styles.subTiers__header}>
          <div>
            <p className={cx("heading5 neutral1000")}>
              {" "}
              {t("platformConfig.subcriptionTiers.title")}
            </p>
            <p className={cx("bodyRegularMD neutral700")}>
              {" "}
              {t("platformConfig.subcriptionTiers.description")}
            </p>
          </div>

          {tiers && tiers?.length < 4 ? (
            <PurpleButton
              size={ButtonSizes.MD}
              label={t("platformConfig.subcriptionTiers.createTier")}
              action={() => navigateToEditTier()}
            />
          ) : null}
        </div>

        <ul className={cx(getTiersCardStyle() && getTiersCardStyle())}>
          {!loadingTiers ? (
            tiers?.map((tier: any, index) => (
              <li className={styles.tier} key={"tier__" + index}>
                <LicenseCard
                  license={tier}
                  period={"month"}
                  showEditButton={true}
                  editLicense={editTier}
                />
              </li>
            ))
          ) : (
            <CardListSkeleton
              className={cx(styles.tier, styles.tierSkeleton)}
              cardsNumber={2}
            />
          )}
        </ul>

        <InfoPanel
          className={styles.infoPanel}
          label={
            <p className={cx("bodyRegularSM")}>
              <Trans
                i18nKey={"platformConfig.subcriptionTiers.infoPanelText"}
              />
            </p>
          }
          hasIcon={true}
        />
      </LightPanel>
    </>
  );
};

export default SubTiers;
