import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../tierDetail.module.scss";
import LightPanel from "../../../../../../components/elements/panels/LightPanel/LightPanel";
import cx from "classnames";
import GaTextInput from "../../../../../../components/elements/forms/formFields/textInput/GaTextInput";
import { gaColors } from "../../../../../../data/display";
import ChevronDownIcon from "../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon";

type ITierLimitsFormProps = {
  formData: {
    name: string;
    description: string;
    isPopular: boolean;
    monthlyHasTrialDays: boolean;
    yearlyHasTrialDays: boolean;
    priceMonthly: string;
    monthlyNumTrialDays: string;
    yearlyNumTrialDays: string;
    ebsiDids: boolean;
    priceYearly: string;
    index: string;
    yearlyDiscountPercentage: number;
  };
  limits: any;
  setLimits: (x?: any) => void;
  setFormState: (x?: any) => void;
};

const TierLimitsForm: React.FC<ITierLimitsFormProps> = (props) => {
  const { formData, limits, setFormState, setLimits } = props;
  const { t } = useTranslation();

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const {
    issuanceTemplates,
    issuedCredentials,
    apiKeys,
    activeUsers,
    verificationTemplates,
    dids,
  } = limits;

  const handleLimitsChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    value = value?.replace(/[^0-9\-]/g, "");
    value = value?.replace(/^0+/, "");
    setLimits({
      ...limits,
      [name]: {
        ...limits[name as keyof typeof limits],
        Value: value ? parseInt(value) : 0,
      },
    });
  };

  const handleAllowEbsiDIDsChange = () => {
    setFormState({ ...formData, ebsiDids: !formData?.ebsiDids });
  };

  return (
    <LightPanel>
      <div className={styles.tierDetail__header}>
        <div>
          <p className={cx("heading5 neutral1000")}>
            {" "}
            {t("platformConfig.tierDetail.subFeaturesTitle")}
          </p>
          <p className={cx("bodyRegularMD neutral700")}>
            {" "}
            {t("platformConfig.tierDetail.subFeaturesDescription")}
          </p>
        </div>
      </div>
      <form>
        <div className={styles.section}>
          <p className={cx("buttonSM")}>
            {t("platformConfig.tierDetail.verificationFeatures")}
          </p>
          <div className={styles.doubleColumn}>
            <GaTextInput
              name="verificationTemplates"
              invalid={false}
              labelText={
                "platformConfig.tierDetail.numberOfVerificationTemplates"
              }
              onChange={(e) => handleLimitsChange(e)}
              placeholder={t("platformConfig.tierDetail.selectQuantity")}
              type="text"
              value={verificationTemplates?.Value?.toString()}
            />
            <GaTextInput
              name="activeUsers"
              invalid={false}
              labelText={"platformConfig.tierDetail.numberOfActiveUsers"}
              onChange={(e) => handleLimitsChange(e)}
              placeholder={t("platformConfig.tierDetail.selectQuantity")}
              type="text"
              value={activeUsers?.Value?.toString()}
            />
          </div>
        </div>
        <div className={styles.section}>
          <p className={cx("buttonSM")}>
            {t("platformConfig.tierDetail.issuanceFeatures")}
          </p>
          <div className={styles.doubleColumn}>
            <GaTextInput
              name="issuanceTemplates"
              invalid={false}
              labelText={"platformConfig.tierDetail.numberOfIssuanceTemplates"}
              onChange={(e) => handleLimitsChange(e)}
              placeholder={t("platformConfig.tierDetail.selectQuantity")}
              type="text"
              value={issuanceTemplates?.Value?.toString()}
            />
            <GaTextInput
              name="issuedCredentials"
              invalid={false}
              labelText={"platformConfig.tierDetail.numberOfIssuedCredentials"}
              onChange={(e) => handleLimitsChange(e)}
              placeholder={t("platformConfig.tierDetail.selectQuantity")}
              type="text"
              value={issuedCredentials?.Value?.toString()}
            />
          </div>
        </div>
        <div className={styles.section}>
          <p className={cx("buttonSM")}>
            {t("platformConfig.tierDetail.otherFeatures")}
          </p>
          <div className={styles.doubleColumn}>
            <GaTextInput
              name="dids"
              invalid={false}
              labelText={"platformConfig.tierDetail.numberOfDids"}
              onChange={(e) => handleLimitsChange(e)}
              placeholder={t("platformConfig.tierDetail.selectQuantity")}
              type="text"
              value={dids?.Value?.toString()}
            />
            <GaTextInput
              name="apiKeys"
              invalid={false}
              labelText={"platformConfig.tierDetail.numberOfApiKeys"}
              onChange={(e) => handleLimitsChange(e)}
              placeholder={t("platformConfig.tierDetail.selectQuantity")}
              type="text"
              value={apiKeys?.Value?.toString()}
            />
          </div>
          <div className={`${styles.advancedOptions}`}>
            <p
              className={cx("buttonSM")}
              onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}>
              {t("general.advancedOptions")}
              <ChevronDownIcon
                className={`${styles.advancedOptionsToogle} ${
                  showAdvancedOptions && styles.toogleOpen
                }`}
                size={16}
                color={gaColors.primary700}
              />
            </p>
            {showAdvancedOptions ? (
              <div className={cx("marginTop20", styles.ebsiDidsContainer)}>
                <div className={`${cx(styles.popularTagContainer)}`}>
                  <div className={`${styles.popularTag}`}>
                    <div>
                      <p className={cx("buttonSM")}>
                        {t("platformConfig.tierDetail.ebsiDID")}
                      </p>
                      <p className={cx("bodyRegularXS neutral700")}>
                        {t("platformConfig.tierDetail.allowEbsiDids")}
                      </p>
                    </div>
                  </div>
                  <div className={styles.activationToggle}>
                    <input
                      checked={formData?.ebsiDids}
                      className={styles.activationCheckbox}
                      type="checkbox"
                      id="switch"
                    />
                    <label
                      onClick={(e) => handleAllowEbsiDIDsChange()}
                      className={styles.activationCheckboxLabel}></label>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </form>
    </LightPanel>
  );
};

export default TierLimitsForm;
