export enum LoaderActionTypes {
    SHOW_SCREEN_LOADER = 'loader/show_screen_loader',
    HIDE_SCREEN_LOADER = 'loader/hide_screen_loader',
}

export interface IShowScreenLoaderAction {
    type: LoaderActionTypes.SHOW_SCREEN_LOADER
}

export interface IHideScreenLoaderAction {
    type: LoaderActionTypes.HIDE_SCREEN_LOADER
}

export interface ILoaderState {
    showScreenLoader?: boolean
}

export type LoaderActions = IShowScreenLoaderAction | IHideScreenLoaderAction
