import {
  IPresentationRequestModel,
  OrganizationModel,
} from "../../../../interfaces/interfaces";
import {
  IDeleteSession,
  IGetUser,
  IHideTenantModal,
  ILoadSessionData,
  IReadSession,
  IReadSessionFailed,
  IReadSessionSuccess,
  ISetSession,
  ISetSessionApiKeys,
  ISetSessionSSIOperations,
  ISetUser,
  ISetUserOrg,
  IShowTenantModal,
  SessionActionTypes,
} from "./sessionTypes";

export const setSession = (signUp?: boolean): ISetSession => {
  return {
    type: SessionActionTypes.SET_SESSION,
    signUp: signUp,
  };
};

export const readSession = (): IReadSession => {
  return {
    type: SessionActionTypes.READ_SESSION,
  };
};

export const readSessionSucess = (tokenData: any): IReadSessionSuccess => {
  return {
    type: SessionActionTypes.READ_SESSION_SUCCESS,
    tokenExpiration: tokenData.exp,
    currentTenant: tokenData?.permissions?.tenant,
    scopesDetected: tokenData?.permissions?.scopes,
    email: tokenData?.sub,
    orgId: tokenData?.orgId,
    role: tokenData?.permissions?.role,
    licenses: tokenData?.licenses,
    dids: tokenData?.permissions?.dids,
    apiKeys: tokenData?.permissions.api_keys,
    ssiOperations: tokenData?.permissions.ssi_operations,
    tenant: tokenData?.permissions.tenant,
    allSsiOperationsAllowed:
      tokenData?.permissions?.ssi_operations?.includes("*"),
    allDidsAllowed: tokenData?.permissions?.dids?.includes("*"),
    allApiKeysAllowed: tokenData?.permissions?.api_keys?.includes("*"),
    allTenantsAllowed: tokenData?.permissions?.tenant === "*",
  };
};

export const readSessionFailed = (error: string): IReadSessionFailed => {
  return {
    type: SessionActionTypes.READ_SESSION_FAILED,
    error: error,
  };
};

export const getUser = (email: string): IGetUser => {
  return {
    type: SessionActionTypes.GET_USER,
    email: email,
  };
};

export const setUser = (user: string): ISetUser => {
  return {
    type: SessionActionTypes.SET_USER,
    user: user,
  };
};

export const setUserOrganization = (org: OrganizationModel): ISetUserOrg => {
  return {
    type: SessionActionTypes.SET_USER_ORGANIZATION,
    payload: {
      org: org,
    },
  };
};

export const loadSessionData = (): ILoadSessionData => {
  return {
    type: SessionActionTypes.LOAD_DATA,
  };
};

export const deleteSession = (): IDeleteSession => {
  return {
    type: SessionActionTypes.DELETE_SESSION,
  };
};

export const setSessionApiKeys = (apiKeys: string[]): ISetSessionApiKeys => {
  return {
    type: SessionActionTypes.SET_SESSION_APIKEYS,
    payload: {
      api_keys: apiKeys,
    },
  };
};

export const setSessionSSIOperations = (
  type: string,
  operations: IPresentationRequestModel[]
): ISetSessionSSIOperations => {
  return {
    type: SessionActionTypes.SET_SESSION_SSI_OPERATIONS,
    payload: {
      ssiOperations: operations,
      type: type,
    },
  };
};

export const showTenantModal = (): IShowTenantModal => {
  return {
    type: SessionActionTypes.SHOW_TENANT_MODAL,
  };
};

export const hideTenantModal = (): IHideTenantModal => {
  return {
    type: SessionActionTypes.HIDE_TENANT_MODAL,
  };
};
