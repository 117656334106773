import { t } from "i18next";
import moment from "moment";
import { initialLang, supportedLngs } from "../i18n";
import { countriesAndStates } from "../data/forms";
import { NumberFormatOptions } from "../interfaces/interfaces";

/* Transform an value of type string[] to string joined width "," & "and"
 * Use translation for each array item.
 */
export function stringfyAsList(list: string[]) {
  if (list?.length > 1) {
    const lastItem = list.pop();
    let stringedData = list?.toString();
    let joinedData = stringedData.replace(/,[s]*/g, ", ");
    joinedData = list + " " + t("public.and") + " " + lastItem;

    return joinedData;
  } else {
    return t(list[0]);
  }
}

/*
 * Return a sortened value with the center character replaced by '..."
 */
export const shortValue = (
  value: string,
  initialCharQuantity: number,
  finalCharQuantity: number
) => {
  const firstCharacters = value?.slice(0, initialCharQuantity);
  const lastCharacters = value?.slice(-finalCharQuantity);

  return firstCharacters + "..." + lastCharacters;
};

/*
 * Return a dd/mm/yyyy formatted date '..."
 */
export const formatDate = (date: Date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

/*
 * Return a dd/mm/yyyy formatted date from an string
 * Format examples: 'DD-MM-YYYY' and "DD/MM/YYYY - HH:mm:ss"
 */
export const formatStringDate = (date: string, format?: string) => {
  const stringToDate = new Date(date);
  return moment(stringToDate).format(format ? format : "DD-MM-YYYY");
};

export const getSimpleSearch = (list: string[], searchedValue: string) => {
  return list.filter((value) =>
    value.toLowerCase().includes(searchedValue.toLowerCase())
  );
};
/* Cache the searchable properties.
 * This avoid to loop between properties to search, translate and latinize with every keypress.
 * TODO: It would be interesting add fuzzy search as an option
 */

export function getSearchableStringfiedOptions(
  options: any[],
  propertiesToSearch: any[]
) {
  const searchableOptions = [...options];

  for (const option of searchableOptions) {
    let formatedOption = "";
    for (const property of propertiesToSearch) {
      if (property.searchMultipleLanguages && property.latinize) {
        for (const lang of supportedLngs) {
          const translatedLatinizedProperty = latinize(
            option[property?.text]?.[lang]
          );
          formatedOption = formatedOption + "^" + translatedLatinizedProperty;
        }
      } else {
        if (property.latinize) {
          const latinizedProperty = latinize(option[property.text]);
          formatedOption = formatedOption + "^" + latinizedProperty;
        } else {
          const optionStringedProperty = option[property.text.toString()];
          formatedOption = formatedOption + "^" + optionStringedProperty;
        }
      }
      option._searchableText = formatedOption;
    }
  }
  return searchableOptions;
}

/* Latinize, trim and lowercase string to compare values */
export function latinize(value?: string) {
  if (value && value?.length) {
    let formattedvalue = value?.trim().toLowerCase();

    formattedvalue = formattedvalue.replace(new RegExp(/[àáâãäå]/g), "a");
    formattedvalue = formattedvalue.replace(new RegExp(/[èéêë]/g), "e");
    formattedvalue = formattedvalue.replace(new RegExp(/[ìíîï]/g), "i");
    formattedvalue = formattedvalue.replace(new RegExp(/ñ/g), "ny");
    formattedvalue = formattedvalue.replace(new RegExp(/[òóôõö]/g), "o");
    formattedvalue = formattedvalue.replace(new RegExp(/[ùúûü]/g), "u");
    formattedvalue = formattedvalue.replace(new RegExp(/[ýÿ]/g), "y");

    return formattedvalue;
  }
}

/**
 * Returns the value of the selected language from an object with multiple translations
 */
export const getPropertyByLang = (property: any) => {
  return t(property[initialLang] ? property[initialLang] : property["en"]);
};

/**
 * Returns formatted value to use in URL
 */
export const getValueForURL = (value: string) => {
  return value?.toLowerCase()?.replace(" ", "-");
};

/**
 * Returns a default Country for organization forms
 */
export const defaultCountryIndex = 54;
export const defaultCountry = countriesAndStates[defaultCountryIndex];

export const getDefaultCountry = (defaultCountryIndex?: number) => {
  if (countriesAndStates) {
    const defaultCountry = defaultCountryIndex
      ? countriesAndStates[defaultCountryIndex]
      : countriesAndStates[0];
    return initialLang
      ? defaultCountry[("name_" + initialLang) as keyof typeof defaultCountry]
      : defaultCountry?.name_es;
  } else return "";
};

/*
 * Returns the value of the property from an object with EU numerical format
 */
export const formatNumberToEU = (number: number) => {
  const euformatter = new Intl.NumberFormat("de-DE");
  return euformatter.format(number || 0);
};

/**
 * Returns the value of the property from an object greater than 1000 with K, M, B, etc, dominations
 */

export const formatNumberToCmpct = (
  number: number,
  options?: NumberFormatOptions,
  location?: string
) => {
  const usformatter = Intl.NumberFormat(location || "en-US", options);
  return usformatter.format(number);
};
