import { ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import {
  changeSubscriptionFailedAction,
  changeSubscriptionSuccessAction,
  deleteOrgFailedAction,
  deleteOrgSuccessAction,
  getLicenseByIdFailedAction,
  getLicenseByIdSuccessAction,
  getLicensesAction,
  getLicensesFailedAction,
  getLicensesSuccessAction,
  getOrganizationByIdFailedAction,
  getOrganizationByIdSuccessAction,
  getOrganizationsFailedAction,
  getOrganizationsSuccessAction,
  getSubscriptionsAction,
  getSubscriptionsFailedAction,
  getSubscriptionsSuccessAction,
  reactivateSubscriptionFailedAction,
  reactivateSubscriptionSuccessAction,
  suspendSubscriptionFailedAction,
  suspendSubscriptionSuccessAction,
  updateLicenseFailedAction,
  updateLicenseSuccessAction,
  updateOrgFailedAction,
  updateOrgSuccessAction,
} from "./clientsActions";
import {
  OrganizationsActionTypes,
  LicensesActionTypes,
  SubscriptionsActionTypes,
} from "./clientsTypes";
import dataService from "../../../services/dataService";
import { showNotification } from "../../Notification/store/notificationActions";
import { hideScreenLoaderAction } from "../../Loader/loaderActions";
import { getTiers } from "../../PlatformConfig/store/tiersActions";

// ORGANIZATION
export const getOrganizationsEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(OrganizationsActionTypes.GET_ORGANIZATIONS),
    switchMap((action: any): any => {
      return from(dataService.getOrganizations()).pipe(
        mergeMap((response?: any) => {
          return of(
            getOrganizationsSuccessAction(response?.response),
            getLicensesAction()
          );
        }),
        catchError((error) =>
          of(
            getOrganizationsFailedAction(error),
            showNotification(
              "clients.getOrganizationsError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const getOrganizationByIDEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(OrganizationsActionTypes.GET_ORG_BY_ID),
    switchMap((action: any): any => {
      const id = action?.payload?.id;
      return from(dataService.getOrganizationById(id)).pipe(
        mergeMap((response?: any) => {
          return of(getOrganizationByIdSuccessAction(response?.response));
        }),

        catchError((error) =>
          of(
            getOrganizationByIdFailedAction(error),
            showNotification(
              "clients.getOrganizationError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const deleteOrgEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(OrganizationsActionTypes.DELETE_ORG),
    switchMap((action: any): any => {
      const id = action?.payload?.id;
      return from(dataService.deleteOrg(id)).pipe(
        mergeMap(() => {
          return of(deleteOrgSuccessAction(), hideScreenLoaderAction());
        }),

        catchError((error) =>
          of(
            deleteOrgFailedAction(error),
            hideScreenLoaderAction(),
            showNotification(
              "clients.deleteClientError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const updateOrgEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(OrganizationsActionTypes.UPDATE_ORG),
    switchMap((action: any): any => {
      const org = action?.payload?.org;
      return from(dataService.updateOrganization(org)).pipe(
        mergeMap(() => {
          return of(
            updateOrgSuccessAction(),
            hideScreenLoaderAction(),
            showNotification("clientDetail.updateOrgSuccess", "success", "")
          );
        }),

        catchError((error) =>
          of(
            updateOrgFailedAction(error),
            hideScreenLoaderAction(),
            showNotification(
              "clientDetail.updateOrgError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

// LICENSES
export const getLicensesEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(LicensesActionTypes.GET_LICENSES),
    switchMap((action: any): any => {
      return from(dataService.getLicenses()).pipe(
        mergeMap((response?: any) => {
          return of(
            getLicensesSuccessAction(response?.response),
            getSubscriptionsAction()
          );
        }),

        catchError((error) =>
          of(
            getLicensesFailedAction(error),
            showNotification(
              "clients.getLicensesError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const getLicenseByIDEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(LicensesActionTypes.GET_LICENSE_BY_ID),
    switchMap((action: any): any => {
      const id = action?.payload?.id;
      return from(dataService.getLicenseById(id)).pipe(
        mergeMap((response?: any) => {
          return of(getLicenseByIdSuccessAction(response?.response));
        }),

        catchError((error) =>
          of(
            getLicenseByIdFailedAction(error),
            showNotification(
              "clients.getLicenseError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const updateLicenseEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(LicensesActionTypes.UPDATE_LICENSE),
    switchMap((action: any): any => {
      const data = action.payload;
      const licenseType = data.licenseType;
      const newPriceId = data.newPriceId;
      const orgID = data.orgID;

      return from(
        dataService.updateLicense(licenseType, newPriceId, orgID)
      ).pipe(
        mergeMap((response: any) => {
          return of(
            updateLicenseSuccessAction(response?.response),
            getLicensesAction(),
            showNotification("clients.updateLicenseSuccess", "success", "")
          );
        }),
        catchError((error): any =>
          of(
            updateLicenseFailedAction(error),
            showNotification(
              "clients.updateLicenseError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

// SUBSCRIPTIONS
export const getClientsSubscriptionsEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(SubscriptionsActionTypes.GET_SUBSCRIPTIONS),
    switchMap((action: any): any => {
      const id = action?.id;

      return from(dataService.getSubscriptions()).pipe(
        mergeMap((response: any) => {
          const provider =
            state$?.value?.sessionReducer?.tokenData?.currentTenant;
          return of(
            getSubscriptionsSuccessAction(response?.response),
            getTiers(provider)
          );
        }),
        catchError((error): any =>
          of(
            getSubscriptionsFailedAction(),
            showNotification(
              "clients.getSubscriptionsError",
              "error",
              "",
              error?.status
            )
          )
        )
      );
    })
  );
};

export const suspendClientSubscriptionEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION),
    switchMap((action: any): any => {
      const id = action?.payload?.id;
      return from(dataService.suspendSubscription(id)).pipe(
        mergeMap((response: any) => {
          return of(
            suspendSubscriptionSuccessAction(),
            hideScreenLoaderAction(),
            showNotification("clients.changeClientStatusSuccess", "success", "")
          );
        }),
        catchError((error): any =>
          of(
            suspendSubscriptionFailedAction(),
            hideScreenLoaderAction(),
            showNotification(
              "clients.errorSuspendingSubscription",
              "error",
              "",
              error?.status
            )
          )
        )
      );
    })
  );
};

export const reactivateClientSubscriptionEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION),
    switchMap((action: any): any => {
      const id = action?.payload?.id;
      return from(dataService.reactivateSubscription(id)).pipe(
        mergeMap((response: any) => {
          return of(
            reactivateSubscriptionSuccessAction(),
            hideScreenLoaderAction(),
            showNotification("clients.changeClientStatusSuccess", "success", "")
          );
        }),
        catchError((error): any =>
          of(
            reactivateSubscriptionFailedAction(),
            hideScreenLoaderAction(),
            showNotification(
              "clients.errorReactivatingSubscription",
              "error",
              "",
              error?.status
            )
          )
        )
      );
    })
  );
};

export const changeSubscriptionEpic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(SubscriptionsActionTypes.CHANGE_SUBSCRIPTION),
    switchMap((action: any): any => {
      const orgID = action.payload?.orgId;
      const data = action.payload?.data;

      return from(dataService.changeSubscription(orgID, data)).pipe(
        mergeMap((response: any) => {
          return of(
            changeSubscriptionSuccessAction(),
            getLicensesAction(),
            showNotification("general.operationSuccess", "success", "")
          );
        }),
        catchError((error): any =>
          of(
            changeSubscriptionFailedAction(),
            showNotification(
              "general.operationFailed",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};
