import { combineEpics } from "redux-observable";
import { userEpics } from "../features/Settings/Users/store";
import { sessionEpics } from "../features/Settings/Profile/store";
import { scafoldingEpics } from "../features/PanelScafolding/store";
import { loginEpics } from "../features/Login/views/Login/store";
import { clientsEpics } from "../features/Clients/store";
import { homeEpics } from "../features/Home/store";
import { metricsEpics } from "../features/Clients/views/detail/sections/metrics/store";
import { tiersEpics } from "../features/PlatformConfig/store";

const rootEpic = combineEpics(
  scafoldingEpics.navigateEpic,
  loginEpics.submitLoginEpic,
  loginEpics.submitEmailloginEpic,
  loginEpics.switchTenantEpic,
  loginEpics.checkLoginEpic,
  loginEpics.loginSuccessEpic,
  loginEpics.signupSuccessEpic,
  sessionEpics.setSessionEpic,
  sessionEpics.readSessionEpic,
  sessionEpics.deleteSessionEpic,
  userEpics.checkUsersEpic,
  clientsEpics.getLicensesEpic,
  clientsEpics.getLicenseByIDEpic,
  clientsEpics.updateLicenseEpic,
  clientsEpics.getOrganizationsEpic,
  clientsEpics.getOrganizationByIDEpic,
  clientsEpics.deleteOrgEpic,
  clientsEpics.updateOrgEpic,
  clientsEpics.getClientsSubscriptionsEpic,
  clientsEpics.suspendClientSubscriptionEpic,
  clientsEpics.reactivateClientSubscriptionEpic,
  clientsEpics.changeSubscriptionEpic,
  homeEpics.getStatisticsEpic,
  homeEpics.getClientStatisticsEpic,
  metricsEpics.getStatisticsEpic,
  tiersEpics.getTiersEpic,
  tiersEpics.createTierEpic,
  tiersEpics.deleteTierEpic,
  tiersEpics.updateTierEpic
);

export default rootEpic;
