import React, { useState } from "react";
import RightNav from "../RightNav/RightNav";
import styles from "./burgerMenuButton.module.scss";
import { LinkModel } from "../../../../interfaces/interfaces";
import { sideMenuExtraMobileOptions } from "../../../../features/PanelScafolding/components/private/panelScafoldingPrivateConstants";

type IBurgerMenuProps = {
  menuOptions: LinkModel[];
  userEmail: string;
  userImage: string;
  expanded?: string[];
  selected: string;
  setExpanded: (x: any) => void;
};

const BurgerMenu: React.FC<IBurgerMenuProps> = (props) => {
  const { menuOptions, expanded, selected, setExpanded, userEmail, userImage } =
    props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={
          open ? styles.backgroundLayerActive : styles.backgroundLayerInactive
        }
      ></div>
      <div
        className={
          (open ? styles.open : styles.closed) + " " + styles.buttonContainer
        }
        onClick={() => setOpen(!open)}
      >
        <div />
        <div />
        <div />
      </div>
      <RightNav
        open={open}
        menuOptions={menuOptions}
        userEmail={userEmail}
        userImage={userImage}
        menuExtraMobileOptions={sideMenuExtraMobileOptions}
        expanded={expanded}
        selected={selected}
        setExpanded={(e) => {
          setExpanded(e);
          setOpen(open);
        }}
      />
    </>
  );
};

export default BurgerMenu;
