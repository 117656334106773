import { ActionStatusTypes } from "../../../../../data/common";
import { OrganizationModel } from "../../../../../interfaces/interfaces";
import { ScafoldingActions } from "../../../../PanelScafolding/store/scafoldingTypes";
import { SessionAction } from "../../../../Settings/Profile/store/sessionTypes";

export interface ISubmitLoginAction {
  type: LoginActionTypes.SUBMIT_LOGIN;
  token: string;
}

export interface ISubmitEmailLoginAction {
  type: LoginActionTypes.SUBMIT_EMAIL_LOGIN;
  payload: {
    user: ILoginItem;
  };
}

export interface ISwitchTenantAction {
  type: LoginActionTypes.SWITCH_TENANT;
  payload: {
    tenant: string;
  };
}

export interface ISwitchTenantToChangeAction {
  type: LoginActionTypes.SWITCH_TENANT_TO_CHANGE_ORG;
  payload: {
    tenant: string;
    org: OrganizationModel;
  };
}

export interface ICheckLoginAction {
  type: LoginActionTypes.CHECK_LOGIN;
}

export interface ILoginSuccessAction {
  type: LoginActionTypes.LOGIN_SUCCESS;
  payload: {};
}

export interface IQRSignupSuccessAction {
  type: LoginActionTypes.QR_SIGNUP_SUCCESS;
  payload: {};
}

export interface ILoginDone {
  type: LoginActionTypes.LOGIN_DONE;
}

export interface ILoginFailedAction {
  type: LoginActionTypes.LOGIN_FAILED;
}
export interface ILogOutAction {
  type: LoginActionTypes.LOGOUT;
}

export type LoginActions =
  | ICheckLoginAction
  | ISubmitLoginAction
  | ISubmitEmailLoginAction
  | ILoginSuccessAction
  | ILoginFailedAction
  | ILoginSuccessAction
  | ILoginFailedAction
  | ILoginDone
  | ILogOutAction
  | ISwitchTenantAction
  | ISwitchTenantToChangeAction
  | IQRSignupSuccessAction;

export interface ILoginState {
  loading: Iloading;
  loggedIn: boolean;
  signUpStatus: ActionStatusTypes;
}

export interface Iloading {
  status: "inactive" | "active" | "finished" | "error";
  description: string;
}

export enum LoginActionTypes {
  CHECK_LOGIN = "login/check",
  SUBMIT_LOGIN = "login/submit",
  SUBMIT_EMAIL_LOGIN = "login/submitEmail",
  SWITCH_TENANT = "login/switch_tenant",
  SWITCH_TENANT_TO_CHANGE_ORG = "login/switch_tenant_to_change_org",
  QR_SIGNUP_SUCCESS = "login/signup_qr_success",
  LOGIN_SUCCESS = "login/success",
  LOGIN_FAILED = "login/failed",
  LOGIN_DONE = "login/done",
  LOGOUT = "login/logout",
}

export interface ILoginItem {
  username: string;
  password: string;
}

export type ReduxActions = LoginActions | ScafoldingActions | SessionAction;
