import { Loading } from "carbon-components-react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { screenLoaderActive } from "../../store/loaderSelectors";
import styles from "./screenLoader.module.scss";

type ILoaderProps = { children?: any };

const Loader: React.FC<ILoaderProps> = ({ children }) => {
  const isLoading = useSelector(screenLoaderActive);

  useEffect(() => {}, [isLoading]);

  const Loaded = <div>{children}</div>;

  const loading = (
    <div className={styles.screenLoader}>
      <p></p>
      <span></span>
    </div>
  );
  return <React.Fragment>{!isLoading ? Loaded : loading}</React.Fragment>;
};

Loader.defaultProps = {};

export default Loader;
