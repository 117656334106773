import { ActionStatusTypes } from "../../../data/common";
import {
  SessionAction,
  SessionActionTypes,
} from "../../Settings/Profile/store/sessionTypes";
import {
  ClientsAction,
  ClientsActionTypes,
  ClientsState,
  LicensesAction,
  LicensesActionTypes,
  LicensesState,
  OrganizationsAction,
  OrganizationsActionTypes,
  OrganizationsState,
  SubscriptionsAction,
  SubscriptionsActionTypes,
  SubscriptionsState,
} from "./clientsTypes";

const OrganizationsInitialState: OrganizationsState = {
  organizations: new Array(),
  selectedOrg: undefined,
  showOrgDetail: false,
  loadingOrgsStatus: ActionStatusTypes.neutral,
  loadingOrgByIdStatus: ActionStatusTypes.neutral,
  deletingOrgByIdStatus: ActionStatusTypes.neutral,
  updatingOrgByIdStatus: ActionStatusTypes.neutral,
};

export const organizationsReducer = (
  state = OrganizationsInitialState,
  action: OrganizationsAction | SessionAction
): OrganizationsState => {
  switch (action.type) {
    case OrganizationsActionTypes.GET_ORGANIZATIONS:
      return {
        ...state,
        loadingOrgsStatus: ActionStatusTypes.loading,
      };
    case OrganizationsActionTypes.GET_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        organizations: action?.payload?.organizations,
        loadingOrgsStatus: ActionStatusTypes.success,
      };
    case OrganizationsActionTypes.GET_ORGANIZATIONS_FAILED:
      return {
        ...state,
        organizations: new Array(),
        loadingOrgsStatus: ActionStatusTypes.failed,
      };
    case OrganizationsActionTypes.GET_ORG_BY_ID:
      return {
        ...state,
        selectedOrg: undefined,
        showOrgDetail: false,
        loadingOrgByIdStatus: ActionStatusTypes.loading,
      };
    case OrganizationsActionTypes.GET_ORG_BY_ID_SUCCESS:
      return {
        ...state,
        selectedOrg: action?.payload?.org,
        showOrgDetail: true,
        loadingOrgByIdStatus: ActionStatusTypes.success,
      };
    case OrganizationsActionTypes.GET_ORG_BY_ID_FAILED:
      return {
        ...state,
        selectedOrg: undefined,
        showOrgDetail: false,
        loadingOrgByIdStatus: ActionStatusTypes.failed,
      };
    case OrganizationsActionTypes.DELETE_ORG:
      return {
        ...state,
        deletingOrgByIdStatus: ActionStatusTypes.loading,
      };
    case OrganizationsActionTypes.DELETE_ORG_SUCCESS:
      return {
        ...state,
        selectedOrg: undefined,
        showOrgDetail: false,
        deletingOrgByIdStatus: ActionStatusTypes.success,
      };
    case OrganizationsActionTypes.DELETE_ORG_FAILED:
      return {
        ...state,
        deletingOrgByIdStatus: ActionStatusTypes.failed,
      };
    case OrganizationsActionTypes.SET_SELECTED_ORG:
      return {
        ...state,
        selectedOrg: action?.payload?.org,
      };
    case SessionActionTypes.DELETE_SESSION:
      return {
        ...OrganizationsInitialState,
      };
    default:
      return state;
  }
};

const LicensesInitialState: LicensesState = {
  licenses: undefined,
  loadingLicensesStatus: ActionStatusTypes.neutral,
  selectedLicense: undefined,
  loadingLicenseByIdStatus: ActionStatusTypes.neutral,
  updatingLicenseStatus: ActionStatusTypes.neutral,
};

export const licensesReducer = (
  state = LicensesInitialState,
  action: LicensesAction | SessionAction
): LicensesState => {
  switch (action.type) {
    case LicensesActionTypes.GET_LICENSES:
      return {
        ...state,
        loadingLicensesStatus: ActionStatusTypes.loading,
      };
    case LicensesActionTypes.GET_LICENSES_SUCCESS:
      return {
        ...state,
        licenses: action?.payload?.licenses,
        loadingLicensesStatus: ActionStatusTypes.success,
      };
    case LicensesActionTypes.GET_LICENSES_FAILED:
      return {
        ...state,
        licenses: undefined,
        loadingLicensesStatus: ActionStatusTypes.failed,
      };
    case LicensesActionTypes.GET_LICENSE_BY_ID:
      return {
        ...state,
        selectedLicense: undefined,
        loadingLicenseByIdStatus: ActionStatusTypes.loading,
      };
    case LicensesActionTypes.GET_LICENSE_BY_ID_SUCCESS:
      return {
        ...state,
        selectedLicense: action?.payload?.license,
        loadingLicenseByIdStatus: ActionStatusTypes.success,
      };
    case LicensesActionTypes.GET_LICENSE_BY_ID_FAILED:
      return {
        ...state,
        selectedLicense: undefined,
        loadingLicenseByIdStatus: ActionStatusTypes.failed,
      };
    case LicensesActionTypes.UPDATE_LICENSE:
      return {
        ...state,
        updatingLicenseStatus: ActionStatusTypes.loading,
      };
    case LicensesActionTypes.UPDATE_LICENSE_SUCCESS:
      return {
        ...state,
        updatingLicenseStatus: ActionStatusTypes.success,
      };
    case LicensesActionTypes.UPDATE_LICENSE_FAILED:
      return {
        ...state,
        updatingLicenseStatus: ActionStatusTypes.failed,
      };
    case LicensesActionTypes.SET_SELECTED_LICENSE:
      return {
        ...state,
        selectedLicense: action?.payload?.license,
      };
    case SessionActionTypes.DELETE_SESSION:
      return {
        ...LicensesInitialState,
      };
    default:
      return state;
  }
};

const SubscriptionsInitialState: SubscriptionsState = {
  subscriptions: undefined,
  loadingSubscriptionsStatus: ActionStatusTypes.neutral,
  updatingSubscriptionsStatus: ActionStatusTypes.neutral,
};

export const subscriptionsReducer = (
  state = SubscriptionsInitialState,
  action: SubscriptionsAction | SessionAction
): SubscriptionsState => {
  switch (action.type) {
    case SubscriptionsActionTypes.GET_SUBSCRIPTIONS:
      return {
        ...state,
        loadingSubscriptionsStatus: ActionStatusTypes.loading,
      };
    case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action?.payload?.subscriptions,
        loadingSubscriptionsStatus: ActionStatusTypes.success,
      };
    case SubscriptionsActionTypes.GET_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        subscriptions: undefined,
        loadingSubscriptionsStatus: ActionStatusTypes.failed,
      };
    case SessionActionTypes.DELETE_SESSION:
      return {
        ...SubscriptionsInitialState,
      };
    default:
      return state;
  }
};

// CLIENTS
const ClientsInitialState: ClientsState = {
  selectedClient: undefined,
};

export const clientsReducer = (
  state = ClientsInitialState,
  action: ClientsAction | SessionAction
): ClientsState => {
  switch (action.type) {
    case ClientsActionTypes.SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action?.payload?.client,
      };
    case SessionActionTypes.DELETE_SESSION:
      return {
        ...ClientsInitialState,
      };
    default:
      return state;
  }
};
