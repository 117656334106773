import moment from "moment";
import { t } from "i18next";
import {
  formatNumbersDefaultOptions,
  subscriptionStatusOptions,
} from "../data/common";
import { subscriptionStatusActions } from "../features/Clients/data/clients.constants";
import {
  ILimitsModel,
  IPriceModel,
  ITierModel,
  MetricCode,
} from "../interfaces/interfaces";
import { formatNumberToCmpct } from "./formatDataUtil";

/**
 * Returns tiers period value translated
 */
export const getPeriodTranslations = (period?: string) =>
  period === "month" ? "clientDetail.monthly" : "clientDetail.yearly";

/**
 * Returns the actions available to execute with a subscription
 */
export const getSubscriptionAction = (itemStatus?: subscriptionStatusOptions) =>
  subscriptionStatusActions[
    itemStatus as keyof typeof subscriptionStatusActions
  ];

/**
 * Returns the next payment date of an org or, failing that, a generic text
 */
export const getNextPaymentDate = (date?: string) => {
  return date
    ? t("clients.the") + " " + moment(date)?.format("DD/MM/YYYY")
    : t("clients.currentPeriodEnds");
};

/**
 * Returns an arrey of tiers filtered by provider
 */
export const filterTiersByProvider = (
  providerID?: string,
  tiers?: ITierModel[]
) => {
  return providerID
    ? tiers?.filter(
        (product) =>
          product?.provider === providerID &&
          product?.prices?.length &&
          product?.name !== "Enterprise"
      )
    : undefined;
};

/**
 * Returns a limit of an array searching by code
 */
export const getLimit = (code: MetricCode, limits?: ILimitsModel[]) => {
  const emptyLimit = {
    Code: code,
    Value: 0,
    Description: "",
    Active: true,
  };

  return limits?.find((limit) => limit?.Code === code) || emptyLimit;
};

/**
 * Returns a tier limits records by feature name
 */
export const getTierLimits = (tier?: ITierModel) => {
  const limits = tier?.limits;
  return {
    issuanceTemplates: getLimit(MetricCode.Ctf1, limits),
    issuedCredentials: getLimit(MetricCode.Ctf2, limits),
    apiKeys: getLimit(MetricCode.Cmn2, limits),
    activeUsers: getLimit(MetricCode.Cnt2, limits),
    verificationTemplates: getLimit(MetricCode.Cnt1, limits),
    dids: getLimit(MetricCode.Cmn1, limits),
  };
};

export const getTierMonthlyPrices = (prices?: IPriceModel[]) => {
  return (
    prices?.filter((price) => price.recurringInterval === "month") || undefined
  );
};

export const getTierYearlyPrices = (prices?: IPriceModel[]) => {
  return (
    prices?.filter((price) => price.recurringInterval === "year") || undefined
  );
};

export const getTierActivePriceMonthly = (prices?: IPriceModel[]) => {
  let activePrice;
  activePrice =
    prices?.find((price) => {
      return price.recurringInterval === "month" && price?.active === true;
    }) || undefined;
  return activePrice;
};

export const getTierActivePriceYearly = (prices?: IPriceModel[]) => {
  return (
    prices?.find(
      (price) => price.recurringInterval === "year" && price?.active === true
    ) || undefined
  );
};

/**
 * Returns a tier price formatted to show in tier cards
 */
export const getTierPriceAmount = (price?: IPriceModel) => {
  let formattedPrice;
  if (!!price?.amount) {
    formattedPrice = price?.amount / 100;
  } else {
    formattedPrice = "";
  }
  return formattedPrice?.toString();
};

/**
 * Returns a tier limit quantity data to display
 * If limit is -1, it will return an infinite symbol
 */
export const getTierLimitToDisplay = (featureLimit?: number | string) => {
  let formattedFeatureLimit;

  if (typeof featureLimit === "number") {
    formattedFeatureLimit =
      featureLimit === -1
        ? "∞"
        : formatNumberToCmpct(featureLimit, formatNumbersDefaultOptions);
  } else if (featureLimit === "-1") {
    formattedFeatureLimit = "∞";
  } else {
    formattedFeatureLimit = featureLimit;
  }

  return formattedFeatureLimit;
};

export const modifyTiersIndexCreating = (
  newIndex: string,
  currentIndex: number,
  currentTier?: ITierModel,
  tiers?: ITierModel[]
) => {
  // Check if current y new are the same
  if (currentIndex === parseInt(newIndex)) {
    return undefined;
  } else if (newIndex && tiers) {
    const newIndexNumber = parseInt(newIndex);

    const tierList = [...tiers];
    const listWithChange = moveItemInArray(tierList, currentIndex, newIndex);

    const tierListWithChanges = listWithChange && [...listWithChange];
    tierListWithChanges?.map((tier, index) =>
      tier ? (tier.index = index) : null
    );

    newIndexNumber && tierListWithChanges?.splice(newIndexNumber, 1);
    return tierListWithChanges;
  }
  return new Array();
};

export const modifyTiersIndexEditing = (
  newIndex: string,
  currentIndex: number,
  currentTier?: ITierModel,
  tiers?: ITierModel[],
  isCreating?: boolean
) => {
  // Check if current y new are the same
  if (currentIndex === parseInt(newIndex) && !isCreating) {
    return undefined;
  } else if (newIndex && tiers) {
    const newIndexNumber = parseInt(newIndex);

    // Check if new index is mayor than current
    const newIsMayor = newIndexNumber > currentIndex;

    let tierList = isCreating
      ? [
          ...tiers.slice(0, parseInt(newIndex)),
          currentTier,
          ...tiers.slice(parseInt(newIndex)),
        ]
      : [...tiers];

    // isCreating && tierList?.insert(newIndex, currentTier);
    const listWithChange = moveItemInArray(tierList, currentIndex, newIndex);

    const tierListWithChanges = listWithChange && [...listWithChange];
    tierListWithChanges?.map((tier, index) =>
      tier ? (tier.index = index) : null
    );
    // Remove tier being changed from list of tiers to update
    newIndexNumber && tierListWithChanges?.splice(newIndexNumber, 1);

    return tierListWithChanges;
  }
  return new Array();
};

export function moveItemInArray(
  list?: any[],
  fromIndex?: number,
  toIndex?: string
) {
  if (list?.length) {
    const element = list[fromIndex || 0];
    list?.splice(fromIndex || 0, 1);
    toIndex && list?.splice(parseInt(toIndex), 0, element);
  }

  return list;
}
