import React, { useEffect, useState } from "react";
import {
  getStatistics,
  setSelectedPeriodIsYear,
} from "../store/metricsActions";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { metricsSelectors } from "../store";
import { ActionStatusTypes } from "../../../../../../../data/common";
import { blocksData, subBlocksData } from "../data/metrics.constants";
import styles from "./metrics.module.scss";
import QuantitiesChart from "../components/quantitiesChart/quantitiesChart";
import MetricsSubBlocks from "../components/metricsSubBlocks/MetricsSubBlocks";
import MetricsBlocks from "../components/metricsBlocks/MetricsBlocks";
import {
  IGeneralSelectOption,
  OrganizationModel,
} from "../../../../../../../interfaces/interfaces";
import DatesFilter from "../components/filters/datesFilter/datesFilter";
import ModalDatePicker from "../../../../../../../components/elements/forms/formFields/modalRangeDatePicker/modalDatePicker";
import moment from "moment";

type IMetricsProps = {
  org?: OrganizationModel;
};

const Metrics: React.FC<IMetricsProps> = (props) => {
  const { org } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterDatesOptions: IGeneralSelectOption[] = [
    {
      id: "W",
      text: "dashboard.last7Days",
      value: "7",
      action: () => (
        setSelectedFilterDate("7"), dispatch(setSelectedPeriodIsYear(false))
      ),
    },
    {
      id: "M",
      text: "dashboard.last30Days",
      value: "30",
      action: () => (
        setSelectedFilterDate("30"), dispatch(setSelectedPeriodIsYear(false))
      ),
    },
    {
      id: "Y",
      text: "dashboard.last365Days",
      value: "365",
      action: () => (
        setSelectedFilterDate("365"), dispatch(setSelectedPeriodIsYear(true))
      ),
    },
    // {
    //   id: "C",
    //   text: "dashboard.custom",
    //   value: "100",
    //   action: () => setShowRangeModal(true),
    // },
  ];
  const [selectedFilterPeriod, setSelectedFilterDate] = useState<string>(
    filterDatesOptions[0]?.value
  );

  const [showRangeModal, setShowRangeModal] = useState<boolean>(false);

  const loadingStatistics =
    useSelector(metricsSelectors.getStastisticsStatus) ===
    ActionStatusTypes?.loading;

  const featuresNumber = useSelector(metricsSelectors.getUsedFeaturesNumber);
  const featuresPercentages = useSelector(
    metricsSelectors.getUsedFeaturesPercentages
  );
  const featuresVariations = useSelector(
    metricsSelectors.getUsedFeaturesVariations
  );

  const dataLoading = loadingStatistics;

  const getSelectedPeriodLabel = filterDatesOptions?.find(
    (option) => option?.value === selectedFilterPeriod
  )?.text;

  const getEndDate = moment()?.format("YYYY-MM-DDTHH:mm:ss[Z]"); // today date
  const getBeginDate = moment()
    ?.subtract(parseInt(selectedFilterPeriod) - 1, "d")
    ?.format("YYYY-MM-DDTHH:mm:ss[Z]"); // selected period start date

  const statsParamsURL = `?org=${org?.id}&end=${getEndDate}&begin=${getBeginDate}&window=current`;

  useEffect(() => {
    !loadingStatistics && dispatch(getStatistics(statsParamsURL));
  }, [selectedFilterPeriod]);

  return (
    <>
      <div className={styles.filtersDateContainer}>
        <div className={styles.filtersDateContainer__content}>
          <DatesFilter
            activePeriodLabel={t(getSelectedPeriodLabel || "")}
            selectedFilterDate={selectedFilterPeriod}
            setSelectedFilterDate={setSelectedFilterDate}
            datesOptions={filterDatesOptions}
            dataLoading={dataLoading}
          />

          {showRangeModal ? <ModalDatePicker /> : null}
        </div>
      </div>
      <div className={styles.clientsMetrics__container}>
        <div className={styles.clientsMetrics__metricsBlocks}>
          <MetricsBlocks
            blocksData={blocksData}
            statsNumberApi={featuresNumber}
            dataLoading={dataLoading}
            selectedFilterPeriod={selectedFilterPeriod}
            featuresPercentages={featuresPercentages}
          ></MetricsBlocks>
        </div>
        <div className={styles.clientsMetrics__chart}>
          <QuantitiesChart
            dataLoading={loadingStatistics}
            selectedPeriodDays={selectedFilterPeriod}
            featuresPercentages={featuresPercentages}
            featuresVariations={featuresVariations}
          />
        </div>
      </div>
      <MetricsSubBlocks
        subBlocksData={subBlocksData}
        statsNumberApi={featuresNumber}
        dataLoading={dataLoading}
      ></MetricsSubBlocks>
    </>
  );
};

Metrics.defaultProps = {};

export default Metrics;
