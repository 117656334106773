import React from "react";
import cx from "classnames";
import {
  CellContentTypes,
  TextAlignTypes,
} from "../../../../../../data/display";
import ChevronDownIcon from "../../../../../../assets/iconsLibrary/simple/chevronDown/chevronDownIcon";
import { t } from "i18next";

type HeaderCellProps = {
  header: {
    key: string;
    text: string;
    textAlign?: TextAlignTypes;
    type?: CellContentTypes;
    tag?: boolean;
    short?: boolean;
    tagText?: string;
    action?: (x?: any) => void;
  };
  index: number;
  headerDataLenght: number;
};

export const HeaderCell: React.FC<HeaderCellProps> = (props) => {
  const { header, index, headerDataLenght } = props;

  return (
    <th
      className={cx(
        header?.textAlign === TextAlignTypes.right
          ? "textAlignRight marginLeftAuto"
          : "textAlignLeft",
        "bodyBoldXS neutral700"
      )}
      key={header?.key + index + Math.random()}
    >
      <div>
        {header.text ? (
          <span
            className={cx(
              header?.textAlign === TextAlignTypes.right
                ? "textAlignRight marginLeftAuto"
                : "textAlignLeft",
              "bodyBoldXS neutral700"
            )}
          >
            {t(header.text)}
          </span>
        ) : null}

        {/* This will be util in the future
        {index !== headerDataLenght - 1 ? (
          <ChevronDownIcon size={16} />
        ) : null} */}
      </div>
    </th>
  );
};
