import { ActionStatusTypes } from "../../../../data/common";
import { IUserItem, UserProfile } from "../../../../interfaces/interfaces";
import { Iloading } from "../../../Login/views/Login/store/loginTypes";

export interface ICheckUsersAction {
  type: UsersActionTypes.CHECK_USERS;
}

export type UsersAction = ICheckUsersAction;

export interface IChangeUserPasswords {
  id: string;
  userId: string;
  newPasswords: string;
  oldPasswords: string;
}

export interface IUsersState {
  users: IUserItem[];
  selectedUser?: IUserItem;
  showUserDetail: boolean;
  loadingStatus: boolean;
  changePassword: {
    loading: Iloading;
  };
  tenant?: string;
  status: ActionStatusTypes;
  error: string;
  profile?: UserProfile;
}

export enum UsersActionTypes {
  CHECK_USERS = "users/status",
}
