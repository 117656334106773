import {
    IHideScreenLoaderAction,
    IShowScreenLoaderAction,
    LoaderActionTypes,
} from './loaderTypes'

export const showScreenLoaderAction = (): IShowScreenLoaderAction => {
    return {
        type: LoaderActionTypes.SHOW_SCREEN_LOADER,
    }
}

export const hideScreenLoaderAction = (): IHideScreenLoaderAction => {
    return {
        type: LoaderActionTypes.HIDE_SCREEN_LOADER,
    }
}
