import { NumberFormatOptions } from "../../../../../../../interfaces/interfaces";

export const blocksData = [
  {
    id: "activeUsers",
    classNameBox: "purpleBackground",
    classNameSubBox: "purpleSemiBackground",
    title: "dashboard.activeUsers",
  },
  {
    id: "issuedCredentials",
    classNameBox: "greenBackground",
    classNameSubBox: "greenSemiBackground",
    title: "dashboard.issuedCredentials",
  },
];
export const subBlocksData = [
  {
    id: "activeUsers",
    title: "dashboard.activeUsers",
  },
  {
    id: "issuedCredentials",
    title: "dashboard.issuedCredentials",
  },
  {
    id: "verifierTemplates",
    title: "dashboard.verificationTemplates",
  },
  {
    id: "issuanceTemplates",
    title: "dashboard.issuanceTemplates",
  },
  {
    id: "dids",
    title: "dashboard.dids",
  },
  {
    id: "apiKeys",
    title: "dashboard.apiKeys",
  },
];

export const featureOptions = [
  { text: "dashboard.activeUsers", value: "activeUsers" },
  { text: "dashboard.dids", value: "dids" },
  { text: "dashboard.issuedCredentials", value: "issuedCredentials" },
  { text: "dashboard.issuanceTemplates", value: "issuanceTemplates" },
  { text: "dashboard.verificationTemplates", value: "verifierTemplates" },
  { text: "dashboard.apiKeys", value: "apiKeys" },
];

export const filterDatesOptions = [
  { id: "W", text: "dashboard.last7Days", value: "7" },
  { id: "M", text: "dashboard.last30Days", value: "30" },
  { id: "Y", text: "dashboard.last365Days", value: "365" },
];
