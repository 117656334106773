import React from "react";
import cx from "classnames";
import PurpleButton from "../../../../components/atoms/buttons/purple/PurpleButton";
import { ITierModel } from "../../../../interfaces/interfaces";
import styles from "./licenseCard.module.scss";
import Tag from "../../../atoms/chips/Tag";
import { t } from "i18next";
import {
  formatNumberToCmpct,
  getValueForURL,
} from "../../../../utils/formatDataUtil";
import { Link } from "react-router-dom";
import LicenseCardFeature from "./licenseCardFeature/LicenseCardFeature";

type ILicenseCardProps = {
  license: ITierModel;
  period: string;
  isCurrentLicense?: boolean;
  showEditButton?: boolean;
  editLicense?: (x?: any) => void;
};

const LicenseCard: React.FC<ILicenseCardProps> = (props) => {
  const { license, period, isCurrentLicense, showEditButton, editLicense } =
    props;

  const getPrice = (tier: ITierModel) => {
    const yearlyPrice = tier?.prices?.find(
      (price) => price?.recurringInterval === "year" && price?.active
    );
    const monthlyPrice = tier?.prices?.find(
      (price) => price?.recurringInterval === "month" && price?.active
    );

    const price =
      period === "year" ? yearlyPrice?.amount : monthlyPrice?.amount;
    return price
      ? formatNumberToCmpct(price / 100, undefined, "de-DE") + "€"
      : t("platformConfig.subcriptionTiers.customPrice");
  };

  const licenseIsEnterprise = license?.name
    ?.toLowerCase()
    ?.includes("enterprise");

  return (
    <div
      className={`${styles?.license} ${
        isCurrentLicense ? styles?.currentLicense : ""
      }`}>
      <div className={styles?.license__header}>
        <p className={`${styles?.license__header__title} ${cx("bodyBoldLG")}`}>
          {license?.name}
        </p>
        {license?.isPopular && (
          <Tag
            text={"Popular"}
            className={styles?.license__header__popularTag}
            color={"blue"}
          />
        )}
      </div>
      {license?.description && (
        <p
          className={`${styles?.license__description} ${cx(
            "marginTop8 bodyRegularSM neutral700"
          )}`}>
          {license?.description}
        </p>
      )}

      {!licenseIsEnterprise ? (
        <div className={`${styles?.license__price}`}>
          <p className={`${cx("marginTop12 heading3")}`}>
            <span>{getPrice(license)}</span>
            <span className={`${cx("neutral700 bodyRegularMD marginLeft8")}`}>
              {"/ " + t(`general.${period}`)}
            </span>
          </p>
        </div>
      ) : (
        <div className={`${styles?.license__price}`}>
          <p
            className={`${styles?.license__customPrice} ${cx(
              "marginTo24 heading4"
            )}`}>
            {getPrice(license)}
          </p>
        </div>
      )}

      <div className={styles?.license__features}>
        <div>
          <p
            className={`${styles?.license__features__title} ${cx(
              "bodyBoldXS neutral700"
            )}`}>
            {t("platformConfig.subcriptionTiers.verificationFeatures")}
          </p>
          <ul className={styles?.license__features__list}>
            <LicenseCardFeature
              featureCode={"Cnt1"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.verificationTemplates")}
            />
            <LicenseCardFeature
              featureCode={"Cnt2"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.activeUsers")}
            />
          </ul>
        </div>
        <div>
          <p
            className={`${styles?.license__features__title} ${cx(
              " bodyBoldXS neutral700"
            )}`}>
            {t("platformConfig.subcriptionTiers.issuanceFeatures")}
          </p>
          <ul className={styles?.license__features__list}>
            <LicenseCardFeature
              featureCode={"Ctf1"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.issuanceTemplates")}
            />

            <LicenseCardFeature
              featureCode={"Ctf2"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.issuedCredentials")}
            />
          </ul>
        </div>
      </div>

      {showEditButton && editLicense ? (
        <Link
          className={cx("buttonMD neutral1000", styles.nameLink)}
          to={getValueForURL(license?.name || "detail")}>
          <PurpleButton
            className={styles?.license__button}
            outlined
            label={t("general.edit")}
            action={() => editLicense && editLicense(license)}
          />
        </Link>
      ) : null}
    </div>
  );
};

export default LicenseCard;
