import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { isValidEmail } from "../../../../utils/validations/functions";
import { loginButton, ssiLoginLink } from "./emailLoginConstants";
import FormPanel from "../../../../components/elements/forms/FormPanel/FormPanel";
import PanelScafoldingPublic from "../../../PanelScafolding/components/public/PanelScafoldingPublic";
import { setSelectedRoute } from "../../../PanelScafolding/store/scafoldingActions";
import GaTextInput from "../../../../components/elements/forms/formFields/textInput/GaTextInput";
import { sessionSelectors } from "../../../Settings/Profile/store";
import { submitEmailLogin } from "../Login/store/loginAction";
import { history } from "../../../../services/history";
import { userHasScopes } from "../../../../utils/permissionsUtil";
import { deleteSession } from "../../../Settings/Profile/store/sessionActions";
import { showNotification } from "../../../Notification/store/notificationActions";

type IEmailLoginProps = {};

const EmailLogin: React.FC<IEmailLoginProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(sessionSelectors.getUserEmail_s);
  const tokenExpiration = useSelector(sessionSelectors.getTokenExpiration);
  const tokenOrg = useSelector(sessionSelectors.getUserOrganization);
  const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes);

  const userCanReadProviders = () => {
    return (
      allowedScopes?.length && userHasScopes(["readProviders"], allowedScopes)
    );
  };

  const goToHome = () => {
    dispatch(setSelectedRoute("home"));
    history.push("/home");
  };
  const goToLoginAndDeleteSession = () => {
    dispatch(setSelectedRoute("login"));
    history.push("/login");
    dispatch(deleteSession());
  };
  const notifyNoPermissionsAndLogout = () => {
    goToLoginAndDeleteSession();
    dispatch(showNotification("login.noAccessPermissions", "error", ""));
  };
  const initApp = async () => {
    if (!!user && !!tokenOrg) {
      userCanReadProviders() ? goToHome() : notifyNoPermissionsAndLogout();
    } else {
      dispatch(setSelectedRoute("email-login"));
      history.push("/email-login");
    }
  };

  useEffect(() => {
    initApp();
  }, [user, tokenExpiration]);

  const [formData, setFormState] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;
  const validForm = !!(
    isValidEmail(formData?.email) && formData?.password.trim()?.length
  );

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormState({
      ...formData,
      [name]: value,
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    validForm ? submitUserData() : null;
  };

  const submitUserData = () => {
    dispatch(submitEmailLogin(email, password));
  };

  return (
    <PanelScafoldingPublic>
      <FormPanel
        title={"login.signIn"}
        legend={"login.logInDescription"}
        primaryButtonDisabled={!validForm}
        primaryButton={loginButton}
        primaryFunction={onSubmit}
        onSubmit={onSubmit}
        ctaSeparator={"login.or"}
        ctaLink={ssiLoginLink}
      >
        <GaTextInput
          className={cx("margin-bottom")}
          id={"email"}
          invalid={!isValidEmail(formData?.email)}
          invalidText={"login.invalidEmail"}
          labelText={"login.email"}
          required={true}
          name={"email"}
          onChange={(e) => {
            handleInputChange(e);
          }}
          placeholder={"Email"}
          value={formData.email}
        />

        <GaTextInput
          className={cx("margin-bottom")}
          id={"password"}
          invalid={!formData?.password?.trim()}
          invalidText={"general.required"}
          labelText={"login.password"}
          required={true}
          name={"password"}
          onChange={(e) => {
            handleInputChange(e);
          }}
          placeholder={t("login.password")}
          type="password"
          value={formData.password}
        />
      </FormPanel>
    </PanelScafoldingPublic>
  );
};

EmailLogin.defaultProps = {};

export default EmailLogin;
