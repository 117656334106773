import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  ButtonModel,
  ImageModel,
  LinkModel,
  StepModel,
  SwitchButtonModel,
} from "../../../../interfaces/interfaces";
import { userHasScopes } from "../../../../utils/permissionsUtil";
import Description from "../../../atoms/texts/Description/Description";
import styles from "./screenHeader.module.scss";
import cx from "classnames";
import GreyButton from "../../../atoms/buttons/grey/GreyButton";
import { sessionSelectors } from "../../../../features/Settings/Profile/store";
import PurpleButton from "../../../atoms/buttons/purple/PurpleButton";
import { Link } from "react-router-dom";
import ChevronLeftIcon from "../../../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon";

type ScreenHeaderProps = {
  id?: string;
  title: string;
  icon?: ImageModel;
  buttonScopes?: string[];
  titleClassname?: string;
  subTextClassname?: string;
  subText?: string;
  button?: ButtonModel;
  buttonIsLight?: boolean;
  hideBorderBottom?: boolean;
  buttonClassname?: string;
  switchButton?: SwitchButtonModel;
  switchCheckedValue?: string;
  onChangeSwitchSelect?: ((x?: any) => void) | any;
  buttonFunction?: ((x?: any) => void) | any;
  description?: string;
  steps?: StepModel[];
  currentStep?: StepModel;
  nextStepActive?: boolean;
  nestedRouteWithBackButton?: {
    parentRoute: LinkModel;
    currentRouteName: string;
  };
  onChangeStep?: (x?: any) => void;
};

const ScreenHeader: React.FC<ScreenHeaderProps> = React.memo((props) => {
  const {
    id,
    title,
    icon,
    buttonScopes,
    buttonClassname,
    hideBorderBottom,
    subText,
    button,
    buttonIsLight,
    switchCheckedValue,
    switchButton,
    buttonFunction,
    description,
    steps,
    currentStep,
    onChangeStep,
    onChangeSwitchSelect,
    nestedRouteWithBackButton,
    titleClassname,
    subTextClassname,
    nextStepActive,
  } = props;
  const { t } = useTranslation();

  const availableScopes = useSelector(sessionSelectors?.getAllowedScopes);
  const hasScopes = !buttonScopes
    ? true
    : availableScopes
    ? userHasScopes(buttonScopes || [], availableScopes)
    : false;

  useEffect(() => {}, [availableScopes]);
  useEffect(() => {}, [buttonFunction]);

  return (
    <div id={id} className={styles.screenHeader}>
      {nestedRouteWithBackButton ? (
        <div className={styles.nestedRoutesBackButton}>
          <Link
            className={cx(
              styles.nestedRoutesBackButton__parent,
              "bodyRegularSM neutral1000"
            )}
            to={nestedRouteWithBackButton?.parentRoute?.route}
          >
            <ChevronLeftIcon size={24} />

            {t(nestedRouteWithBackButton?.parentRoute?.label)}
          </Link>
          {" / "}
          <span
            className={cx(
              styles.nestedRoutesBackButton__child,
              "bodyRegularSM primary700"
            )}
          >
            {t(nestedRouteWithBackButton?.currentRouteName)}
          </span>
        </div>
      ) : null}
      <div
        className={`${styles.screenHeader__topBar} ${
          hideBorderBottom ? "" : styles.borderBottom
        }`}
      >
        <div className={styles.screenHeader__topBar__title}>
          <div>
            <h2 className={cx("neutral1000 heading4", titleClassname)}>
              {t(title)}
            </h2>
            {subText ? (
              <p
                className={cx(
                  "bodyRegularLG neutral700 marginTop8",
                  subTextClassname
                )}
              >
                {t(subText)}
              </p>
            ) : null}
          </div>
        </div>
        {button && hasScopes ? (
          buttonIsLight ? (
            <GreyButton
              outlined
              className={`${
                (cx("margin-left-auto"), styles.modal__header__primaryButton)
              } ${buttonClassname ? buttonClassname : ""}`}
              action={buttonFunction ? buttonFunction : null}
              label={button?.label || ""}
              disabled={!!button?.disabled}
            />
          ) : (
            <PurpleButton
              className={`${styles.modal__header__primaryButton} ${
                buttonClassname ? buttonClassname : ""
              }`}
              action={buttonFunction ? buttonFunction : null}
              label={button?.label || ""}
              disabled={!!button?.disabled}
              disabledTooltip={button.disabledTooltip}
              hideIconTooltip={button.hideIconTooltip}
              disabledTooltipLabel={button.disabledTooltipLabel}
            />
          )
        ) : null}
      </div>
      {description ? (
        <div className={styles.screenHeader__description}>
          <Description
            className={cx("bodyRegularLG neutral700")}
            text={description}
          />
        </div>
      ) : null}
    </div>
  );
});

export default ScreenHeader;
