import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./actionPanel.module.scss";
import cx from "classnames";
import { ButtonModel } from "../../../../interfaces/interfaces";
import RedButton from "../../../atoms/buttons/red/RedButton";
import { ButtonSizes } from "../../../../data/display";

type IActionPanelProps = {
  title?: string;
  titleExtraTranslationParams?: Object;
  labelExtraTranslationParams?: Object;
  label: string;
  color: string;
  hasTitle?: boolean;
  rightButton?: ButtonModel;
  leftButton?: ButtonModel;
  className?: string;
};

const ActionPanel: React.FC<IActionPanelProps> = (props) => {
  const {
    title,
    titleExtraTranslationParams,
    labelExtraTranslationParams,
    label,
    color,
    rightButton,
    leftButton,
    hasTitle,
    className,
  } = props;

  const { t } = useTranslation();

  const setColorClass = () => {
    let colorClass = "yellow";
    switch (color) {
      case "red":
        colorClass = styles.red;
        break;
      case "yellow":
        colorClass = styles.yellow;
        break;
      default:
        colorClass = styles.yellow;
    }

    return colorClass;
  };

  return (
    <div
      className={`${styles.actionPanel} ${setColorClass()} ${
        className && className
      }`}>
      <div className={styles.actionPanel__header}>
        {hasTitle ? (
          <p
            className={`${rightButton || leftButton ? "" : styles.fullWidth} ${
              styles.title
            } ${cx("heading6 neutral1000")}`}>
            <Trans
              i18nKey={title}
              values={{ ...titleExtraTranslationParams }}
            />
          </p>
        ) : null}
        <div
          className={`${rightButton || leftButton ? "" : styles.fullWidth} ${
            styles.actionPanel__content__texts
          }`}>
          <p className={cx("bodyRegularSM neutral1000 marginTop4")}>
            <Trans
              i18nKey={label}
              values={{ ...labelExtraTranslationParams }}
            />
          </p>
        </div>
      </div>

      <div className={styles.actionPanel__content__buttons}>
        {leftButton && (
          <RedButton
            className={cx(styles.button)}
            size={ButtonSizes.MD}
            label={leftButton.label}
            disabled={false}
            action={leftButton.action}
          />
        )}
        {rightButton && (
          <RedButton
            className={styles.button}
            size={ButtonSizes.MD}
            label={rightButton.label}
            disabled={false}
            action={rightButton.action}
          />
        )}
      </div>
    </div>
  );
};

export default ActionPanel;
