import {
  Direction,
  TooltipAlignment,
} from "carbon-components-react/typings/shared";
import { Component, FunctionComponent, SVGProps } from "react";
import { subscriptionStatusOptions } from "../data/common";
import { ButtonSizes } from "../data/display";
import { ReduxActions } from "../features/Login/views/Login/store/loginTypes";

type supportedLanguage = "en" | "es" | "ca" | "eu" | "gl" | "pt";

export interface ImageModel {
  src: string;
  alt: string;
}

export interface PermissionsDataModel {
  scopes?: string[];
  every?: boolean;
}

export interface LinkModel {
  id?: string;
  label: string;
  route: string;
  outLink?: string;
  action?: (...args: any[]) => any;
  icon?: ImageModel;
  iconComponent?: any;
  status?: string;
  subRoutes?: LinkModel[];
  selected?: boolean;
  scopes?: string[];
}
export interface ctaLinkModel extends LinkModel {
  image?: ImageModel;
}
export interface ButtonModel {
  label: string;
  icon?: string;
  IconComponent?: any;
  image?: ImageModel;
  disabledIcon?: ImageModel;
  disabledTooltip?: boolean;
  hideIconTooltip?: boolean;
  disabledTooltipLabel?: string;
  svgIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  action?: ((x?: any) => void) | any | Function;
  outlined?: boolean;
  size?: ButtonSizes;
  className?: any;
  url?: string;
  fileName?: string;
}

export interface INewButtonModel {
  label: string;
  icon?: string;
  IconComponent?: any;
  outlined?: boolean;
  className?: any;
  url?: string;
  fileName?: string;
  action: (x?: any) => void;
}

export interface SwitchButtonModel {
  options: OptionModel[];
  function?: Function | void;
}

export interface OptionModel {
  text: string;
  value: string;
}
export interface StepModel {
  id: number;
  label: string;
  order: number;
  description?: string;
  route?: string;
  component?: Component | FunctionComponent | any;
}

export interface NotificationModel {
  // TODO: complete type when it has been defined
}

export interface PendingActionModel {
  // TODO: complete type when it has been defined
}

export interface TooltipPositionModel {
  align: TooltipAlignment;
  direction: Direction;
}

export interface TranslationModel {
  en?: string;
  es?: string;
}

export type LicenseToPreselect =
  | "Starter"
  | "Essential"
  | "Professional"
  | "Enterprise";

export type NotificationKind =
  | "error"
  | "info"
  | "info-square"
  | "success"
  | "warning"
  | "warning-alt";

export interface TsxIconProps {
  style?: "default" | "filled" | "active";
  size?: number;
  color?: string;
  className?: string;
}

export interface ILicenseItem {
  orgId: string;
  sbxId: string;
  licenseType: string;
  dids: string[];
  tenants: string[];
  createdAt: Date;
  updatedAt: Date;
  usage?: LicenseUsage;
}

export type LicenseUsage = Record<MetricCode, MetricUsage>;

export enum MetricCode {
  Cmn1 = "Cmn1",
  Cmn2 = "Cmn2",
  Cnt1 = "Cnt1",
  Cnt2 = "Cnt2",
  Ctf1 = "Ctf1",
  Ctf2 = "Ctf2",
}

export interface MetricUsage {
  current: number;
  max: number;
}
export interface IPresentationRequestModel {
  id: string;
  vmethodId?: string;
  callback?: string;
  sessionTTL?: number;
  domain?: string;
  credentials?: ICredentialRequestedModel[];
  security?: ISecurityConfig[];
}

export interface ICredentialRequestedModel extends CredentialModel {
  groupList?: string[];
  type?: string;
  purpose?: string;
  mandatory?: boolean;
  trustLevel?: number;
}

export interface ISecurityConfig {
  type: string;
  accepted: string[];
}

export interface BasicCredentialModel {
  id: string;
  name: TranslationModel;
}
export interface CredentialModel {
  id: string;
  name: TranslationModel;
  issuerList?: IssuerModel[];
  credentials?: BasicCredentialModel[] | null;
}

export interface IssuerModel {
  id: string;
  name?: TranslationModel;
  trustLevel?: number;
}

export interface IUserItem {
  id?: string;
  email: string;
  password?: string;
  status?: string;
  role?: string;
  tenants?: string[];
  createdAt?: string;
  updatedAt?: string;
  Provider: string;
  dids: string[];
  ssi_operations: string[];
  api_keys: string[];
  org_ID: string;
}

export interface IGaasReq {
  url: string;
  method: string;
  headers?: any;
  body?: any;
  vouch?: any;
  origin?: string;
  referer?: string;
  withCredentials?: boolean;
}

export type UserProfile = {
  email: string;
  name?: string;
  data: IUserItem;
  profilePic?: string;
};

export interface OrganizationModel {
  id: string;
  providerId?: string;
  orgInfo: {
    name: string;
    domainName: string;
    services?: {
      id: string;
      type: string;
      serviceEndpoint: string;
    }[];
    display?: {};
    areaGroup?: string;
    discoveryURL?: string;
    identifierBag?: string;
    legalPersonalIdentifier?: string;
    LEI?: string;
    SEED?: string;
    SIC?: string;
  };
  billingInfo?: {
    legalAddress: ILegalAddress;
    LegalName: string;
    vatRegistration: string;
    taxReference?: string;
  };
  createdAt?: string;
}

export interface ILegalAddress {
  street: string;
  suite?: string;
  number: string;
  locality: string;
  region: string;
  country: string;
  postalCode: string;
}
export interface ICreateDidModel {
  didMethod?: string;
  alias?: string;
}

export interface ActionModel {
  label: string;
  action: ((x?: any) => void) | any | Function;
  hasScope: boolean;
  color?: string;
}

export interface ISearchState {
  searched: string;
  searchedResult: any;
}

export interface IClientModel {
  name: string;
  orgId: string;
  plan: {
    type?: string; // tier finded by currentTierId in sub info
    period?: string; // currentPriceId
  };
  license?: ILicenseItem;
  subscription?: ISubscriptionInfo;
  org?: OrganizationModel;
  status?: subscriptionStatusOptions; // subscriptionStatus
  signUpDate?: string;
  nextPaymentDate?: string;
  amount?: number;
}

export type ISubscriptionInfo = {
  currentPeriodEnd: string;
  currentPeriodStart: string;
  currentPriceId: string;
  currentTierId: string;
  subscriptionSchedule?: {
    scheduledPriceId: string;
    scheduledTierId: string;
  };
  subscriptionStatus?: string;
  subscriptionNextPaymentAttempt?: string;
  subscriptionLivemode?: boolean;
  cancelAtPeriodEnd: boolean;
  orgId: string;
  paymentMethod?: object;
  paymentOutsideStripe: boolean;
  provider: string;
};

export interface ITierModel {
  id?: string;
  name: string;
  description: string;
  prices: IPriceModel[];
  provider?: string;
  isPopular?: boolean;
  ebsiDids?: boolean;
  index: number;
  limits: ILimitsModel[];
}

export interface IPriceModel {
  id?: string;
  name?: string;
  currency: string;
  recurringInterval: string;
  amount: number;
  type: string;
  numTrialDays?: number;
  active?: boolean;
}

export interface IProductFeaturesModel {
  activeUsersInfo?: string;
  issuedCredentialsInfo?: string;
  apiKeysInfo?: string;
  verificationTemplates?: number | string;
  activeUsers?: number | string;
  issuanceTemplates?: number | string;
  issuedCredentials?: number | string;
  dids: string;
  apiKeys: string;
  notifications: string[];
  stadistics: boolean;
  nFactor: string[];
  didPublicCatalogue: boolean;
  newSchemaPublicCatalogue: string;
  customQR: string;
  support: string;
  slas: boolean;
  credentialsInW3CFormat: boolean;
  sandBoxEnvironment: boolean;
  ebsiIntegration: boolean;
  customGDPRPurposes: boolean;
  credentialsInISO28013: boolean;
  integrationCustomTrustFrameworks: boolean;
  integrationCustomTrustInfraestructure: boolean;
  standardSupport: boolean | string;
  premierSupport: boolean | string;
}

export interface ILimitsModel {
  Code: MetricCode;
  Value: number;
  Description: string;
  Active?: boolean;
}

export interface IChangeSubData {
  priceId?: string;
  periodStart?: string;
  periodEnd?: string;
}

export interface PermissionsDataModel {
  scopes?: string[];
  every?: boolean;
}

export interface IModalButtons {
  primary: {
    label: string;
    action?: (x?: any) => void;
  };
  secondary: {
    label: string;
    action: (x?: any) => void;
  };
}
export interface StatisticsParams {
  org: string;
  begin?: string;
  end?: string;
  interval?: statsInterval;
  window?: statsWindow;
  increment?: boolean;
}

export type statsInterval = "D" | "W" | "M" | "Y";

export type statsWindow = "current" | "previous";

export interface MetricValue {
  value: number;
  time: Date;
  dStr?: string;
}

export interface MetricStatistic {
  id: MetricCode;
  name: string;
  begin: Date;
  end: Date;
  values: MetricValue[];
  percentage?: number;
  variation?: number;
  variation_relative?: any;
  percentage_relative?: any;
}

export type Statistics = Record<MetricCode, MetricStatistic>;

export interface NumberFormatOptions {
  notation: "compact" | "standard" | "scientific" | "engineering" | undefined;
  compactDisplay: "short" | "long" | undefined;
  minimumFractionDigits: 0;
}
export interface IDataChart {
  x: Date;
  y: number;
  dStr?: string;
}

export interface IGeneralSelectOption {
  id?: string;
  text: string;
  value: any;
  action?: (x?: any) => void | void;
}

export interface IPercentageVariationChart {
  activeUsers?: number;
  apiKeys?: number;
  dids?: number;
  issuanceTemplates?: number;
  issuedCredentials?: number;
  verifierTemplates?: number;
}
