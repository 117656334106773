import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { scafoldingSelectors } from "../../store";

import { screenLoaderActive } from "../../../Loader/store/loaderSelectors";
import {
  default as Loader,
  default as ScreenLoader,
} from "../../../Loader/components/screenLoader/ScreenLoader";
// @ts-ignore
import PanelStyles from "./PanelScafoldingPrivate.module.scss";
import {
  sideMenu,
  sideMenuExtraMobile,
  sideMenuSettings,
} from "./panelScafoldingPrivateConstants";
import { history } from "../../../../services/history";
import settingsIcon from "../../../../assets/iconsLibrary/settings/settingsIcon";
import Notification from "../../../Notification/Notification";
import { setSelectedRoute, setSettings } from "../../store/scafoldingActions";
import BurgerMenuButton from "../../../../components/elements/navigation/BurgerMenuButton/BurgerMenuButton";
import DropdownMenu from "../../../../components/elements/navigation/DropdownMenu/DropdownMenu";
import { Header } from "carbon-components-react";
import { gaImages } from "../../../../data/images";
import GaImage from "../../../../components/atoms/Image/Image";
import { sessionSelectors } from "../../../Settings/Profile/store";
import { userHasScopes } from "../../../../utils/permissionsUtil";
import cx from "classnames";
import { gaColors } from "../../../../data/display";
import ChevronLeftIcon from "../../../../assets/iconsLibrary/simple/chevronLeft/chevronLeftIcon";
import WarningModal from "../../../../components/elements/modals/warninModal/WarningModal";

type IPanelSProps = {
  hideNavBar?: boolean;
  children?: React.ReactNode;
};

const PanelScafoldingPrivate: React.FC<IPanelSProps> = (props) => {
  const { hideNavBar, children } = props;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const currentUrlRoute = location.pathname;
  const activeRoute = useSelector(scafoldingSelectors.activeRoute);
  const isSettings = useSelector(scafoldingSelectors.showSettings);
  const showScreenLoader = useSelector(screenLoaderActive);
  const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes);
  const [showSureGoBackModal, setShowSureGoBackModal] = React.useState(false);

  const userCanReadProviders = () => {
    return (
      allowedScopes?.length && userHasScopes(["readProviders"], allowedScopes)
    );
  };

  const goToHome = () => {
    // TODO: Add redux to select a back route for nexts features
    dispatch(setSelectedRoute("platform-configuration"));
    navigate("/platform-configuration");
  };

  const deleteModalButtons = {
    primary: { label: "general.yesGoBack", action: goToHome },
    secondary: {
      label: "general.noStay",
      action: () => setShowSureGoBackModal(false),
    },
  };

  const sideMenuExtraMobileList = sideMenuExtraMobile;

  const [expanded, setExpanded] = useState([""]);

  useEffect(() => {}, [dispatch, currentUrlRoute, showScreenLoader]);

  return (
    <Suspense fallback={<Loader />}>
      {!!userCanReadProviders() ? (
        <>
          <Header
            id={"topOfTheWiew"}
            aria-label="Admin"
            className={PanelStyles.topbar}>
            {!hideNavBar ? (
              <BurgerMenuButton
                menuOptions={isSettings ? sideMenuSettings : sideMenu}
                userEmail={""}
                userImage={""}
                expanded={expanded}
                selected={activeRoute}
                setExpanded={setExpanded}
              />
            ) : null}

            <Link
              className={PanelStyles.logoCont}
              to="/"
              onClick={() => (
                dispatch(setSettings()), dispatch(setSelectedRoute("home"))
              )}>
              <GaImage image={gaImages.logoPlatform} />
            </Link>

            {!hideNavBar ? (
              <div className={PanelStyles.rightSpace}>
                <DropdownMenu
                  menuOptions={sideMenuExtraMobileList()}
                  icon={gaImages.settingsIcon}
                  iconComponent={settingsIcon}
                  iconClassName={PanelStyles.user__image}></DropdownMenu>
              </div>
            ) : null}
          </Header>
          <div>
            {hideNavBar ? (
              <div className={PanelStyles.goBack}>
                <span
                  onClick={() => setShowSureGoBackModal(true)}
                  className={cx("bodyRegularSM")}>
                  <ChevronLeftIcon size={24} color={gaColors.neutral1000} />
                  Go back
                </span>
              </div>
            ) : null}
            <section
              className={cx(
                PanelStyles.panelContainer,
                hideNavBar && PanelStyles.panelContainerTwo
              )}>
              <div>
                {children} <Notification className={PanelStyles.extraPanel} />
              </div>
              {showSureGoBackModal ? (
                <WarningModal
                  title={"general.sureGoBack"}
                  description={"general.sureGoBackDesc"}
                  primaryButton={deleteModalButtons.primary}
                  secondaryButton={deleteModalButtons.secondary}
                  hideModal={() => setShowSureGoBackModal(false)}
                  item={undefined}
                />
              ) : null}
            </section>
          </div>
          {showScreenLoader && <ScreenLoader />}
        </>
      ) : null}
    </Suspense>
  );
};

PanelScafoldingPrivate.defaultProps = {};

export default PanelScafoldingPrivate;
