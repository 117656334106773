import {
  INavigate,
  ISetActiveRoute,
  ISetSettings,
  ScafoldingActionsTypes,
} from "./scafoldingTypes";

// NAVIGATION
export const setSelectedRoute = (route: string): ISetActiveRoute => {
  return {
    type: ScafoldingActionsTypes.SET_ACTIVE_ROUTE,
    payload: {
      route: route,
    },
  };
};

export const navigateAction = (path?: string): INavigate => {
  return {
    type: ScafoldingActionsTypes.NAVIGATE,
    path: path,
  };
};

// NAVIGATION
export const setSettings = (useSettings?: any): any => {
  let settings = useSettings !== undefined;
  return {
    type: ScafoldingActionsTypes.SET_SETTINGS_PANEL,
    payload: {
      settings: settings,
    },
  };
};
