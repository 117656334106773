import { NotificationKind } from "../../../interfaces/interfaces";
import {
  IHideNotificationAction,
  IShowNotificationAction,
  NotificationActionTypes,
} from "./notificationTypes";

export const showNotification = (
  title: string,
  kind: NotificationKind,
  subtitle: string,
  status?: number,
  duration?: number
): IShowNotificationAction => {
  return {
    type: NotificationActionTypes.NOTIFICATION_SHOW,
    payload: {
      notification: {
        title: title,
        kind: kind,
        subtitle: subtitle,
        status: status,
        duration: duration,
      },
    },
  };
};

export const hideNotification = (): IHideNotificationAction => {
  return {
    type: NotificationActionTypes.NOTIFICATION_HIDE,
  };
};
