import React, { useEffect, useState } from "react";
import PanelScafoldingPrivate from "../../PanelScafolding/components/private/PanelScafoldingPrivate";
import { useTranslation } from "react-i18next";
import {
  getClientStatistics,
  getStatistics,
  setSelectedPeriodIsYear,
} from "../store/homeActions";
import { useDispatch, useSelector } from "react-redux";
import { homeSelectors } from "../store";
import { ActionStatusTypes } from "../../../data/common";
import ScreenHeader from "../../../components/elements/headers/ScreenHeader/ScreenHeader";
import HomeBlocks from "../components/homeBlocks/HomeBlocks";
import {
  blocksData,
  filterDatesOptions,
  subBlocksData,
} from "../data/home.constants";
import HomeSubBlocks from "../components/homeSubBlocks/HomeSubBlocks";
import styles from "./home.module.scss";
import DatesFilter from "../components/filters/datesFilter/datesFilter";
import QuantitiesChart from "../components/quantitiesChart/quantitiesChart";
import { IGeneralSelectOption } from "../../../interfaces/interfaces";
import ModalDatePicker from "../../../components/elements/forms/formFields/modalRangeDatePicker/modalDatePicker";
import moment from "moment";
import { getOrganizationsAction } from "../../Clients/store/clientsActions";
import { clientsSelectors } from "../../Clients/store";
import { setSelectedRoute } from "../../PanelScafolding/store/scafoldingActions";
import { deleteSession } from "../../Settings/Profile/store/sessionActions";
import { history } from "../../../services/history";
import { sessionSelectors } from "../../Settings/Profile/store";

type IHomeProps = {};

const Home: React.FC<IHomeProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const filterDatesOptions: IGeneralSelectOption[] = [
    {
      id: "W",
      text: "dashboard.last7Days",
      value: "7",
      action: () => (
        setSelectedFilterDate("7"), dispatch(setSelectedPeriodIsYear(false))
      ),
    },
    {
      id: "M",
      text: "dashboard.last30Days",
      value: "30",
      action: () => (
        setSelectedFilterDate("30"), dispatch(setSelectedPeriodIsYear(false))
      ),
    },
    {
      id: "Y",
      text: "dashboard.last365Days",
      value: "365",
      action: () => (
        setSelectedFilterDate("365"), dispatch(setSelectedPeriodIsYear(true))
      ),
    },
    // {
    //   id: "C",
    //   text: "dashboard.custom",
    //   value: "100",
    //   action: () => setShowRangeModal(true),
    // },
  ];

  const [selectedFilterPeriod, setSelectedFilterDate] = useState<string>(
    filterDatesOptions[0]?.value
  );
  const [showRangeModal, setShowRangeModal] = useState<boolean>(false);

  const loadingStatistics =
    useSelector(homeSelectors.getStastisticsStatus) ===
    ActionStatusTypes?.loading;

  const featuresNumber = useSelector(homeSelectors.getUsedFeaturesNumber);
  const featuresPercentages = useSelector(
    homeSelectors.getUsedFeaturesPercentages
  );
  const featuresVariations = useSelector(
    homeSelectors.getUsedFeaturesVariations
  );
  const getClientsDataLoading = useSelector(
    clientsSelectors.getClientsDataStatus
  );
  const clientsList = useSelector(clientsSelectors.getClients);
  const user = useSelector(sessionSelectors.getUserEmail_s);
  const dataloaded = useSelector(sessionSelectors.getSessionRead);
  const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes);

  const getSelectedPeriodLabel = filterDatesOptions?.find(
    (option) => option?.value === selectedFilterPeriod
  )?.text;

  const getEndDate = moment()?.format("YYYY-MM-DDTHH:mm:ss[Z]"); // today date
  const getBeginDate = moment()
    ?.subtract(parseInt(selectedFilterPeriod) - 1, "d")
    ?.format("YYYY-MM-DDTHH:mm:ss[Z]"); // selected period start date

  const statsParamsURL = `?end=${getEndDate}&begin=${getBeginDate}&window=current`;

  const goToLoginAndDeleteSession = () => {
    dispatch(setSelectedRoute("login"));
    history.push("/login");
  };

  useEffect(() => {
    if (!!user && allowedScopes) {
      !loadingStatistics && dispatch(getClientStatistics(statsParamsURL));
      !loadingStatistics && dispatch(getStatistics(statsParamsURL));
      !getClientsDataLoading &&
        !clientsList &&
        dispatch(getOrganizationsAction());
    } else {
      goToLoginAndDeleteSession();
    }
  }, [selectedFilterPeriod, dataloaded]);

  const dataLoading = getClientsDataLoading || loadingStatistics;

  return (
    <PanelScafoldingPrivate>
      <div className={styles.header__container}>
        <ScreenHeader
          title={"dashboard.screenTitle"}
          subText={"dashboard.description"}
        />
        <div className={styles.headerRightContent}>
          <DatesFilter
            activePeriodLabel={t(getSelectedPeriodLabel || "")}
            selectedFilterDate={selectedFilterPeriod}
            setSelectedFilterDate={setSelectedFilterDate}
            datesOptions={filterDatesOptions}
            dataLoading={dataLoading}
          />

          {showRangeModal ? <ModalDatePicker /> : null}
        </div>
      </div>
      <div className={styles.homeMetrics__container}>
        <div className={styles.homeMetrics__homeBlocks}>
          <HomeBlocks
            blocksData={blocksData}
            statsNumberApi={featuresNumber}
            dataLoading={dataLoading}
            selectedPeriodDays={selectedFilterPeriod}
            featuresPercentages={featuresPercentages}
            clientsTotal={clientsList?.length || 0}></HomeBlocks>
        </div>
        <div className={styles.homeMetrics__chart}>
          <QuantitiesChart
            dataLoading={dataLoading}
            selectedPeriodDays={selectedFilterPeriod}
            featuresPercentages={featuresPercentages}
            featuresVariations={featuresVariations}
          />
        </div>
      </div>
      <HomeSubBlocks
        subBlocksData={subBlocksData}
        statsNumberApi={featuresNumber}
        dataLoading={dataLoading}
        clientsTotal={clientsList?.length || 0}></HomeSubBlocks>
    </PanelScafoldingPrivate>
  );
};

Home.defaultProps = {};

export default Home;
