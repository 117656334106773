import { Tooltip } from "carbon-components-react";
import cx from "classnames";
import React, { useState } from "react";
import {
  ButtonModel,
  TooltipPositionModel,
} from "../../../../interfaces/interfaces";
import styles from "./hoverTooltip.module.scss";
import GreyButton from "../../../atoms/buttons/grey/GreyButton";

type IHoverTooltipProps = {
  Label: React.ReactNode | string;
  info: string;
  clear?: boolean;
  blue?: boolean;
  index?: number;
  position?: TooltipPositionModel;
  positionMobile?: TooltipPositionModel;
  positionTablet?: TooltipPositionModel;
  extraTranslationParams?: Object;
  button?: ButtonModel;
  hideIcon?: boolean;
  className?: string;
  link?: {
    label: string;
    url: string;
  };
  buttonFunction?: (x?: any) => void;
  onHandleChange?: (x: any) => void;
};

const HoverTooltip: React.FC<IHoverTooltipProps> = (props) => {
  const {
    clear,
    blue,
    index,
    Label,
    className,
    info,
    extraTranslationParams,
    button,
    link,
    onHandleChange,
    hideIcon,
    position,
    positionMobile,
    positionTablet,
  } = props;
  let show = false;

  const [hover, setHover] = useState(false);

  const toggleButton = (index?: number) => {
    if (onHandleChange) {
      onHandleChange(index);
    }
    show = !show;
  };

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className={`${styles.hoverTooltipContainer} ${
        className ? className : ""
      } ${hideIcon ? styles.hideIcon : ""}`}
    >
      <Tooltip
        open={hover}
        tooltipId={
          clear
            ? styles.clearHoverTooltipTablet
            : blue
            ? styles.blueHoverTooltipTablet
            : ""
        }
        align={
          positionTablet
            ? positionTablet?.align
            : positionMobile?.align || "start"
        }
        direction={
          positionTablet
            ? positionTablet?.direction
            : positionMobile?.direction || "top"
        }
        triggerClassName={`${styles.hoverTooltip} ${styles.hoverTooltipTablet}`}
        triggerText={Label}
        onChange={() => toggleButton(index)}
      >
        <>
          {Label}
          {button ? (
            <GreyButton
              className={cx("margin-top", styles.tooltipButton)}
              outlined
              label={button.label}
              disabled={false}
              action={button?.action ? button?.action : null}
            />
          ) : null}
        </>
      </Tooltip>
    </div>
  );
};

export default HoverTooltip;
