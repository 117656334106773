export const contentSP = `
    connect-src 'self' 
    ${process.env.REACT_APP_CONNECT_HOST} 
    ${process.env.REACT_APP_GAAS_HOST}  
    ${process.env.REACT_APP_NUCLEUS_HOST}  
    ${process.env.REACT_APP_CERTIFY_HOST} 
    ${process.env.REACT_APP_VOUCH_API}
    data: ;

    default-src 'self' 
    data: ;

    img-src 'self' 
    data: ;


    style-src 'self' 
    https://fonts.googleapis.com/ 
    https://fonts.gstatic.com/ 
    'sha256-0hAheEzaMe6uXIKV4EehS9pu1am1lj/KnnzrOYqckXk='
    'sha256-fWMZWhOCcgTibzAvyPuhBp48OhJBLjI2e1MBzTauoI0='
    'sha256-dKPMtStvhWirlTIky2ozsboS0Q6fEpiYn8PJwiK2ywo='
    'sha256-2j+NsrE/qRlmhkADxLdqK0AALIC4Gcc77SVRgwXmYCc='
    'sha256-0eSNDQdRxaHNpoG9OmzMqvKRlbzGsG1Kw8qS/GkeOLU='
    'sha256-QyyQvX4zVL4sgEmWCajnJMEVxf+HtL/O+66S7X1oqss='
    'sha256-cI6npbLAYrmkX3bdF4enQmhGkRX5+4yR2pVU4vNmPV8='
    'sha256-2j+NsrE/qRlmhkADxLdqK0AALIC4Gcc77SVRgwXmYCc='
    'sha256-9i4CO/Nl+gX45HxIVK0YGg311ZbVCsEZzl4uJ47ZNOo=' 
    'sha256-j7x6Tz1JB3awWTnL8QxOgkIYUS2sbLrGr4WoJYlsqgc=' 
    'sha256-47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU='
    'sha256-VvguK34k3gka79MlGWU9vwxWpd1OaXxtDPI8MCikW5U=' ;

    font-src 'self' 
    https://fonts.googleapis.com/ 
    https://fonts.gstatic.com/ 
`;
