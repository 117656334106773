import { ActionStatusTypes } from "../../../data/common";
import { ITierModel } from "../../../interfaces/interfaces";

export interface ITiersState {
  tiers?: ITierModel[];
  selectedTier?: ITierModel;
  loadingStatus: boolean;
  error: string;
  getTiersStatus: ActionStatusTypes;
  createTierStatus: ActionStatusTypes;
  deleteTierStatus: ActionStatusTypes;
  updateTierStatus: ActionStatusTypes;
}

export enum TiersTypes {
  GET_TIERS = "tiers/get",
  GET_TIERS_SUCCESS = "tiers/get_success",
  GET_TIERS_FAILED = "tiers/get_failed",
  CREATE_TIER = "tiers/create_tier",
  CREATE_TIER_SUCCESS = "tiers/create_tier_success",
  CREATE_TIER_FAILED = "tiers/create_tier_failed",
  DELETE_TIER = "tiers/delete_tier",
  DELETE_TIER_SUCCESS = "tiers/delete_tier_success",
  DELETE_TIER_FAILED = "tiers/delete_tier_failed",
  UPDATE_TIER = "tiers/update_tier",
  UPDATE_TIER_SUCCESS = "tiers/update_tier_success",
  UPDATE_TIER_FAILED = "tiers/update_tier_failed",
  SELECT_TIER = "tiers/select_tier",
}
export interface IGetTiers {
  type: TiersTypes.GET_TIERS;
  payload: {
    providerId: string;
  };
}

export interface IGetTiersSuccess {
  type: TiersTypes.GET_TIERS_SUCCESS;
  payload: {
    tiers: ITierModel[];
  };
}

export interface IGetTiersFailed {
  type: TiersTypes.GET_TIERS_FAILED;
  payload: {
    error: string;
  };
}

// Create

export interface ICreateTier {
  type: TiersTypes.CREATE_TIER;
  payload: {
    tier: ITierModel;
  };
}

export interface ICreateTierSuccess {
  type: TiersTypes.CREATE_TIER_SUCCESS;
  payload: {
    message: string;
    tier: ITierModel;
  };
}

export interface ICreateTierFailed {
  type: TiersTypes.CREATE_TIER_FAILED;
  payload: {
    error: string;
  };
}

// Delete

export interface IDeleteTier {
  type: TiersTypes.DELETE_TIER;
  payload: {
    tierID: string;
    provider: string;
  };
}

export interface IDeleteTierSuccess {
  type: TiersTypes.DELETE_TIER_SUCCESS;
  payload: {
    id: string;
  };
}

export interface IDeleteTierFailed {
  type: TiersTypes.DELETE_TIER_FAILED;
}

// Update
export interface IUpdateTier {
  type: TiersTypes.UPDATE_TIER;
  payload: {
    tier: ITierModel;
  };
}

export interface IUpdateTierSuccess {
  type: TiersTypes.UPDATE_TIER_SUCCESS;
  payload: {
    tier: ITierModel;
  };
}

export interface IUpdateTierFailed {
  type: TiersTypes.UPDATE_TIER_FAILED;
  payload: {
    error: string;
  };
}

// Select
export interface SelectTier {
  type: TiersTypes.SELECT_TIER;
  payload: {
    tier?: ITierModel;
  };
}
export type ITiersActions =
  | IGetTiers
  | IGetTiersSuccess
  | IGetTiersFailed
  | ICreateTier
  | ICreateTierSuccess
  | ICreateTierFailed
  | IDeleteTier
  | IDeleteTierSuccess
  | IDeleteTierFailed
  | IUpdateTier
  | IUpdateTierSuccess
  | IUpdateTierFailed
  | SelectTier;
