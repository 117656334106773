import { createSelector } from "reselect";
import { ActionStatusTypes } from "../../../data/common";
import { RootState } from "../../../store/indexReducers";
import { ITierModel } from "../../../interfaces/interfaces";
import { getLimit } from "../../../utils/licensesUtil";

export const getTiersStatus = (state: RootState) =>
  state.tiersReducer.getTiersStatus;

export const getTiers = (state: RootState) => state.tiersReducer.tiers;

export const getSelectedTier = (state: RootState) =>
  state.tiersReducer.selectedTier;

export const getSortedTiers = createSelector([getTiers], (tiers) => {
  return sortTiersByIndex(tiers);
});

const sortTiersByIndex = (tiers?: ITierModel[]) => {
  return tiers?.sort((a, b) => (a.index > b.index ? 1 : -1));
};
