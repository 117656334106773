import React from "react";
import cx from "classnames";
import styles from "../licenseCard.module.scss";
import { gaColors } from "../../../../../data/display";
import CheckIcon from "../../../../../assets/iconsLibrary/simple/check/checkIcon";
import InfoIcon from "../../../../../assets/iconsLibrary/simple/info/infoIcon";
import { t } from "i18next";
import { ILimitsModel } from "../../../../../interfaces/interfaces";
import { getTierLimitToDisplay } from "../../../../../utils/licensesUtil";

type ILicenseCardFeatureProps = {
  featureCode: string;
  limits: ILimitsModel[];
  label: string;
  info?: string;
};

const LicenseCardFeature: React.FC<ILicenseCardFeatureProps> = (props) => {
  const { featureCode, limits, label, info } = props;

  const feature = limits?.find((limit) => limit?.Code === featureCode);
  const featureLimit = feature?.Value;

  let formattedFeatureLimit = getTierLimitToDisplay(featureLimit);

  return (
    <li
      className={`${styles?.license__features__list__item} ${cx(
        "marginTop16"
      )}`}>
      <CheckIcon size={16} color={gaColors.success300} />

      <div className={styles?.license__features__list__item__content}>
        {feature && (
          <span className={cx("bodyBoldMD")}> {formattedFeatureLimit}</span>
        )}

        <span className={cx("bodyRegularSM marginLeft4", styles?.featureName)}>
          {label}
        </span>
        {info ? (
          <span
            className={cx("bodyRegularSM", styles.toogle)}
            aria-label={t(info)}
            tooltip-position="top">
            <InfoIcon size={16} color={gaColors.neutral1000} />
          </span>
        ) : null}
      </div>
    </li>
  );
};

export default LicenseCardFeature;
