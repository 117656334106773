import React from "react";
import styles from "./extProviderActionsCell.module.scss";
import {
  subscriptionExtProviderOptions,
  subscriptionStatusOptions,
} from "../../../../../../data/common";
import cx from "classnames";
import { IClientModel } from "../../../../../../interfaces/interfaces";
import PurpleButton from "../../../../../../components/atoms/buttons/purple/PurpleButton";
import RedButton from "../../../../../../components/atoms/buttons/red/RedButton";
import { t } from "i18next";
import { ButtonSizes } from "../../../../../../data/display";

type ExtProviderActionsCellProps = {
  item: IClientModel;
  showCancelModal?: (e?: any) => void;
  showActivateModal?: (e?: any) => void;
};

export const ExtProviderActionsCell: React.FC<ExtProviderActionsCellProps> = (
  props
) => {
  const { item, showActivateModal, showCancelModal } = props;

  // Functions
  function getActionOption(currentStatus?: subscriptionStatusOptions) {
    let action;
    let text;
    let color;
    const status = currentStatus && subscriptionStatusOptions[currentStatus];
    action = status
      ? subscriptionExtProviderOptions[status]?.action
      : undefined;
    color = status ? subscriptionExtProviderOptions[status]?.color : undefined;
    text = status ? subscriptionExtProviderOptions[status]?.text : "";

    return {
      action: action,
      color: color,
      text: text,
    };
  }

  function getActionCellContent(x: any) {
    if (getActionOption) {
      return {
        color: getActionOption(x)?.color || "blue",
        text: getActionOption(x)?.text || x?.text,
        action: getActionOption(x)?.action,
      };
    }
  }

  // Variables
  const status = item?.status;
  const color = getActionCellContent(status)?.color;
  const text = getActionOption(status)?.text || status || "";

  return (
    <td className={styles.extProvideActionsCell}>
      {getActionCellContent(status)?.text ? (
        <div className={styles.extProvideActions}>
          {color === "purple" ? (
            <PurpleButton
              size={ButtonSizes.SM}
              label={t(text)}
              action={() => showActivateModal && showActivateModal(item)}
            />
          ) : color ? null : /* TODO DEV-3869
            <RedButton  
            size={ButtonSizes.SM} 
            label={t(text)}
            outlined
            action={() => showCancelModal && showCancelModal(item)}
           /> */ null}
        </div>
      ) : (
        <p className={cx("buttonMD neutral1000")}>-</p>
      )}
    </td>
  );
};
