import React from 'react'

type MoreVerticalIconProps = {
    size?: number
    color?: string
    className?: string
}

const MoreVerticalIcon: React.FC<MoreVerticalIconProps> = React.memo(
    (props) => {
        const { size, color, className } = props

        return (
            <svg
                className={className && className}
                width={size || '32'}
                height={size || '32'}
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15.9998 17.3333C16.7362 17.3333 17.3332 16.7364 17.3332 16C17.3332 15.2636 16.7362 14.6667 15.9998 14.6667C15.2635 14.6667 14.6665 15.2636 14.6665 16C14.6665 16.7364 15.2635 17.3333 15.9998 17.3333Z"
                    stroke={color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.9998 7.99999C16.7362 7.99999 17.3332 7.40304 17.3332 6.66666C17.3332 5.93028 16.7362 5.33333 15.9998 5.33333C15.2635 5.33333 14.6665 5.93028 14.6665 6.66666C14.6665 7.40304 15.2635 7.99999 15.9998 7.99999Z"
                    stroke={color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.9998 26.6667C16.7362 26.6667 17.3332 26.0697 17.3332 25.3333C17.3332 24.597 16.7362 24 15.9998 24C15.2635 24 14.6665 24.597 14.6665 25.3333C14.6665 26.0697 15.2635 26.6667 15.9998 26.6667Z"
                    stroke={color || 'currentColor' || '#1E1E20'}
                    strokeWidth="2.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        )
    }
)

export default MoreVerticalIcon
