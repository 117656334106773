import { ComboBox, Select, SelectItem } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import style from "./gaSelect.module.scss";
import cx from "classnames";
import { userHasScopes } from "../../../../../utils/permissionsUtil";
import { sessionSelectors } from "../../../../../features/Settings/Profile/store";
import { getPropertyByLang } from "../../../../../utils/formatDataUtil";
import InputSkeleton from "../../../skeletons/inlineSkeleton/InputSkeleton";
import PermissionsPanel from "../../../panels/permissionsPanel/PermissionsPanel";
import { PermissionsDataModel } from "../../../../../interfaces/interfaces";

type GaSelectProps = {
  id?: string;
  labelText?: string;
  placeholder?: string;
  helperText?: string;
  value?: string;
  disabled?: boolean;
  invalidText?: string;
  invalid?: boolean;
  readOnly?: boolean;
  name?: string;
  required?: boolean;
  light?: boolean;
  inline?: boolean;
  size?: "sm" | "xl" | undefined;
  className?: string;
  options: any[];
  optionTextProperty?: string;
  optionTextPropertyTranslated?: boolean;
  optionValueProperty?: string;
  autoComplete?: string;
  isComboBox?: boolean;
  isLoading?: boolean;
  permissions?: PermissionsDataModel;
  onChange?: (e?: any) => void;
};

const GaSelect: React.FC<GaSelectProps> = React.memo((props) => {
  const {
    id,
    labelText,
    placeholder,
    className,
    helperText,
    disabled,
    name,
    value,
    permissions,
    invalidText,
    invalid,
    light,
    readOnly,
    inline,
    size,
    required,
    options,
    optionTextProperty,
    optionTextPropertyTranslated,
    optionValueProperty,
    autoComplete,
    isComboBox,
    isLoading,
    onChange,
  } = props;
  const { t } = useTranslation();

  const [availableOptions, setOptions] = useState(new Array());
  const [touched, setTouched] = useState(false);
  const availableScopes = useSelector(sessionSelectors?.getAllowedScopes);
  const hasScopes = !permissions?.scopes
    ? true
    : availableScopes
    ? userHasScopes(
        permissions?.scopes || [],
        availableScopes,
        permissions?.every
      )
    : false;

  useEffect(() => {}, [availableScopes]);

  useEffect(() => {
    setOptions(options);
  }, [options]);

  return (
    <div
      className={`${className} ${style.gaSelectContainer} ${
        required ? style.required : ""
      } ${disabled ? style.readOnly : ""}`}
    >
      {!permissions?.scopes || hasScopes ? (
        !isLoading ? (
          isComboBox ? (
            <>
              <label className={`${cx("neutral1000", style.comboBoxLabel)}`}>
                {t(labelText || "")}
              </label>
              <ComboBox
                className={`${style.comboBox}`}
                id={labelText || ""}
                placeholder={placeholder || ""}
                items={options}
                value={value}
                invalid={touched ? invalid : false}
                invalidText={t(invalidText || "")}
                itemToString={(item) => (item ? item : "")}
                onChange={(e) => {
                  onChange ? onChange(e) : () => {};
                }}
                disabled={disabled || readOnly}
                onBlur={() => setTouched(true)}
              />
            </>
          ) : (
            <Select
              disabled={disabled || readOnly}
              id={id ? id : labelText || "" + Math.random()}
              helperText={helperText || ""}
              invalidText={t(invalidText || "")}
              labelText={labelText ? <Trans t={t}>{labelText}</Trans> : ""}
              name={name ? name : ""}
              onChange={(e) => {
                onChange ? onChange(e) : () => {};
              }}
              onBlur={() => setTouched(true)}
              required={required || false}
              autoComplete={autoComplete ? autoComplete : "Off"}
              placeholder={placeholder ? t(placeholder) : ""}
              inline={inline || false}
              size={size || undefined}
              invalid={touched ? invalid : false}
              value={value || ""}
            >
              {!!availableOptions?.length
                ? availableOptions?.map((item, index) => {
                    return (
                      <SelectItem
                        key={index}
                        text={
                          optionTextProperty
                            ? optionTextPropertyTranslated
                              ? getPropertyByLang(item[optionTextProperty])
                              : t(item[optionTextProperty])
                            : item
                        }
                        value={
                          optionValueProperty ? item[optionValueProperty] : item
                        }
                        disabled={item.disabled || false}
                      />
                    );
                  })
                : null}
            </Select>
          )
        ) : (
          <InputSkeleton
            className={className || ""}
            labelText={labelText}
            required={required}
          />
        )
      ) : (
        <PermissionsPanel
          scopes={permissions?.scopes}
          labelText={labelText}
          requiredLabel={required}
        />
      )}
    </div>
  );
});

export default GaSelect;
