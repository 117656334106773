import { act } from "react-dom/test-utils";
import { ActionStatusTypes } from "../../../../../../../data/common";
import {
  IMetricsActions,
  IMetricsState,
  StatisticsTypes,
} from "./metricsTypes";

const statisticsState: IMetricsState = {
  getStaticsStatus: ActionStatusTypes.neutral,
  statistics: undefined,
  selectedPeriodIsYear: false,
};
export const statisticsMetricsReducer = (
  state = statisticsState,
  action: IMetricsActions
): IMetricsState => {
  switch (action.type) {
    case StatisticsTypes.GET_ALL_STATISTICS:
      return {
        ...state,
        getStaticsStatus: ActionStatusTypes.loading,
      };
    case StatisticsTypes.GET_ALL_STATISTICS_SUCCESS:
      return {
        ...state,
        getStaticsStatus: ActionStatusTypes.success,
        statistics: action.payload.statistics,
      };
    case StatisticsTypes.GET_ALL_STATISTICS_FAILED:
      return {
        ...state,
        getStaticsStatus: ActionStatusTypes.failed,
        statistics: undefined,
      };

    case StatisticsTypes.SET_SELECTED_PERIOD_YEAR:
      return {
        ...state,
        selectedPeriodIsYear: action?.payload?.isYear,
      };
    default:
      return state;
  }
};
