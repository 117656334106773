import * as React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import styles from "./metricsBlocks.module.scss";
import {
  formatNumberToCmpct,
  formatNumberToEU,
} from "../../../../../../../../utils/formatDataUtil";
import CardListSkeleton from "../../../../../../../../components/elements/skeletons/cardListSkeleton/CardListSkeleton";
import PercentagesMetricsBlocks from "../percentagesFeatures/percentagesMetricsBlocks/percentagesMetricsBlocks";
import { IPercentageVariationChart } from "../../../../../../../../interfaces/interfaces";
import { formatNumbersDefaultOptions } from "../../../../../../../../data/common";

export type IMetricsBlocksProps = {
  statsNumberApi?: {
    activeUsers: number;
    apiKeys: number;
    dids: number;
    issuanceTemplates: number;
    issuedCredentials: number;
    verifierTemplates: number;
  };
  featuresPercentages?: IPercentageVariationChart;
  blocksData: {
    id: string;
    classNameBox: string;
    classNameSubBox: string;
    title: string;
    statsNumber?: number;
  }[];
  dataLoading?: boolean;
  selectedFilterPeriod: string;
};

const MetricsBlocks: React.FC<IMetricsBlocksProps> = (props) => {
  const {
    blocksData,
    statsNumberApi,
    dataLoading,
    featuresPercentages,
    selectedFilterPeriod,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.metricsBlocks__container}>
      {!dataLoading ? (
        statsNumberApi &&
        blocksData?.map((el, index) => {
          const { id, classNameBox, classNameSubBox, title } = el;

          const number =
            (statsNumberApi &&
              statsNumberApi[id as keyof typeof statsNumberApi]) ||
            0;
          const percentage =
            (featuresPercentages &&
              featuresPercentages[id as keyof typeof featuresPercentages]) ||
            undefined;
          return (
            <div
              key={"block__" + index}
              id={id}
              className={`${styles.metricsBlocks__each} ${
                classNameBox && classNameBox
              }`}
            >
              <div className={styles.metricsBlocks__each_container}>
                <div
                  className={`${styles.metricsBlocks__each_title} ${cx(
                    "heading6"
                  )}`}
                >
                  {t(title)}
                </div>
                <div className={styles.metricsBlocks__each_data}>
                  {statsNumberApi && (
                    <div
                      className={`${styles.each_data__number} ${cx(
                        "heading3"
                      )}`}
                      aria-label={
                        number >= 1000 ? formatNumberToEU(number) : ""
                      }
                      aria-hidden={number >= 1000 ? false : true}
                      tooltip-position="top"
                    >
                      {formatNumberToCmpct(number, formatNumbersDefaultOptions)}
                    </div>
                  )}
                  <PercentagesMetricsBlocks
                    classNameSubBox={classNameSubBox}
                    selectedPeriodDays={selectedFilterPeriod}
                    percentage={percentage}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <CardListSkeleton
          cardsNumber={2}
          className={`${styles.metricsBlocks__each} ${styles.skeleton}`}
        />
      )}
    </div>
  );
};

export default MetricsBlocks;
