import { ofType } from "redux-observable";
import { switchMap } from "rxjs/operators";
import { of } from "rxjs";
import { ScafoldingActionsTypes } from "./scafoldingTypes";
import {history} from '../../../services/history'

export const navigateEpic = (action$: any, state$: any): any => {
  return action$.pipe(

    ofType(ScafoldingActionsTypes.NAVIGATE),
    switchMap((action: any): any => {
      history.push(action.path);
        return of();
      })
  );
};
