import cx from "classnames";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./externalProviderTable.module.scss";
import { clientsSelectors } from "../../../store";
import Description from "../../../../../components/atoms/texts/Description/Description";
import LightPanel from "../../../../../components/elements/panels/LightPanel/LightPanel";
import { CellContentTypes, TextAlignTypes } from "../../../../../data/display";
import { userHasScopes } from "../../../../../utils/permissionsUtil";
import { sessionSelectors } from "../../../../Settings/Profile/store";
import Paginator, {
  PaginatorProps,
} from "../../../../../components/elements/paginator/Paginator";
import { clientsScopes } from "../../../data/clients.constants";
import {
  setSelectedLicenseAction,
  setSelectedOrgAction,
} from "../../../store/clientsActions";
import { IClientModel } from "../../../../../interfaces/interfaces";
import {
  AmountCell,
  DateCell,
  HeaderCell,
  NameCell,
  SubscriptionCell,
  CredStatusActions,
} from "../cells";
import { ExtProviderActionsCell } from "../cells/ExtProviderActionsCell/ExtProviderActionsCell";

export type ExternalProviderTableProps = {
  tableData: any[];
  headerData: {
    key: string;
    text: string;
    textAlign?: TextAlignTypes;
    type?: CellContentTypes;
    tag?: boolean;
    short?: boolean;
    tagText?: string;
    action?: (x?: any) => void;
  }[];
  noResults?: boolean;
  showingModal?: boolean;
  paginationData?: PaginatorProps;
  canDelete?: boolean;
  mobileItem?: ((x: any) => any) | ((x: any) => Element);
  cancelItem?: (x?: any) => void;
  activateItem?: (x?: any) => void;
  editItem?: (x?: any) => void;
  removeType?: (x: any, y?: any) => void;
  addType?: (x: any, y?: any) => void;
};

const ExternalProviderTable: React.FC<ExternalProviderTableProps> = (props) => {
  const {
    tableData,
    headerData,
    paginationData,
    noResults,
    mobileItem,
    activateItem,
    cancelItem,
    editItem,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const orgLoading = useSelector(clientsSelectors.getOrgsStatus);
  const availableScopes = useSelector(sessionSelectors?.getAllowedScopes);

  // States
  const [hoveredClientIndex, setHoveredClientIndex] = useState<
    number | undefined
  >();

  // Variables
  const canEdit = !!(
    availableScopes && userHasScopes(clientsScopes?.edit, availableScopes)
  );
  const canDelete = !!(
    availableScopes && userHasScopes(clientsScopes?.delete, availableScopes)
  );
  const clients: any[] = new Array();

  // Effects
  useEffect(() => {
    clearSelectedOrgAndLicense();
  }, []);
  useEffect(() => {}, [clients, tableData, orgLoading]);

  // Functions
  const clearSelectedOrgAndLicense = () => {
    dispatch(setSelectedOrgAction());
    dispatch(setSelectedLicenseAction());
  };

  return (
    <>
      {!noResults ? (
        <LightPanel className={styles.panelContainer}>
          <div className={cx("width100", styles.table)}>
            <table className="">
              <thead>
                <tr>
                  {headerData.map((header, index) =>
                    !(
                      header.key &&
                      header.key === "actions" &&
                      !(canEdit || canDelete)
                    ) ? (
                      <HeaderCell
                        key={"tableHeader" + header.key + index}
                        header={header}
                        index={index}
                        headerDataLenght={headerData?.length}
                      />
                    ) : null
                  )}
                </tr>
              </thead>
              <tbody>
                {!!tableData
                  ? tableData?.map((item: IClientModel, index) => (
                      <tr
                        data-id={index}
                        key={"clientRow" + index}
                        className={cx(
                          !!mobileItem ? styles.table__longDisplay : "",
                          hoveredClientIndex === index ? styles.hoveredRow : ""
                        )}
                      >
                        {!!item ? (
                          <>
                            <NameCell
                              item={item}
                              index={index}
                              setHoveredClientIndex={setHoveredClientIndex}
                              canEdit={canEdit}
                              editItem={editItem}
                            />
                            <SubscriptionCell item={item} />
                            <CredStatusActions item={item} />
                            <DateCell date={item?.signUpDate} />
                            <DateCell
                              date={item?.nextPaymentDate}
                              className={item?.status}
                            />
                            <AmountCell amount={item?.amount} />

                            <ExtProviderActionsCell
                              item={item}
                              showActivateModal={activateItem}
                              showCancelModal={cancelItem}
                            />
                          </>
                        ) : null}
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
          </div>
        </LightPanel>
      ) : (
        <Description text={t("clients.noResults")} />
      )}

      {!!paginationData && !noResults ? (
        <div>
          <Paginator className={cx("margin-top")} {...paginationData} />
        </div>
      ) : null}
    </>
  );
};

export default ExternalProviderTable;
