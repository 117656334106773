import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { history } from "../../../../services/history";
import { UsersActionTypes } from "./usersTypes";
import dataService from "../../../../services/dataService";
import auth from "../../../../services/auth";

export const checkUsersEpic: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(UsersActionTypes.CHECK_USERS),
    switchMap((action: any): any => {
      return from(dataService.existingUsers()).pipe(
        mergeMap((response?: any) => {
          if (history.location.pathname !== "/login") history.push("login");
          return of();
        }),
        catchError((error) => {
          let token = error?.response?.headers?.token;
          auth.setRegistrationToken(token);
          // history.push("home");
          return of();
        })
      );
    })
  );
};
