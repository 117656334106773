import React from "react";
import {
  filterTiersByProvider,
  getNextPaymentDate,
  getSubscriptionAction,
} from "../../../../../../utils/licensesUtil";
import { setSelectedClientAction } from "../../../../store/clientsActions";
import WarningModal from "../../../../../../components/elements/modals/warninModal/WarningModal";
import {
  IModalButtons,
  ITierModel,
} from "../../../../../../interfaces/interfaces";
import { gaImages } from "../../../../../../data/images";
import { ClientType } from "../../Clients";
import ChangeSubscriptionModal from "../../../../components/modals/changeSubscriptionModal/ChangeSubscriptionModal";
import { useDispatch } from "react-redux";

export type ModalsProps = {
  showSuspendWarning: boolean;
  selectedClient: ClientType;
  suspendModalButtons: IModalButtons;
  selectedOrgId?: string;
  deleteModalButtons: IModalButtons;
  tiers?: ITierModel[];
  changeSubModalButtons: IModalButtons;
  showActivateSubModal: boolean;
  showDeleteModal: boolean;
  setSelectedOrg: (x?: any) => void;
  setShowDeleteModal: (x?: any) => void;
  setShowSuspendModal: (x?: any) => void;
  setShowActivateSubModal: (x?: any) => void;
};

const Modals: React.FC<ModalsProps> = (props) => {
  const {
    showSuspendWarning,
    selectedClient,
    suspendModalButtons,
    selectedOrgId,
    deleteModalButtons,
    tiers,
    changeSubModalButtons,
    showActivateSubModal,
    showDeleteModal,
    setSelectedOrg,
    setShowDeleteModal,
    setShowSuspendModal,
    setShowActivateSubModal,
  } = props;
  const dispatch = useDispatch();

  return (
    <>
      {showSuspendWarning ? (
        <WarningModal
          showBg={true}
          title={"clients.sureSuspendTitle"}
          description={"clients.sureSuspendDesc"}
          descriptionExtraTranslationParams={{
            date: getNextPaymentDate(selectedClient?.nextPaymentDate),
          }}
          primaryButton={suspendModalButtons.primary}
          secondaryButton={suspendModalButtons.secondary}
          headerClose
          hideModal={() => (
            dispatch(setSelectedClientAction(undefined)),
            setShowSuspendModal(false),
            setSelectedOrg(undefined)
          )}
          item={selectedOrgId}
        />
      ) : null}
      {showDeleteModal ? (
        <WarningModal
          title={"clients.sureDeleteTitle"}
          description={"clients.sureDeleteDesc"}
          primaryButton={deleteModalButtons.primary}
          secondaryButton={deleteModalButtons.secondary}
          icon={gaImages?.skullPng}
          headerClose
          item={selectedOrgId}
          hideModal={() => (
            setShowDeleteModal(false), setSelectedOrg(undefined)
          )}
        />
      ) : null}
      {showActivateSubModal ? (
        <ChangeSubscriptionModal
          showBg={true}
          actionType={getSubscriptionAction(selectedClient?.status)}
          secondaryButton={changeSubModalButtons.secondary}
          headerClose
          tiers={filterTiersByProvider(selectedClient?.org?.providerId, tiers)}
          hideModal={() => setShowActivateSubModal(false)}
          item={selectedClient?.org}
          selectedLicense={selectedClient?.plan?.type}
          selectedPeriodChecked={selectedClient?.plan?.period || ""}
        />
      ) : null}
    </>
  );
};

export default Modals;
