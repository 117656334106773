import {
  IChangeSubData,
  IClientModel,
  ILicenseItem,
  ISubscriptionInfo,
  OrganizationModel,
} from "../../../interfaces/interfaces";
import {
  ClientsActionTypes,
  IChangeSubscription,
  IChangeSubscriptionFailed,
  IChangeSubscriptionSuccess,
  IDeleteOrg,
  IDeleteOrgFailed,
  IDeleteOrgSuccess,
  IGetLicenseById,
  IGetLicenseByIdFailed,
  IGetLicenseByIdSuccess,
  IGetLicenses,
  IGetLicensesFailed,
  IGetLicensesSuccess,
  IGetOrganizationById,
  IGetOrganizationByIdFailed,
  IGetOrganizationByIdSuccess,
  IGetOrganizations,
  IGetOrganizationsFailed,
  IGetOrganizationsSuccess,
  IGetSubscriptions,
  IGetSubscriptionsFailed,
  IGetSubscriptionsSuccess,
  IReactivateSubscription,
  IReactivateSubscriptionFailed,
  IReactivateSubscriptionSuccess,
  ISetSelectedClient,
  ISetSelectedLicense,
  ISetSelectedOrg,
  ISuspendSubscription,
  ISuspendSubscriptionFailed,
  ISuspendSubscriptionSuccess,
  IUpdateLicense,
  IUpdateLicenseFailed,
  IUpdateLicenseSuccess,
  IUpdateOrg,
  IUpdateOrgFailed,
  IUpdateOrgSuccess,
  LicensesActionTypes,
  OrganizationsActionTypes,
  SubscriptionsActionTypes,
} from "./clientsTypes";

// ORGANIZATIONS
export const getOrganizationsAction = (): IGetOrganizations => {
  return {
    type: OrganizationsActionTypes.GET_ORGANIZATIONS,
  };
};

export const getOrganizationsSuccessAction = (
  organizations: OrganizationModel[]
): IGetOrganizationsSuccess => {
  return {
    type: OrganizationsActionTypes.GET_ORGANIZATIONS_SUCCESS,
    payload: {
      organizations,
    },
  };
};

export const getOrganizationsFailedAction = (
  err: string
): IGetOrganizationsFailed => {
  return {
    type: OrganizationsActionTypes.GET_ORGANIZATIONS_FAILED,
    error: err,
  };
};

export const getOrganizationByIdAction = (id: string): IGetOrganizationById => {
  return {
    type: OrganizationsActionTypes.GET_ORG_BY_ID,
    payload: { id },
  };
};

export const getOrganizationByIdSuccessAction = (
  org: OrganizationModel
): IGetOrganizationByIdSuccess => {
  return {
    type: OrganizationsActionTypes.GET_ORG_BY_ID_SUCCESS,
    payload: {
      org,
    },
  };
};

export const getOrganizationByIdFailedAction = (
  err: string
): IGetOrganizationByIdFailed => {
  return {
    type: OrganizationsActionTypes.GET_ORG_BY_ID_FAILED,
    error: err,
  };
};

export const deleteOrgAction = (id: string): IDeleteOrg => {
  return {
    type: OrganizationsActionTypes.DELETE_ORG,
    payload: { id },
  };
};

export const deleteOrgSuccessAction = (): IDeleteOrgSuccess => {
  return {
    type: OrganizationsActionTypes.DELETE_ORG_SUCCESS,
  };
};

export const deleteOrgFailedAction = (err: string): IDeleteOrgFailed => {
  return {
    type: OrganizationsActionTypes.DELETE_ORG_FAILED,
    error: err,
  };
};

export const updateOrgAction = (org: OrganizationModel): IUpdateOrg => {
  return {
    type: OrganizationsActionTypes.UPDATE_ORG,
    payload: { org },
  };
};

export const updateOrgSuccessAction = (): IUpdateOrgSuccess => {
  return {
    type: OrganizationsActionTypes.UPDATE_ORG_SUCCESS,
  };
};

export const updateOrgFailedAction = (err: string): IUpdateOrgFailed => {
  return {
    type: OrganizationsActionTypes.UPDATE_ORG_FAILED,
    error: err,
  };
};

export const setSelectedOrgAction = (
  org?: OrganizationModel
): ISetSelectedOrg => {
  return {
    type: OrganizationsActionTypes.SET_SELECTED_ORG,
    payload: { org },
  };
};

// LICENSES
export const getLicensesAction = (): IGetLicenses => {
  return {
    type: LicensesActionTypes.GET_LICENSES,
  };
};

export const getLicensesSuccessAction = (
  licenses: ILicenseItem[]
): IGetLicensesSuccess => {
  return {
    type: LicensesActionTypes.GET_LICENSES_SUCCESS,
    payload: {
      licenses: licenses,
    },
  };
};

export const getLicensesFailedAction = (err: string): IGetLicensesFailed => {
  return {
    type: LicensesActionTypes.GET_LICENSES_FAILED,
    error: err,
  };
};

export const getLicenseByIdAction = (id: string): IGetLicenseById => {
  return {
    type: LicensesActionTypes.GET_LICENSE_BY_ID,
    payload: { id },
  };
};

export const getLicenseByIdSuccessAction = (
  license: ILicenseItem
): IGetLicenseByIdSuccess => {
  return {
    type: LicensesActionTypes.GET_LICENSE_BY_ID_SUCCESS,
    payload: {
      license,
    },
  };
};

export const getLicenseByIdFailedAction = (
  err: string
): IGetLicenseByIdFailed => {
  return {
    type: LicensesActionTypes.GET_LICENSE_BY_ID_FAILED,
    error: err,
  };
};

export const updateLicenseAction = (license: ILicenseItem): IUpdateLicense => {
  return {
    type: LicensesActionTypes.UPDATE_LICENSE,
    payload: { license },
  };
};

export const updateLicenseSuccessAction = (
  license: ILicenseItem
): IUpdateLicenseSuccess => {
  return {
    type: LicensesActionTypes.UPDATE_LICENSE_SUCCESS,
    payload: {
      license,
    },
  };
};

export const updateLicenseFailedAction = (
  err: string
): IUpdateLicenseFailed => {
  return {
    type: LicensesActionTypes.UPDATE_LICENSE_FAILED,
    error: err,
  };
};

export const setSelectedLicenseAction = (
  license?: ILicenseItem
): ISetSelectedLicense => {
  return {
    type: LicensesActionTypes.SET_SELECTED_LICENSE,
    payload: { license },
  };
};

export const getSubscriptionsAction = (): IGetSubscriptions => {
  return {
    type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS,
  };
};

export const getSubscriptionsSuccessAction = (
  subscriptions: ISubscriptionInfo[]
): IGetSubscriptionsSuccess => {
  return {
    type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS,
    payload: {
      subscriptions: subscriptions,
    },
  };
};

export const getSubscriptionsFailedAction = (): IGetSubscriptionsFailed => {
  return {
    type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_FAILED,
  };
};

export const suspendSubscriptionAction = (id: string): ISuspendSubscription => {
  return {
    type: SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION,
    payload: {
      id,
    },
  };
};

export const suspendSubscriptionSuccessAction =
  (): ISuspendSubscriptionSuccess => {
    return {
      type: SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION_SUCCESS,
    };
  };

export const suspendSubscriptionFailedAction =
  (): ISuspendSubscriptionFailed => {
    return {
      type: SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION_FAILED,
    };
  };

export const changeSubscriptionAction = (
  orgId: string,
  data: IChangeSubData
): IChangeSubscription => {
  return {
    type: SubscriptionsActionTypes.CHANGE_SUBSCRIPTION,
    payload: {
      orgId,
      data,
    },
  };
};

export const changeSubscriptionSuccessAction =
  (): IChangeSubscriptionSuccess => {
    return {
      type: SubscriptionsActionTypes.CHANGE_SUBSCRIPTION_SUCCESS,
    };
  };

export const changeSubscriptionFailedAction = (): IChangeSubscriptionFailed => {
  return {
    type: SubscriptionsActionTypes.CHANGE_SUBSCRIPTION_FAILED,
  };
};

export const reactivateSubscriptionAction = (
  id: string
): IReactivateSubscription => {
  return {
    type: SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION,
    payload: {
      id,
    },
  };
};

export const reactivateSubscriptionSuccessAction =
  (): IReactivateSubscriptionSuccess => {
    return {
      type: SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS,
    };
  };

export const reactivateSubscriptionFailedAction =
  (): IReactivateSubscriptionFailed => {
    return {
      type: SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION_FAILED,
    };
  };

// CLIENTS
export const setSelectedClientAction = (
  client?: IClientModel
): ISetSelectedClient => {
  return {
    type: ClientsActionTypes.SET_SELECTED_CLIENT,
    payload: {
      client,
    },
  };
};
