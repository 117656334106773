import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./changeSubscriptionModal.module.scss";
import cx from "classnames";
import {
  ButtonModel,
  ITierModel,
  OrganizationModel,
} from "../../../../../interfaces/interfaces";
import CloseIcon from "../../../../../assets/iconsLibrary/simple/close/closeIcon";
import { ButtonSizes, gaColors } from "../../../../../data/display";
import GreyButton from "../../../../../components/atoms/buttons/grey/GreyButton";
import GaSelect from "../../../../../components/elements/forms/formFields/select/GaSelect";
import {
  SubscriptionActionsEnum,
  changeSubscriptionTypeTexts,
} from "../../../data/clients.constants";
import PurpleButton from "../../../../../components/atoms/buttons/purple/PurpleButton";
import GaDatePicker from "../../../../../components/elements/forms/formFields/datePicker/GaDatePicker";
import moment from "moment";
import { useDispatch } from "react-redux";
import { changeSubscriptionAction } from "../../../store/clientsActions";

type IChangeSubscriptionModalProps = {
  item?: OrganizationModel;
  actionType: SubscriptionActionsEnum;
  secondaryButton: ButtonModel;
  selectedLicense: any;
  selectedPeriodChecked?: string;
  showBg?: boolean;
  tiers?: ITierModel[];
  headerClose?: boolean;
  notActivating?: boolean;
  hideModal: () => void;
};

const ChangeSubscriptionModal: React.FC<IChangeSubscriptionModalProps> = (
  props
) => {
  const {
    item,
    actionType,
    secondaryButton,
    showBg,
    tiers,
    selectedLicense,
    selectedPeriodChecked,
    headerClose,
    notActivating,
    hideModal,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const modalData =
    changeSubscriptionTypeTexts[
      actionType as keyof typeof changeSubscriptionTypeTexts
    ];

  const [modifiyngSub, setModifiyngSub] = useState(
    actionType === "cancel" || actionType === "change"
  );

  const todayDate = new Date();
  let tomorrowDate = new Date(todayDate);
  tomorrowDate.setDate(todayDate.getDate() + 1);

  const getDefaultTier = tiers?.find(
    (product) => product?.name === selectedLicense
  );

  const getFormattedData = () => {
    dispatch(
      changeSubscriptionAction(item?.id || "", {
        priceId: selectedAmount,
        periodStart: startDate + "T00:00:00.00Z",
        periodEnd: endDate + "T00:00:00.00Z",
      })
    );
    hideModal();
  };

  const tierPrice =
    getDefaultTier?.prices?.find(
      (el) => el?.recurringInterval === selectedPeriodChecked
    ) || undefined;

  const [formData, setFormState] = useState({
    selectedTier:
      getDefaultTier?.name || (tiers?.length && tiers[0]?.name) || "",
    selectedAmount: tierPrice ? tierPrice?.id : undefined,
    startDate: moment(todayDate)?.format("YYYY-MM-DD") || "",
    endDate: "",
  });
  const { selectedTier, selectedAmount, startDate, endDate } = formData;

  const validForm =
    selectedTier?.trim()?.length &&
    selectedAmount?.trim()?.length &&
    startDate?.trim()?.length &&
    endDate?.trim()?.length;

  const getAmountOptions = (subscriptionType: string) => {
    const periodOptions = new Array();
    const selectedLicensePrices = tiers?.find(
      (tier) => tier?.name === subscriptionType
    )?.prices;

    selectedLicensePrices?.map(
      (el) =>
        el?.amount &&
        periodOptions?.push({ value: el.id, text: el?.amount / 100 + " €" })
    );

    periodOptions.sort((a, b) => a?.amount - b?.amount);
    return periodOptions;
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormState({
      ...formData,
      [name]: value,
    });
  };

  const handleTierChange = (event: any) => {
    const value = event?.target?.value;
    const amountOptions = getAmountOptions(value);
    const firstAmountOption = amountOptions?.length
      ? amountOptions[0]
      : undefined;
    setFormState({
      ...formData,
      selectedTier: value,
      selectedAmount: firstAmountOption?.value,
    });
  };

  const handlePeriodChange = (event: any) => {
    const value = event?.target?.value;
    setFormState({
      ...formData,
      selectedAmount: value,
      endDate:
        value === "custom" ? moment(todayDate)?.format("YYYY-MM-DD") : "",
    });
  };

  const setModifyingSubToFalse = () => {
    const tierPrice =
      getDefaultTier?.prices?.find(
        (el) => el?.recurringInterval === selectedPeriodChecked
      ) || undefined;

    setFormState({
      ...formData,
      selectedTier:
        getDefaultTier?.name || (tiers?.length && tiers[0]?.name) || "",
      selectedAmount: tierPrice ? tierPrice?.id : undefined,
    });

    setModifiyngSub(false);
  };

  return (
    <>
      <div
        className={`${styles.backgroundLayer} ${
          showBg ? styles.darkBackgroundLayer : ""
        }`}>
        <div className={styles.modal}>
          <div className={styles.modal__header}>
            <div>
              {headerClose ? (
                <div className={styles.close} onClick={() => hideModal()}>
                  <CloseIcon size={24} color={gaColors.neutral1000} />
                </div>
              ) : null}
              <p className={cx("heading5 neutral1000")}>
                {t(modalData?.title)}
              </p>
            </div>
            {modalData?.desc1 ? (
              <p className={cx("bodyBoldMD neutral700 marginTop4")}>
                {t(modalData?.desc1)}
              </p>
            ) : null}
            {modalData?.desc2 ? (
              <p className={cx("bodyRegularMD neutral700")}>
                {t(modalData?.desc2)}
              </p>
            ) : null}
          </div>

          <div className={cx(styles.modal__content)}>
            <div className={styles.doubleColumn}>
              <GaSelect
                value={selectedTier || ""}
                id="plan-selector"
                inline={false}
                invalid={false}
                required
                readOnly={!modifiyngSub}
                invalidText={t("public.required")}
                labelText={"clientDetail.subscriptionTier"}
                placeholder={t("clientDetail.subscriptionTier")}
                name={"selectedTier"}
                optionTextProperty={"name"}
                optionValueProperty={"name"}
                onChange={(e) => handleTierChange(e)}
                options={tiers || []}
              />
              <GaSelect
                value={selectedAmount}
                id="select-selectedAmount"
                inline={false}
                invalid={false}
                required
                readOnly={!modifiyngSub}
                invalidText={t("public.required")}
                labelText={"clientDetail.subscriptionPeriod"}
                placeholder={t("clientDetail.subscriptionPeriod")}
                optionTextProperty={"text"}
                optionValueProperty={"value"}
                name={"selectedAmount"}
                onChange={(e) => {
                  handlePeriodChange(e);
                }}
                options={getAmountOptions(selectedTier)}
              />
            </div>
            <div className={styles.doubleColumn}>
              <GaDatePicker
                value={startDate}
                min={startDate}
                id="select-startDate"
                required
                labelText={"clientDetail.subscriptionStartDate"}
                name={"startDate"}
              />
              <GaDatePicker
                value={endDate}
                id="select-endDate"
                min={
                  moment(tomorrowDate)?.format("YYYY-MM-DD") || startDate || ""
                }
                required
                labelText={"clientDetail.subscriptionExpirationDate"}
                name={"endDate"}
                onChange={(e) => {
                  handleInputChange(e);
                }}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            {modifiyngSub ? (
              <GreyButton
                outlined
                size={ButtonSizes.MD}
                label={t(secondaryButton?.label)}
                disabled={false}
                action={() =>
                  !notActivating ? setModifyingSubToFalse() : hideModal()
                }
              />
            ) : (
              <PurpleButton
                outlined
                size={ButtonSizes.MD}
                label={t("clients.modifySubscription")}
                disabled={false}
                action={() => setModifiyngSub(true)}
              />
            )}
            <PurpleButton
              size={ButtonSizes.MD}
              className={styles.modal__delete}
              label={t(modalData?.primaryButtonLabel)}
              disabled={!validForm}
              action={(e: any) => getFormattedData()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeSubscriptionModal;
