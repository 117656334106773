import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../tierDetail.module.scss";
import LightPanel from "../../../../../../components/elements/panels/LightPanel/LightPanel";
import cx from "classnames";
import GaTextInput from "../../../../../../components/elements/forms/formFields/textInput/GaTextInput";
import { gaColors } from "../../../../../../data/display";
import InfoIcon from "../../../../../../assets/iconsLibrary/simple/info/infoIcon";
import GaSelect from "../../../../../../components/elements/forms/formFields/select/GaSelect";
import { tierOrderOptions } from "../../../../data/platformConfig.constants";

type IGeneralTierFormProps = {
  formData: {
    name: string;
    description: string;
    isPopular: boolean;
    monthlyHasTrialDays: boolean;
    yearlyHasTrialDays: boolean;
    priceMonthly: string;
    monthlyNumTrialDays: string;
    yearlyNumTrialDays: string;
    ebsiDids: boolean;
    priceYearly: string;
    index: string;
    yearlyDiscountPercentage: number;
  };
  setFormState: (x?: any) => void;
};

const GeneralTierForm: React.FC<IGeneralTierFormProps> = (props) => {
  const { formData, setFormState } = props;
  const { t } = useTranslation();

  const {
    name,
    description,
    priceMonthly,
    priceYearly,
    ebsiDids,
    monthlyHasTrialDays,
    yearlyHasTrialDays,
    monthlyNumTrialDays,
    yearlyNumTrialDays,
    isPopular,
    index,
    yearlyDiscountPercentage,
  } = formData;

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    if (
      name === "priceMonthly" ||
      name === "priceYearly" ||
      name === "monthlyNumTrialDays" ||
      name === "yearlyNumTrialDays"
    ) {
      value = value?.replace(/[^\d]/g, "");
      value = value?.replace(/^0+/, "");
    }
    setFormState({
      ...formData,
      [name]: value,
    });
  };

  const handlePopularTagChange = () => {
    setFormState({ ...formData, isPopular: !isPopular });
  };

  const handleMonthlyHasTrial = () => {
    const hasTrialDays = !!monthlyHasTrialDays;
    setFormState({
      ...formData,
      monthlyHasTrialDays: !monthlyHasTrialDays,
      monthlyNumTrialDays: hasTrialDays ? "0" : monthlyNumTrialDays,
    });
  };

  const handleYearlyHasTrial = () => {
    const hasTrialDays = !!yearlyHasTrialDays;
    setFormState({
      ...formData,
      yearlyHasTrialDays: !yearlyHasTrialDays,
      yearlyNumTrialDays: hasTrialDays ? "0" : yearlyNumTrialDays,
    });
  };

  return (
    <LightPanel>
      <div className={styles.tierDetail__header}>
        <div>
          <p className={cx("heading5 neutral1000")}>
            {" "}
            {t("platformConfig.tierDetail.generalTitle")}
          </p>
          <p className={cx("bodyRegularMD neutral700")}>
            {" "}
            {t("platformConfig.tierDetail.generalDescription")}
          </p>
        </div>
      </div>
      <form>
        <GaTextInput
          name="name"
          invalid={false}
          labelText={"platformConfig.tierDetail.name"}
          onChange={(e) => handleInputChange(e)}
          placeholder={t("platformConfig.tierDetail.namePlh")}
          type="text"
          required
          value={name}
        />

        <GaTextInput
          name="description"
          invalid={false}
          maxLength={120}
          labelText={"platformConfig.tierDetail.desc"}
          onChange={(e) => handleInputChange(e)}
          placeholder={t("platformConfig.tierDetail.descPlh")}
          value={description}
        />

        <div className={`${styles.popularTagContainer}`}>
          <div className={`${styles.popularTag}`}>
            <p className={cx("bodyRegularSM")}>
              {t("platformConfig.tierDetail.addPopularTag")}
            </p>

            <span
              className={cx("bodyRegularSM", styles.tagToogle)}
              aria-label={t("platformConfig.tierDetail.popularTagInfo")}
              tooltip-position="top">
              <InfoIcon size={16} color={gaColors.neutral1000} />
            </span>
          </div>
          <div className={styles.activationToggle}>
            <input
              checked={isPopular}
              className={styles.activationCheckbox}
              type="checkbox"
              id="switch"
            />
            <label
              onClick={(e) => handlePopularTagChange()}
              className={styles.activationCheckboxLabel}></label>
          </div>
        </div>

        <div className={styles.doublePriceColumn}>
          <p className={cx("bodyRegularSM")}>
            {t("platformConfig.tierDetail.subsPricesConf")}
          </p>
          <p className={cx("bodyRegularXS marginTop8")}>
            {t("clientDetail.monthly")}
          </p>
          <div>
            <GaTextInput
              name="priceMonthly"
              className={styles.priceInput}
              invalid={false}
              labelText={"platformConfig.tierDetail.priceMonthly"}
              onChange={(e) => {
                handleInputChange(e);
              }}
              placeholder={t("platformConfig.tierDetail.priceMonthlyPlh")}
              type="text"
              required
              value={priceMonthly}
            />
            <div className={`${cx("marginTop12", styles.popularTagContainer)}`}>
              <div className={`${styles.popularTag}`}>
                <p className={cx("bodyRegularSM")}>
                  {t("platformConfig.tierDetail.trialDays")}
                </p>

                <span
                  className={cx(
                    "bodyRegularSM",
                    styles.tagToogle,
                    styles.tagTogleShort
                  )}
                  aria-label={t("platformConfig.tierDetail.trialDays")}
                  tooltip-position="top">
                  <InfoIcon size={16} color={gaColors.neutral1000} />
                </span>
              </div>
              <div className={styles.activationToggle}>
                <input
                  checked={monthlyHasTrialDays}
                  className={styles.activationCheckbox}
                  type="checkbox"
                  id="switch"
                />
                <label
                  onClick={(e) => handleMonthlyHasTrial()}
                  className={styles.activationCheckboxLabel}></label>
              </div>
            </div>
            {monthlyHasTrialDays ? (
              <GaTextInput
                className={cx("marginTop8", styles.trialDaysInput)}
                name="monthlyNumTrialDays"
                invalid={false}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                type="text"
                required
                value={monthlyNumTrialDays}
              />
            ) : null}
          </div>
          <p className={cx("bodyRegularXS marginTop8")}>
            {t("clientDetail.yearly")}
          </p>
          <div>
            <GaTextInput
              className={styles.priceInput}
              name="priceYearly"
              invalid={false}
              labelText={"platformConfig.tierDetail.priceYearly"}
              onChange={(e) => {
                handleInputChange(e);
              }}
              placeholder={t("platformConfig.tierDetail.priceYearlyPlh")}
              type="text"
              required
              value={priceYearly}
            />
            <div className={`${cx("marginTop12", styles.popularTagContainer)}`}>
              <div className={`${styles.popularTag}`}>
                <p className={cx("bodyRegularSM")}>
                  {t("platformConfig.tierDetail.trialDays")}
                </p>

                <span
                  className={cx(
                    "bodyRegularSM",
                    styles.tagToogle,
                    styles.tagTogleShort
                  )}
                  aria-label={t("platformConfig.tierDetail.trialDays")}
                  tooltip-position="top">
                  <InfoIcon size={16} color={gaColors.neutral1000} />
                </span>
              </div>
              <div className={styles.activationToggle}>
                <input
                  checked={yearlyHasTrialDays}
                  className={styles.activationCheckbox}
                  type="checkbox"
                  id="switch"
                />
                <label
                  onClick={(e) => handleYearlyHasTrial()}
                  className={styles.activationCheckboxLabel}></label>
              </div>
            </div>

            {yearlyHasTrialDays ? (
              <GaTextInput
                className={cx("marginTop8", styles.trialDaysInput)}
                name="yearlyNumTrialDays"
                invalid={false}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                type="text"
                required
                value={yearlyNumTrialDays}
              />
            ) : null}
          </div>
        </div>

        <div>
          <div className={`${cx(styles.popularTagContainer)}`}>
            <div className={`${styles.popularTag}`}>
              <p className={cx("bodyRegularSM")}>
                {t("platformConfig.tierDetail.subscriptionTierOrder")}
              </p>

              <span
                className={cx("bodyRegularSM", styles.tagToogle)}
                aria-label={t(
                  "platformConfig.tierDetail.subscriptionTierOrderInfo"
                )}
                tooltip-position="top">
                <InfoIcon size={16} color={gaColors.neutral1000} />
              </span>
            </div>
          </div>
          <GaSelect
            value={index}
            name="index"
            inline={false}
            invalid={false}
            optionValueProperty={"value"}
            optionTextProperty={"text"}
            onChange={(e) => {
              handleInputChange(e);
            }}
            options={tierOrderOptions}
          />
        </div>
      </form>
    </LightPanel>
  );
};

export default GeneralTierForm;
