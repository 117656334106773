import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import cx from "classnames";
import styles from "./dropdownMenu.module.scss";
import GaImage from "../../../atoms/Image/Image";
import { ImageModel, LinkModel } from "../../../../interfaces/interfaces";
import { setSelectedRoute } from "../../../../features/PanelScafolding/store/scafoldingActions";
import { gaImages } from "../../../../data/images";
import { gaColors } from "../../../../data/display";
import { history } from "../../../../services/history";

type IDropdownMenuProps = {
  label?: string;
  selected?: boolean;
  icon?: ImageModel;
  iconClassName?: string;
  iconComponent?: any;
  className?: string;
  menuOptions: LinkModel[];
};

const DropdownMenu: React.FC<IDropdownMenuProps> = (props) => {
  const {
    label,
    icon,
    iconClassName,
    iconComponent,
    className,
    menuOptions,
    selected,
  } = props;

  // Hooks
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // States
  const [active, setIsActive] = useState(false);

  // Functions
  const showOption = (optionID?: string) =>
    optionID !== "tenants" && (optionID === "general" || optionID === "logout");

  function navigateTo(url: string) {
    setActiveRoute(url);
    history?.push(url);
  }

  const setActiveRoute = (route: string) => {
    dispatch(setSelectedRoute(route));
  };

  // View
  return (
    <>
      {label ? (
        <>
          {t(label)}
          <button
            className={styles.dropdownButton + " " + className}
            onClick={() => setIsActive(!active)}
          >
            <GaImage
              className={
                iconClassName
                  ? iconClassName
                  : active
                  ? styles.dropdownButton__iconActive
                  : styles.dropdownButton__icon
              }
              image={icon || gaImages.arrowForward}
            />
          </button>
        </>
      ) : (
        <button
          className={styles.dropdownButton + " " + className}
          onClick={() => setIsActive(!active)}
        >
          {iconComponent ? (
            <props.iconComponent
              size="40"
              type="filled"
              color={!active ? gaColors?.primary700 : "#1B1A5F"}
            />
          ) : (
            <GaImage
              className={
                iconClassName
                  ? iconClassName
                  : active
                  ? styles.dropdownButton__iconActive
                  : styles.dropdownButton__icon
              }
              image={icon || gaImages.angleDown}
            />
          )}
        </button>
      )}
      <div
        className={
          (active ? styles.active : styles.inActive) + " " + styles.menuOptions
        }
      >
        <ul className={styles.menuOptions__list}>
          {menuOptions?.map((e, index) => {
            return e.subRoutes ? (
              <DropdownMenu
                key={"dropdownOption__" + index}
                menuOptions={e.subRoutes}
                label={e.label}
              ></DropdownMenu>
            ) : e.id !== "support" && e.id !== "documentation" ? (
              showOption(e.id) ? (
                <li
                  key={e.route + e.id + index}
                  className={styles.menuOptions__item}
                  onClick={() => {
                    if (e.action) {
                      dispatch(e.action(e.id));
                    }
                    if (e.route !== "no") {
                      e.id === "general"
                        ? navigateTo("/settings")
                        : navigateTo(e.route);
                    }
                    setIsActive(!active);
                  }}
                >
                  {e.icon ? (
                    <GaImage
                      className={styles.menuOptions__icon}
                      image={e.icon}
                    />
                  ) : null}
                  <span
                    className={`${selected ? "font-weight-bold" : ""}
                                            ${cx("buttonSM neutral1000")}`}
                  >
                    {t(e.label)}
                  </span>
                </li>
              ) : null
            ) : e?.id === "documentation" ? (
              <a
                key={"dropdownLink__" + index}
                className={styles.menuOptions__item}
                href={e.outLink}
                target="_blank"
              >
                <li key={e.route + e.id + index}>
                  {e.icon ? (
                    <GaImage
                      className={styles.menuOptions__icon}
                      image={e.icon}
                    />
                  ) : null}
                  <span
                    className={`${selected ? "font-weight-bold" : ""}
                                            ${cx("buttonSM neutral1000")}`}
                  >
                    {t(e.label)}
                  </span>
                </li>
              </a>
            ) : null;
          })}
        </ul>
      </div>
      <div
        className={
          active ? styles.backgroundLayerActive : styles.backgroundLayerInactive
        }
        onClick={() => setIsActive(!active)}
      ></div>
    </>
  );
};

export default DropdownMenu;
