import React from 'react';
import CardListSkeleton from '../cardListSkeleton/CardListSkeleton';
import style from './tableSkeleton.module.scss';

type TableSkeletonProps = {
  columnsNumber: number
  rowsNumber: number,
  responsiveCards?: boolean,
  className?: string
}

const TableSkeleton: React.FC<TableSkeletonProps> = (props) => {
  const { columnsNumber, responsiveCards, rowsNumber, className } = props

  const rowRepeat = rowsNumber - 1
  const rows = Array(rowRepeat)
  const cards = Array.from(Array(rows), (_,x) => x);

  const columnsArray = Array.from(
    { length: columnsNumber },
    (_, index) => index
  )
  for (let i = 0; i < rowRepeat; i++) {
    rows[i] = (
      <tr key={i}>
        {columnsArray.map((j, index) => (
          <td key={index + Math.random()}>
            <span className={`${style.skeleton}`} />
          </td>
        ))}
      </tr>
    )
  }

  return (
    <>
      {responsiveCards ? <CardListSkeleton cardsNumber={rowRepeat} className={`${style.cardSkeleton}`} /> : null}
      <div className={`${style.tableSkeleton} ${className || ''}`}>
        <table>
          <thead>
            <tr>
              {columnsArray.map((i, index) => (
                <th key={index + Math.random()}> </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {columnsArray.map((i, index) => (
                <td key={index + Math.random()}>
                  <span className={`${style.skeleton}`} />
                </td>
              ))}
            </tr>
            {rows}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TableSkeleton
