import cx from "classnames";
import React, { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import packageJson from "../../../../../package.json";
import GaImage from "../../../../components/atoms/Image/Image";
import Notification from "../../../Notification/Notification";
import { LinkModel } from "../../../../interfaces/interfaces";
import Loader from "../../../Loader/components/screenLoader/ScreenLoader";

// @ts-ignore
import panelStyles from "./PanelScafoldingPublic.module.scss";
import { gaImages } from "../../../../data/images";
import { loginLink } from "../../../Login/loginConstants";

type IPanelSProps = {
  link?: LinkModel;
  columnDisplay?: boolean;
  children: any;
};

const PanelScafoldingPublic: React.FC<IPanelSProps> = (props) => {
  const { link, columnDisplay, children } = props;
  const { t } = useTranslation();

  return (
    <Suspense fallback={<Loader />}>
      <>
        <header aria-label="Studio" className={panelStyles.topbar}>
          <Link className={panelStyles.logoContainer} to="/login">
            <GaImage image={gaImages.logoPlatform} />
          </Link>
        </header>

        <section className={panelStyles.panelContainer}>
          <Notification className={panelStyles.extraPanel} />
          <div
            className={`${panelStyles.panelContainerChildren} ${
              columnDisplay ? panelStyles.columnDisplay : ""
            }`}
          >
            {children}
          </div>
        </section>
        <footer className={panelStyles.footer}>
          <ul>
            <a href="https://gataca.io/privacy-policy" target="_blank">
              <li className={cx("ButtonMD neutral700")}>
                {t("general.privacy")}
              </li>
            </a>
            <a href="https://gataca.io/terms-of-service-studio" target="_blank">
              <li className={cx("ButtonMD neutral700")}>
                {t("general.termsAndConditions")}
              </li>
            </a>
            <a
              href="https://gataca.atlassian.net/wiki/spaces/DOCS"
              target="_blank"
            >
              <li className={cx("ButtonMD neutral700")}>
                {t("general.documentation")}
              </li>
            </a>

            <p
              className={`${cx("ButtonMD neutral700")} ${panelStyles.version}`}
            >
              <Link
                className={`${cx("ButtonMD neutral700")} ${
                  panelStyles.spaceLinks
                }`}
                to={link?.route || loginLink.route}
              >
                {t(link?.label || loginLink.label)}
              </Link>
              {packageJson?.version && "v " + packageJson?.version}
            </p>
          </ul>
        </footer>
      </>
    </Suspense>
  );
};

export default PanelScafoldingPublic;
