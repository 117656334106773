import HomeIcon from "../../../../assets/iconsLibrary/home/homeIcon";
import SettingsIcon from "../../../../assets/iconsLibrary/settings/settingsIcon";
import UsersIcon from "../../../../assets/iconsLibrary/simple/users/usersIcon";
import activity from "../../../../assets/images/activity.svg";
import apiKeys from "../../../../assets/images/api-keys.svg";
import checkList from "../../../../assets/images/checklist-pen.svg";
import support from "../../../../assets/images/headphones-customer-support-question.svg";
import houseChimney from "../../../../assets/images/house-chimney.svg";
import logout from "../../../../assets/images/logout.svg";
import {
  default as burguerMenu,
  default as security,
} from "../../../../assets/images/security.svg";
import settings from "../../../../assets/images/settings.svg";
import { LinkModel } from "../../../../interfaces/interfaces";
import { deleteSession } from "../../../Settings/Profile/store/sessionActions";
import { setSettings } from "../../store/scafoldingActions";

export const houseChimneyIcon = houseChimney;
export const supportIcon = support;
export const checkListIcon = checkList;
export const burguerMenuIcon = burguerMenu;
export const settingsIcon = settings;
export const logoutIcon = logout;
export const activityIcon = activity;
export const apiKeysIcon = apiKeys;
export const securityIcon = security;
export const documentationLink =
  "https://gataca.atlassian.net/wiki/spaces/DOCS";

export const sideMenuSettings = [
  {
    id: "profile",
    route: "/settings",
    label: "navigation.settingsnav.profile",
  },
];

export const sideMenu: LinkModel[] = [
  {
    route: "/home",
    label: "navigation.home",
    iconComponent: HomeIcon,
  },
  {
    route: "/clients",
    label: "navigation.clients",
    iconComponent: UsersIcon,
  },
  {
    route: "/platform-configuration",
    label: "navigation.platformConfig",
    iconComponent: SettingsIcon,
  },
];

export const sideMenuExtraHelp: LinkModel[] = [
  // {
  //     id: 'faq',
  //     route: '/faq',
  //     label: 'navigation.help.faq',
  //     status: 'disabled',
  //     // icon: {
  //     //   src: checkListIcon,
  //     //   alt: 'images.checkListIcon'
  //     // }
  // },
  // TODO: Uncomment when Settings is ready

  // {
  //   route: '/settings',
  //   label: 'navigation.settings',
  //   icon: {
  //     src: settingsIcon,
  //     alt: 'images.settingsIcon'
  //   }
  // },
  // {
  //     id: 'documentation',
  //     route: '/documentation',
  //     status: 'disabled',
  //     label: 'navigation.help.documentation',
  //     // icon: {
  //     //   src: logoutIcon,
  //     //   alt: 'images.logoutIcon'
  //     // }
  // },
  {
    id: "support",
    route: "",
    label: "navigation.help.support",
    status: "disabled",
    outLink: process.env.REACT_APP_SUPPORT_URL,

    // icon: {
    //   src: supportIcon,
    //   alt: 'images.supportIcon',
    // },
  },
];

export const sideMenuExtraMobileOptions: LinkModel[] = [
  {
    id: "generalSettings",
    route: "generalSettings",
    label: "navigation.settings",
    action: setSettings,
    icon: {
      src: settingsIcon,
      alt: "images.settingsIcon",
    },
    subRoutes: [
      {
        id: "profile",
        route: "settings",
        label: "navigation.settingsnav.profile",
      },
      {
        id: "support",
        route: "",
        label: "navigation.help.support",
        status: "disabled",
        outLink: process.env.REACT_APP_SUPPORT_URL,
      },
    ],
  },
  {
    id: "logout",
    route: "no",
    action: deleteSession,
    label: "navigation.logout",
  },
];

export const sideMenuExtraMobile = (): LinkModel[] => {
  let menu = [
    // {
    //     id: 'profile',
    //     route: 'settings',
    //     label: 'navigation.profile',
    //     action: setSettings,
    //     status: 'disabled',
    // },
    {
      id: "general",
      route: "settings",
      label: "navigation.settings",
      action: setSettings,
    },
    {
      id: "support",
      route: "",
      label: "navigation.help.support",
      status: "disabled",
      outLink: process.env.REACT_APP_SUPPORT_URL,
    },
    {
      id: "documentation",
      route: "no",
      label: "navigation.documentation",
      //icon: {
      //    src: documentationIcon,
      //    alt: 'images.documentationIcon',
      //},
      outLink: documentationLink,
    },
    {
      id: "logout",
      route: "no",
      action: deleteSession,
      label: "navigation.logout",
    },
  ];

  return menu;
};
