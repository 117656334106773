import React from "react";
import { useTranslation } from "react-i18next";
import { ImageModel } from "../../../interfaces/interfaces";
import style from "./tag.module.scss";
import GaImage from "../Image/Image";
import cx from "classnames";

type GaTagProps = {
  text: string;
  color: string;
  className?: string;
  icon?: ImageModel;
  iconComponent?: any;
  item?: any;
  functionality?: (x?: any, y?: any) => void;
  iconFunctionality?: (x: any, y?: any) => void;
};

const GaTag: React.FC<GaTagProps> = React.memo((props) => {
  const {
    text,
    color,
    className,
    icon,
    iconComponent,
    item,
    functionality,
    iconFunctionality,
  } = props;
  const { t } = useTranslation();

  const setColorClass = () => {
    let colorClass = "green";
    switch (color) {
      case "white":
        colorClass = style.white;
        break;
      case "purple":
        colorClass = style.purple;
        break;
      case "red":
        colorClass = style.red;
        break;
      case "green":
        colorClass = style.green;
        break;
      case "yellow":
        colorClass = style.yellow;
        break;
      case "blue":
        colorClass = style.blue;
        break;
      case "teal":
        colorClass = style.teal;
        break;
      case "pink":
        colorClass = style.pink;
        break;
      case "grey":
        colorClass = style.grey;
        break;
      default:
        colorClass = style.green;
    }

    return colorClass;
  };
  return (
    <div
      className={`${style.tag} ${className} ${setColorClass()} ${cx(
        "buttonSM",
        functionality && style.interactive
      )}`}
      onClick={() => {
        functionality ? functionality(item, text) : "";
      }}
    >
      {iconComponent ? <props.iconComponent color="white" size={16} /> : null}{" "}
      {t(text)}{" "}
      {icon ? (
        <div
          onClick={() => {
            iconFunctionality ? iconFunctionality(item, text) : null;
          }}
        >
          <GaImage image={icon} />
        </div>
      ) : null}
    </div>
  );
});

export default GaTag;
