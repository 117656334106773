import { Pagination, PaginationPageSize } from "carbon-components-react";
import { t } from "i18next";
import React, { useEffect } from "react";
import styles from "./paginator.module.scss";

export type PaginatorProps = {
  page: number;
  pageSize?: number;
  pageSizes?: readonly number[] | readonly PaginationPageSize[];
  totalItems: number;
  className?: string;
  backwardText?: string;
  forwardText?: string;
  itemsPerPageText?: string;
  pageNumberText?: string;
  onHandleChange: (e?: any) => void;
};

const Paginator: React.FC<PaginatorProps> = React.memo((props) => {
  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    className,
    backwardText,
    forwardText,
    itemsPerPageText,
    pageNumberText,
    onHandleChange,
  } = props;

  useEffect(() => {}, [totalItems, pageSize]);

  return (
    <Pagination
      className={`${styles.paginator} ${className}`}
      backwardText={""}
      forwardText={""}
      itemsPerPageText={itemsPerPageText || t("general.itemsPerPage")}
      page={page}
      itemRangeText={(min, max, total) =>
        total > 1
          ? t("general.itemsRangeText", { min: min, max: max, total: total })
          : t("general.itemRangeText", { min: min, max: max, total: total })
      }
      pageRangeText={(page, total) =>
        total > 1
          ? t("general.pagesRangeText", { total: total, current: page })
          : t("general.pageRangeText", { total: total, current: page })
      }
      pageNumberText={t("general.pageNumber")}
      pageSize={pageSize || 10}
      pageSizes={pageSizes || [10, 20, 30, 40, 50]}
      totalItems={totalItems}
      onChange={(e) => onHandleChange(e)}
    />
  );
});

export default Paginator;
