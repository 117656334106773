import React from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import styles from "./featuresFilter.module.scss";
import GaSelect from "../../../../../../../../../components/elements/forms/formFields/select/GaSelect";
import { initialLang } from "../../../../../../../../../i18n";
import { IGeneralSelectOption } from "../../../../../../../../../interfaces/interfaces";
import { featureOptions } from "../../../data/metrics.constants";

export type IFeaturesFilterProps = {
  options: IGeneralSelectOption[];
  selectedFeature: string;
  startDate: string;
  endDate: string;
  selectFeature: (x: any) => void;
};

const FeaturesFilter: React.FC<IFeaturesFilterProps> = (props) => {
  const { options, selectedFeature, startDate, endDate, selectFeature } = props;

  return (
    <div className={`${styles.featuresFilter}`}>
      <GaSelect
        value={selectedFeature}
        name="feature"
        inline={false}
        invalid={false}
        isLoading={!featureOptions?.length}
        optionValueProperty={"value"}
        optionTextProperty={"text"}
        onChange={(e) => {
          selectFeature(e?.target?.value);
        }}
        options={options}
      />
      {startDate && endDate ? (
        <div className={styles.featuresFilter__dates}>
          <span className={cx("bodyRegularSM neutral700")}>
            {startDate} - {endDate}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default FeaturesFilter;
