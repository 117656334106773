import { MetricStatistic, Statistics } from "../../../interfaces/interfaces";
import {
  IGetClientsStatistics,
  IGetClientsStatisticsFailed,
  IGetClientsStatisticsSuccess,
  IGetStatistics,
  IGetStatisticsFailed,
  IGetStatisticsSuccess,
  ISetSelectedPeriodYear,
  StatisticsTypes,
} from "./homeTypes";

export const getStatistics = (urlParams: string): IGetStatistics => {
  return {
    type: StatisticsTypes.GET_ALL_STATISTICS,
    payload: { params: urlParams },
  };
};

export const getStatisticsSuccess = (
  stats: Statistics
): IGetStatisticsSuccess => {
  return {
    type: StatisticsTypes.GET_ALL_STATISTICS_SUCCESS,
    payload: {
      statistics: stats,
    },
  };
};

export const getStatisticsFailed = (error: any): IGetStatisticsFailed => {
  return {
    type: StatisticsTypes.GET_ALL_STATISTICS_FAILED,
    payload: {
      error: error,
    },
  };
};

export const getClientStatistics = (
  urlParams: string
): IGetClientsStatistics => {
  return {
    type: StatisticsTypes.GET_CLIENTS_STATISTICS,
    payload: { params: urlParams },
  };
};

export const getClientStatisticsSuccess = (
  stats: MetricStatistic
): IGetClientsStatisticsSuccess => {
  return {
    type: StatisticsTypes.GET_CLIENTS_STATISTICS_SUCCESS,
    payload: {
      statistics: stats,
    },
  };
};

export const getClientStatisticsFailed = (
  error: any
): IGetClientsStatisticsFailed => {
  return {
    type: StatisticsTypes.GET_CLIENTS_STATISTICS_FAILED,
    payload: {
      error: error,
    },
  };
};

export const setSelectedPeriodIsYear = (
  isYear: boolean
): ISetSelectedPeriodYear => {
  return {
    type: StatisticsTypes.SET_SELECTED_PERIOD_YEAR,
    payload: {
      isYear,
    },
  };
};
