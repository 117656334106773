import { ofType } from "redux-observable";
import { from, merge, of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import { history } from "../../../../../services/history";
import { loginDone, loginFailed } from "./loginAction";
import { LoginActionTypes } from "./loginTypes";
import dataService from "../../../../../services/dataService";
import auth from "../../../../../services/auth";
import { showNotification } from "../../../../Notification/store/notificationActions";
import {
  loadSessionData,
  readSession,
} from "../../../../Settings/Profile/store/sessionActions";
import { checkUsers } from "../../../../Settings/Users/store/usersActions";
import {
  updateOrgAction,
  updateOrgFailedAction,
} from "../../../../Clients/store/clientsActions";
import { hideScreenLoaderAction } from "../../../../Loader/loaderActions";

export const submitLoginEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.SUBMIT_LOGIN),
    switchMap((action: any): any => {
      return dataService.authToken(action.token).pipe(
        map((res: any) => {}),
        catchError((error) =>
          of(
            loginFailed(error),
            showNotification("login.errorSignIn", "error", "")
          )
        )
      );
    })
  );
};

export const submitEmailloginEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.SUBMIT_EMAIL_LOGIN),
    switchMap((action: any): any => {
      const user = action.payload.user;
      return from(dataService.emailLogIn(user)).pipe(
        map((response: any) => {
          return readSession();
        }),
        catchError((error) => {
          return of(
            checkUsers(),
            showNotification(
              "login.errorSignIn",
              "error",
              error?.response?.data?.message
            )
          );
        })
      );
    })
  );
};

export const switchTenantEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.SWITCH_TENANT),
    switchMap((action: any): any => {
      const tenant = action.payload.tenant;
      return from(dataService.switchTenant(tenant)).pipe(
        mergeMap((response: any) => {
          auth.deleteCurrentTenant();
          auth.setCurrentTenant(tenant);
          return merge(of(readSession()), of(loadSessionData()));
        }),
        catchError((error) => {
          return of(
            showNotification(
              "login.switchTenantError",
              "error",
              "",
              error?.status
            ),
            checkUsers()
          );
        })
      );
    })
  );
};

export const switchTenantToChangeOrgEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.SWITCH_TENANT_TO_CHANGE_ORG),
    switchMap((action: any): any => {
      const tenant = action.payload.tenant;
      const org = action?.payload?.org;
      return from(dataService.switchTenant(tenant)).pipe(
        mergeMap((response: any) => {
          auth.deleteCurrentTenant();
          auth.setCurrentTenant(tenant);
          return merge(of(updateOrgAction(org)));
        }),
        catchError((error) => {
          return of(
            updateOrgFailedAction(error),
            hideScreenLoaderAction(),
            showNotification(
              "clientDetail.updateOrgError",
              "error",
              error.response?.message,
              error?.status
            )
          );
        })
      );
    })
  );
};

export const checkLoginEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.CHECK_LOGIN),
    switchMap((action: any): any => {
      return (
        map((response: any) => {
          return readSession();
        }),
        catchError((error) => {
          return of();
        })
      );
    })
  );
};

export const loginSuccessEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.LOGIN_SUCCESS),
    switchMap((action: any): any => {
      return of(readSession(), loginDone());
    })
  );
};

export const signupSuccessEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(LoginActionTypes.QR_SIGNUP_SUCCESS),
    switchMap((action: any): any => {
      history.push("home");
      return of(readSession());
    })
  );
};

export const loginOutEpic: any = (action$: any) => {
  return action$.pipe(
    ofType(LoginActionTypes.LOGOUT),
    switchMap((action: any): any => {
      sessionStorage.clear();
      return of(loginDone());
    })
  );
};
