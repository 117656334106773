import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./infoPanel.module.scss";
import { ImageModel } from "../../../../interfaces/interfaces";
import InfoIcon from "../../../../assets/iconsLibrary/simple/info/infoIcon";
import { gaColors } from "../../../../data/display";
import cx from "classnames";

type IInfoPanelProps = {
  label: React.ReactNode | string;
  hasIcon?: boolean;
  iconComponent?: React.ReactNode;
  className?: string;
};

const InfoPanel: React.FC<IInfoPanelProps> = (props) => {
  const { label, hasIcon, className, iconComponent } = props;
  const { t } = useTranslation();

  return (
    <div className={cx(styles.infoPanel, className && className)}>
      {hasIcon ? (
        iconComponent ? (
          iconComponent
        ) : (
          <InfoIcon size={24} color={gaColors?.info300} />
        )
      ) : null}
      <p className={cx("bodyRegularSM")}>{label}</p>
    </div>
  );
};

export default InfoPanel;
