import React from "react";
import cx from "classnames";
import { IClientModel } from "../../../../../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { getValueForURL } from "../../../../../../utils/formatDataUtil";
import styles from "./nameCell.module.scss";

type NameCellProps = {
  item: IClientModel;
  index: number;
  canEdit?: boolean;
  editItem?: (e?: any) => void;
  setHoveredClientIndex: (e?: any) => void;
};

export const NameCell: React.FC<NameCellProps> = (props) => {
  const { item, index, canEdit, editItem, setHoveredClientIndex } = props;

  return (
    <td
      onMouseEnter={() => setHoveredClientIndex(index)}
      onMouseLeave={() => setHoveredClientIndex(undefined)}
      onClick={() => (canEdit && editItem ? editItem(item) : undefined)}
    >
      <Link
        className={cx("buttonMD neutral1000", styles.nameLink)}
        to={getValueForURL(item?.name || "detail")}
      >
        {item.name}
      </Link>
    </td>
  );
};
