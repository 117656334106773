import { PasswordInput, TextInput } from "carbon-components-react";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import style from "./gaTextInput.module.scss";
import InputSkeleton from "../../../skeletons/inlineSkeleton/InputSkeleton";
import CopyIcon from "../../../../../assets/iconsLibrary/simple/copy/copyIcon";
import cx from "classnames";

type GaTextInputProps = {
  id?: string;
  labelText?: string;
  placeholder?: string;
  helperText?: string;
  value?: string;
  readOnly?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  invalidText?: string;
  invalid?: boolean;
  name?: string;
  required?: boolean;
  light?: boolean;
  className?: string;
  autoComplete?: string;
  copyOption?: boolean;
  type?: "text" | "password";
  isLoading?: boolean;
  maxLength?: number;
  onChange?: (e?: any) => void;
  onKeyDown?: (e?: any) => void;
};

const GaTextInput: React.FC<GaTextInputProps> = React.memo((props) => {
  const {
    id,
    labelText,
    placeholder,
    className,
    helperText,
    disabled,
    name,
    value,
    readOnly,
    defaultValue,
    invalidText,
    invalid,
    required,
    autoComplete,
    type,
    maxLength,
    copyOption,
    isLoading,
    onChange,
    onKeyDown,
  } = props;
  const { t } = useTranslation();

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    !!invalid && setTouched(false);
  }, [value]);

  return (
    <div
      className={`${className} ${style.gaTextInputContainer} ${
        required ? style.required : ""
      } ${readOnly ? style.readOnly : ""}`}>
      {!isLoading ? (
        type && type === "password" ? (
          <PasswordInput
            labelText={labelText ? <Trans t={t}>{labelText}</Trans> : ""}
            placeholder={placeholder ? t(placeholder) : ""}
            id={id ? id : labelText || "" + Math.random()}
            invalidText={invalidText ? t(invalidText) : ""}
            invalid={touched ? invalid : false}
            name={name ? name : ""}
            maxLength={maxLength || undefined}
            onChange={(e) => {
              return onChange ? onChange(e) : () => {};
            }}
            onBlur={() => setTouched(true)}
            required={required || false}
            autoComplete={"Off"}
            readOnly={readOnly}
            defaultValue={defaultValue}
            disabled={disabled || readOnly}
            helperText={helperText || ""}
            value={value || ""}
            showPasswordLabel={t("general.show")}
            hidePasswordLabel={t("general.hide")}
          />
        ) : (
          <>
            {maxLength ? (
              <span
                className={cx(
                  "bodyRegularCap neutral600",
                  style.maxLengthLabel
                )}>
                <Trans
                  i18nKey={"general.maxRule"}
                  values={{ length: maxLength }}
                />
              </span>
            ) : null}
            <TextInput
              readOnly={readOnly}
              defaultValue={defaultValue}
              disabled={disabled || readOnly}
              invalid={touched ? invalid : false}
              id={id ? id : labelText || "" + Math.random()}
              helperText={helperText || ""}
              onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : () => {})}
              invalidText={invalidText ? t(invalidText) : ""}
              labelText={labelText ? <Trans t={t}>{labelText}</Trans> : ""}
              onBlur={() => setTouched(true)}
              name={name ? name : ""}
              maxLength={maxLength || undefined}
              onChange={(e) => (onChange ? onChange(e) : () => {})}
              required={required || false}
              autoComplete={autoComplete ? autoComplete : "Off"}
              placeholder={placeholder ? t(placeholder) : ""}
              type={type ? type : "text"}
              value={value || ""}
            />
            {copyOption ? (
              <div className={style.copyButton}>
                <div
                  className={style.copyButton__button}
                  onClick={() => {
                    navigator.clipboard.writeText(value || "");
                  }}>
                  <CopyIcon size={16} color="#1E1E20" />
                </div>
              </div>
            ) : null}
          </>
        )
      ) : (
        <InputSkeleton
          className={className || ""}
          labelText={labelText}
          required={required}
        />
      )}
    </div>
  );
});

export default GaTextInput;
