import * as React from "react";
import { Trans } from "react-i18next";
import cx from "classnames";
import styles from "./totalFeatures.module.scss";
import { featureOptions } from "../../data/home.constants";
import { t } from "i18next";

type ITotalFeaturesProps = {
  feature: string;
  total: number;
};

const TotalFeatures: React.FC<ITotalFeaturesProps> = (props) => {
  const { feature, total } = props;

  const selectedFeatureLabel = featureOptions?.find(
    (el) => el.value === feature
  )?.text;

  return (
    <div className={styles.totalContainer}>
      <h4 className={cx("heading4 primary700 margin0")}>{total}</h4>
      <div className={`${styles.totalFeatures} ${cx("bodyRegularSM")}`}>
        <Trans
          i18nKey={"dashboard.featureTotal"}
          components={{ bold: <span />, br: <br /> }}
          values={{
            feature: t(selectedFeatureLabel || ""),
          }}
        ></Trans>
      </div>
    </div>
  );
};

export default TotalFeatures;
