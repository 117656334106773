import React from "react";

type SkullIconProps = {
  size?: number;
  color?: string;
  className?: string;
};

const SkullIcon: React.FC<SkullIconProps> = React.memo((props) => {
  const { size, color, className } = props;

  return (
    <svg
      className={className && className}
      width={size || "32"}
      height={size || "32"}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.9674 13.6944V14.2442C28.906 16.7625 28.0759 19.1974 26.5977 21.2223L25.9921 22.052L26.6398 22.8493C26.9248 23.2 27.0885 23.6436 27.0885 24.1218V24.1657C27.0885 25.2753 26.1838 26.18 25.0742 26.18H23.7007H22.578L22.3869 27.2864L21.803 30.6667H11.1679L10.584 27.2864L10.3929 26.18H9.27015H7.89668C6.78711 26.18 5.88241 25.2753 5.88241 24.1657V24.1218C5.88241 23.6407 6.04561 23.2006 6.33106 22.8493L6.97882 22.052L6.37316 21.2223C4.83613 19.1168 3.99984 16.5717 3.99984 13.9435V13.6907C3.99984 12.022 4.32712 10.404 4.9713 8.88111L4.97153 8.88057C5.59262 7.41044 6.48414 6.08905 7.61984 4.95334C8.75555 3.81763 10.0769 2.92611 11.5471 2.30502L11.5476 2.30479C13.0707 1.66054 14.685 1.33333 16.3572 1.33333H16.61C18.2787 1.33333 19.8958 1.66058 21.4173 2.3075L21.4201 2.30869C22.8903 2.92978 24.2117 3.82129 25.3474 4.957C26.4831 6.09271 27.3746 7.4141 27.9957 8.88423L27.9959 8.88478C28.6402 10.4079 28.9674 12.0221 28.9674 13.6944Z"
        stroke={color || "currentColor" || "#1E1E20"}
        strokeWidth="2.66667"
      />
      <path
        d="M13.7418 16.4487C13.7418 17.6765 12.7465 18.6717 11.5188 18.6717C10.291 18.6717 9.29574 17.6765 9.29574 16.4487C9.29574 15.221 10.291 14.2257 11.5188 14.2257C12.7465 14.2257 13.7418 15.221 13.7418 16.4487Z"
        stroke={color || "currentColor" || "#1E1E20"}
        strokeWidth="2.66667"
      />
      <path
        d="M23.6715 16.4487C23.6715 17.6765 22.6762 18.6717 21.4485 18.6717C20.2207 18.6717 19.2254 17.6765 19.2254 16.4487C19.2254 15.221 20.2207 14.2257 21.4485 14.2257C22.6762 14.2257 23.6715 15.221 23.6715 16.4487Z"
        stroke={color || "currentColor" || "#1E1E20"}
        strokeWidth="2.66667"
      />
      <path
        d="M15.2768 21.0598C14.7348 20.9536 14.2147 21.3089 14.1085 21.8473L13.5554 24.6967C13.4492 25.2388 13.8045 25.7589 14.3429 25.8651C14.4088 25.8761 14.471 25.8834 14.5333 25.8834C15.0021 25.8834 15.4197 25.5538 15.5112 25.0777L16.0643 22.2282C16.1668 21.6861 15.8152 21.1624 15.2768 21.0598Z"
        fill={color || "currentColor" || "#1E1E20"}
      />
      <path
        d="M18.859 21.8474C18.7528 21.3053 18.2291 20.9537 17.6907 21.0599C17.1486 21.1662 16.797 21.6899 16.9032 22.2283L17.4562 25.0778C17.5478 25.5539 17.9653 25.8836 18.4342 25.8836C18.4964 25.8836 18.5623 25.8762 18.6246 25.8652C19.1667 25.759 19.5183 25.2353 19.4121 24.6969L18.859 21.8474Z"
        fill={color || "currentColor" || "#1E1E20"}
      />
    </svg>
  );
});

export default SkullIcon;
