import React from "react";
import GaTag from "../../../../../../components/atoms/chips/Tag";
import styles from "./statusCell.module.scss";
import cx from "classnames";
import { IClientModel } from "../../../../../../interfaces/interfaces";
import {
  getActionCellContent,
  getActionOptions,
} from "../../../../data/clients.constants";

type StatusCellProps = {
  item: IClientModel;
};

export const CredStatusActions: React.FC<StatusCellProps> = (props) => {
  const { item } = props;
  const status = item?.status;

  return (
    <td>
      {getActionCellContent(status)?.text ? (
        <div className={styles.credStatus}>
          <GaTag
            color={getActionCellContent(status)?.color || "blue"}
            text={getActionOptions(status)?.text || status || ""}
          />
        </div>
      ) : (
        <span className={cx("buttonMD neutral1000")}>-</span>
      )}
    </td>
  );
};
