import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import commonEn from "./locales/common/en.json";
import commonEs from "./locales/common/es.json";
import commonPt from "./locales/common/pt.json";

const resources = {
  es: { common: commonEs },
  en: { common: commonEn },
  pt: { common: commonPt },
};

const options = {
  order: [
    "navigator",
  ],
  lookupQuerystring: "lng",
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources,
    ns: ["common"],
    defaultNS: "common",
    fallbackLng: "en",
    supportedLngs: ["es", "en", "pt"],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
  });

// Check the localstorage saved language
const storedLang = localStorage.getItem("i18nextLng");
const initialLang = storedLang?.length === 2 ? storedLang : "en";
const supportedLngs = ["es", "en", "pt"];

export default i18n;
export { initialLang, supportedLngs };
