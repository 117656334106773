import { ActionStatusTypes } from "../../../../data/common";
import { IUsersState, UsersAction } from "./usersTypes";

const initialUsersState: IUsersState = {
  users: [],
  selectedUser: undefined,
  tenant: "",
  changePassword: {
    loading: {
      status: "inactive",
      description: " ",
    },
  },
  showUserDetail: false,
  loadingStatus: false,
  status: ActionStatusTypes.neutral,
  error: "",
  profile: undefined,
};

export function usersReducer(
  state = initialUsersState,
  action: UsersAction
): IUsersState {
  switch (action.type) {
    default:
      return state;
  }
}
