import { ILicenseItem } from "../../../../interfaces/interfaces";
import {
  SessionAction,
  SessionActionTypes,
  SessionState,
} from "./sessionTypes";

const sessionInitialState: SessionState = {
  tokenData: undefined,
  loading: true,
  user: "",
  ssiOperations: undefined,
  licenses: undefined,
  tenants: [],
  error: "",
  tenant: undefined,
  signUp: false,
  role: undefined,
  allApiKeysAllowed: false,
  allDidsAllowed: false,
  allSsiOperationsAllowed: false,
  usage: undefined,
  licensesLoading: false,
  showTenantModal: false,
  sessionRead: false,
};

export const sessionReducer = (
  state = sessionInitialState,
  action: SessionAction
): SessionState => {
  let currentLicense: ILicenseItem | undefined;
  switch (action.type) {
    case SessionActionTypes.SET_SESSION:
      return {
        ...state,
        sessionRead: false,
      };
    case SessionActionTypes.READ_SESSION_SUCCESS:
      currentLicense = action.licenses?.find((l: ILicenseItem) =>
        l.tenants.includes(action.currentTenant)
      );
      return {
        ...state,
        tokenData: {
          tokenExpiration: action.tokenExpiration,
          currentTenant: action.currentTenant,
          organizationId: action?.orgId || currentLicense?.orgId,
          allowedScopes: action.scopesDetected,
          userDids: action.dids,
          userSsiOperations: action.ssiOperations,
          apiKeys: action.apiKeys,
        },
        user: action.email || state.user,
        isOnPremise: currentLicense?.licenseType === "OnPremise",
        sandboxId: currentLicense?.sbxId,
        role: action.role,
        tenant: action.tenant,
        allApiKeysAllowed: action.allApiKeysAllowed,
        allDidsAllowed: action.allDidsAllowed,
        allSsiOperationsAllowed: action.allSsiOperationsAllowed,
        allTenantsAllowed: action.allTenantsAllowed,
        usage: currentLicense?.usage,
        sessionRead: true,
      };
    case SessionActionTypes.READ_SESSION_FAILED:
      return {
        ...state,
        sessionRead: true,
      };
    case SessionActionTypes.DELETE_SESSION:
      return {
        ...state,
        tokenData: undefined,
        loading: true,
        user: "",
        licenses: undefined,
        tenants: [],
        ssiOperations: undefined,
        error: "",
        tenant: undefined,
        signUp: false,
        role: undefined,
        allApiKeysAllowed: false,
        allDidsAllowed: false,
        allSsiOperationsAllowed: false,
        usage: undefined,
        sessionRead: true,
      };
    case SessionActionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };
    //TODO : when non Org user is possible abilitate this
    case SessionActionTypes.GET_USER:
      return {
        ...state,
        // loading: true
      };
    case SessionActionTypes.SET_USER_ORGANIZATION:
      return {
        ...state,
        tokenData: state.tokenData
          ? {
              ...state.tokenData,
              organizationId: action.payload?.org?.id,
            }
          : undefined,
      };
    case SessionActionTypes.SET_SESSION_APIKEYS:
      return {
        ...state,
        tokenData: state.tokenData
          ? {
              ...state.tokenData,
              apiKeys: action.payload.api_keys,
            }
          : undefined,
      };

    case SessionActionTypes.SET_SESSION_DIDS:
      return {
        ...state,
        dids: action.payload.dids,
      };
    case SessionActionTypes.SET_SESSION_SSI_OPERATIONS:
      let existingSsiOperations = state.ssiOperations || {};
      existingSsiOperations[action.payload.type] = action.payload.ssiOperations;
      return {
        ...state,
        ssiOperations: existingSsiOperations,
      };
    default:
      return state;
  }
};
