import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../information.module.scss";
import GaTextInput from "../../../../../../../../components/elements/forms/formFields/textInput/GaTextInput";
import GaSelect from "../../../../../../../../components/elements/forms/formFields/select/GaSelect";
import { countriesAndStates } from "../../../../../../../../data/forms";
import cx from "classnames";
import { ILegalAddress } from "../../../../../../../../interfaces/interfaces";
import { initialLang } from "../../../../../../../../i18n";
import LightPanel from "../../../../../../../../components/elements/panels/LightPanel/LightPanel";
import PurpleButton from "../../../../../../../../components/atoms/buttons/purple/PurpleButton";
import GreyButton from "../../../../../../../../components/atoms/buttons/grey/GreyButton";
import { ButtonSizes } from "../../../../../../../../data/display";
import CloseIcon from "../../../../../../../../assets/iconsLibrary/simple/close/closeIcon";
import { useSelector } from "react-redux";
import { clientsSelectors } from "../../../../../../store";
import { ActionStatusTypes } from "../../../../../../../../data/common";

export type InformationFormProps = {
  formData: ILegalAddress;
  orgAddress?: ILegalAddress;
  secondFormActive: boolean;
  secondFormDataModified: boolean;
  states: string[];
  orgCountry: any;
  defaultStates: string[];
  emptySecondFormData: ILegalAddress;
  setLegalAddressState: (x?: any) => void;
  setSecondFormActive: (x: boolean) => void;
  setFormState: (x: any) => void;
  setStates: (x?: any) => void;
  getStates: (x?: any) => void;
  handleAddressInputsChange: (x: any, y?: any) => void;
  updateWithSecondFormData: (x?: any) => void;
};

const InformationForm: React.FC<InformationFormProps> = (props) => {
  const {
    states,
    formData,
    orgAddress,
    secondFormActive,
    orgCountry,
    defaultStates,
    emptySecondFormData,
    secondFormDataModified,
    getStates,
    setLegalAddressState,
    setStates,
    setSecondFormActive,
    handleAddressInputsChange,
    updateWithSecondFormData,
  } = props;

  const { t } = useTranslation();
  const { street, suite, number, locality, region, country, postalCode } =
    formData;

  const updateOrgStatus = useSelector(clientsSelectors.getOrgByIdStatus);

  useEffect(() => {
    if (updateOrgStatus === ActionStatusTypes?.success && secondFormActive) {
      setSecondFormActive(false);
    } else if (
      updateOrgStatus === ActionStatusTypes?.failed &&
      secondFormActive
    ) {
      setLegalAddressState(emptySecondFormData);
    }
  });

  return (
    <LightPanel className={styles.formLightPanel}>
      <div className={styles.formLightPanel__header}>
        <p className={cx("heading5 neutral1000")}>
          {t("clientDetail.companyInformation")}
        </p>
        {!secondFormActive ? (
          <GreyButton
            size={ButtonSizes.SM}
            label={t("general.edit")}
            outlined
            action={() => setSecondFormActive(true)}
          />
        ) : (
          <div
            onClick={() => (
              setLegalAddressState(emptySecondFormData),
              setSecondFormActive(false),
              setStates(orgCountry?.states || defaultStates)
            )}
          >
            <CloseIcon size={24} className={styles.closeFormIcon} />
          </div>
        )}
      </div>
      <div className={styles.form}>
        <div className={styles.form__textInputs}>
          <GaTextInput
            name="street"
            readOnly={!secondFormActive}
            invalid={false}
            labelText={"clientDetail.address"}
            onChange={(e) => handleAddressInputsChange(e)}
            placeholder={t("clientDetail.addressPlaceholder")}
            type="text"
            value={street}
          />
          <div className={styles.form__textInputs__doubleColumn}>
            <GaTextInput
              name="number"
              invalid={false}
              readOnly={!secondFormActive}
              labelText={"welcome.number"}
              onChange={(e) => handleAddressInputsChange(e)}
              placeholder={t("welcome.number")}
              type="text"
              value={number}
            />
            <GaTextInput
              name="suite"
              invalid={false}
              readOnly={!secondFormActive}
              labelText={"welcome.suite"}
              onChange={(e) => handleAddressInputsChange(e)}
              placeholder={t("welcome.suitePlaceholder")}
              type="text"
              value={suite}
            />
          </div>
          <div className={styles.form__textInputs__doubleColumn}>
            {secondFormActive ? (
              <GaSelect
                value={country}
                name="country"
                inline={false}
                readOnly={!secondFormActive}
                invalid={false}
                isLoading={!countriesAndStates?.length}
                labelText={"welcome.country"}
                optionValueProperty={
                  initialLang ? "name_" + initialLang : "name_es"
                }
                optionTextProperty={
                  initialLang ? "name_" + initialLang : "name_es"
                }
                onChange={(e) => {
                  handleAddressInputsChange(e), getStates(e.target.value);
                }}
                options={countriesAndStates}
              />
            ) : (
              <GaTextInput
                name="country"
                invalid={false}
                readOnly
                labelText={"welcome.country"}
                onChange={() => {}}
                placeholder={t("welcome.country")}
                type="text"
                value={orgAddress?.country ? orgAddress?.country : ""}
              />
            )}
            {secondFormActive ? (
              <GaSelect
                value={region}
                name="region"
                inline={false}
                invalid={false}
                readOnly={!secondFormActive}
                isLoading={!states?.length}
                labelText={"welcome.region"}
                onChange={(e) => handleAddressInputsChange(e)}
                options={states}
              />
            ) : (
              <GaTextInput
                name="region"
                invalid={false}
                readOnly
                labelText={"welcome.region"}
                onChange={() => {}}
                placeholder={t("welcome.region")}
                type="text"
                value={orgAddress?.region ? orgAddress?.region : ""}
              />
            )}
          </div>
          <div className={styles.form__textInputs__doubleColumn}>
            <GaTextInput
              name="locality"
              invalid={false}
              readOnly={!secondFormActive}
              labelText={"welcome.locality"}
              onChange={(e) => handleAddressInputsChange(e)}
              placeholder={t("welcome.locality")}
              type="text"
              value={locality}
            />

            <GaTextInput
              name="postalCode"
              invalid={false}
              readOnly={!secondFormActive}
              labelText={"welcome.postalCode"}
              onChange={(e) => handleAddressInputsChange(e)}
              placeholder={t("welcome.postalCode")}
              type="text"
              value={postalCode}
            />
          </div>
        </div>
      </div>
      {secondFormActive ? (
        <div className={styles.formButtons}>
          <GreyButton
            size={ButtonSizes.SM}
            label={t("general.cancel")}
            outlined
            action={() => setSecondFormActive(false)}
          />{" "}
          <PurpleButton
            size={ButtonSizes.SM}
            label={t("general.save")}
            action={() => updateWithSecondFormData()}
            disabled={!secondFormDataModified}
          />
        </div>
      ) : null}
    </LightPanel>
  );
};

export default InformationForm;
