import { ILicenseItem } from "../../../../interfaces/interfaces";
import { RootState } from "../../../../store/indexReducers";

// @ts-ignore
export const getUserEmail_s = (state: RootState) => state.sessionReducer.user;
export const getUserImage = (state: RootState) => state.sessionReducer.image;
export const getSessionLoading_s = (state: RootState) =>
  state.sessionReducer.loading;
export const getLicensesAreLoading = (state: RootState) =>
  state.sessionReducer.licensesLoading;
export const getUserRole_s = (state: RootState) => state.sessionReducer.role;
export const getUserOrganization = (state: RootState) =>
  state.sessionReducer.tokenData?.organizationId;
export const getUserSSIOperations = (state: RootState) =>
  state.sessionReducer.tokenData?.userSsiOperations;
export const getTokenData = (state: RootState) =>
  state.sessionReducer.tokenData;
export const getTokenExpiration = (state: RootState) =>
  state.sessionReducer.tokenData?.tokenExpiration;
export const getLicenses = (state: RootState) => state.sessionReducer.licenses;
export const getLicenseType = (state: RootState) =>
  returnLicenseType(state.sessionReducer.licenses);
export const getTenants = (state: RootState) => state.sessionReducer.tenants;
export const getCurrentTenant = (state: RootState) =>
  state.sessionReducer.tokenData?.currentTenant;
export const allSsiOperationsAllowed = (state: RootState) =>
  state.sessionReducer.allSsiOperationsAllowed;
export const allApiKeysAllowed = (state: RootState) =>
  state.sessionReducer.allApiKeysAllowed;
export const allDidsAllowed = (state: RootState) =>
  state.sessionReducer.allDidsAllowed;
export const allTenantsAllowed = (state: RootState) =>
  state.sessionReducer.allTenantsAllowed;
export const getSsiOperations = (state: RootState) =>
  Object.values(state.sessionReducer.ssiOperations || {}).flat();
export const getUserSsiOperations = (state: RootState) =>
  state.sessionReducer.tokenData?.userSsiOperations;
export const getUserDids = (state: RootState) =>
  state.sessionReducer.tokenData?.userDids;
export const getUserTenant = (state: RootState) => state.sessionReducer.tenant;

export const getUserApiKeys = (state: RootState) =>
  state.sessionReducer.tokenData?.apiKeys;
export const isOnPremise = (state: RootState) =>
  state.sessionReducer.isOnPremise;
export const getSessionRead = (state: RootState) =>
  state.sessionReducer.sessionRead;
export const getAllowedScopes = (state: RootState) =>
  state.sessionReducer.tokenData?.allowedScopes;
export const getShowTenantModal = (state: RootState) =>
  state.sessionReducer.showTenantModal;

export const getLicenseUsage = (state: RootState) => state.sessionReducer.usage;

const returnLicenseType = (licenses: ILicenseItem[] | undefined) => {
  if (licenses && licenses[0]) {
    return licenses[0].licenseType;
  } else {
    return "Starter";
  }
};
