import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "../../information.module.scss";
import GaTextInput from "../../../../../../../../components/elements/forms/formFields/textInput/GaTextInput";
import GaSelect from "../../../../../../../../components/elements/forms/formFields/select/GaSelect";
import { domains } from "../../../../../../../../data/forms";
import cx from "classnames";
import LightPanel from "../../../../../../../../components/elements/panels/LightPanel/LightPanel";
import PurpleButton from "../../../../../../../../components/atoms/buttons/purple/PurpleButton";
import { ButtonSizes } from "../../../../../../../../data/display";
import GreyButton from "../../../../../../../../components/atoms/buttons/grey/GreyButton";
import CloseIcon from "../../../../../../../../assets/iconsLibrary/simple/close/closeIcon";
import { useSelector } from "react-redux";
import { clientsSelectors } from "../../../../../../store";
import { ActionStatusTypes } from "../../../../../../../../data/common";

export type FormDataType = {
  name: string;
  legalName: string;
  domainName: string;
  VATRegistration: string;
  discoveryURL: string;
  photo: undefined;
};

export type ProfileFormProps = {
  formData: FormDataType;
  emptyFirstFormData: FormDataType;
  firstFormActive: boolean;
  firstFormDataModified: boolean;
  firstFormIsValid: boolean;
  updateWithFirstFormData: () => void;
  setFirstFormActive: (x: boolean) => void;
  setFormState: (x: any) => void;
  handleInputChange: (x: any, y?: any) => void;
};

const ProfileForm: React.FC<ProfileFormProps> = (props) => {
  const {
    formData,
    firstFormActive,
    firstFormIsValid,
    emptyFirstFormData,
    firstFormDataModified,
    updateWithFirstFormData,
    setFormState,
    setFirstFormActive,
    handleInputChange,
  } = props;

  const { t } = useTranslation();
  const { name, legalName, domainName, VATRegistration, discoveryURL } =
    formData;

  const domainValue = domains?.find((el) => el?.value === domainName)?.value;

  const updateOrgStatus = useSelector(clientsSelectors.getOrgByIdStatus);

  useEffect(() => {
    if (updateOrgStatus === ActionStatusTypes?.success && firstFormActive) {
      setFirstFormActive(false);
    } else if (
      updateOrgStatus === ActionStatusTypes?.failed &&
      firstFormActive
    ) {
      setFormState(emptyFirstFormData);
    }
  });

  return (
    <LightPanel className={styles.formLightPanel}>
      <div className={styles.formLightPanel__header}>
        <p className={cx("heading5 neutral1000")}>
          {" "}
          {t("clientDetail.companyProfile")}
        </p>
        {!firstFormActive ? (
          <GreyButton
            size={ButtonSizes.SM}
            label={t("general.edit")}
            outlined
            action={() => setFirstFormActive(true)}
          />
        ) : (
          <div
            onClick={() => (
              setFormState(emptyFirstFormData), setFirstFormActive(false)
            )}
          >
            <CloseIcon size={24} className={styles.closeFormIcon} />
          </div>
        )}
      </div>
      <div className={styles.form}>
        <div className={styles.form__textInputs}>
          <GaTextInput
            name="name"
            invalid={false}
            invalidText={t("general.required")}
            labelText={"welcome.companyName"}
            onChange={(e) => handleInputChange(e)}
            placeholder={t("welcome.companyName")}
            type="text"
            readOnly={!firstFormActive}
            value={name}
            required
          />
          <GaTextInput
            name="legalName"
            invalid={false}
            invalidText={t("general.required")}
            labelText={"welcome.legalName"}
            onChange={(e) => handleInputChange(e)}
            placeholder={t("welcome.legalName")}
            type="text"
            readOnly={!firstFormActive}
            value={legalName}
            required
          />
          {firstFormActive ? (
            <GaSelect
              value={domainName}
              name="domainName"
              inline={false}
              required
              invalid={false}
              readOnly={!firstFormActive}
              isLoading={!domains?.length}
              invalidText={t("general.required")}
              labelText={"welcome.domainName"}
              onChange={(e) => handleInputChange(e)}
              placeholder={t("welcome.domainName")}
              optionTextProperty={"text"}
              optionValueProperty={"value"}
              options={domains}
            />
          ) : (
            <GaTextInput
              name="domainName"
              invalid={false}
              invalidText={t("general.required")}
              labelText={"welcome.domainName"}
              onChange={(e) => handleInputChange(e)}
              placeholder={t("welcome.domainName")}
              type="text"
              readOnly={!firstFormActive}
              value={t(domainValue || "")}
              required
            />
          )}
          <div className={styles.form__textInputs__doubleColumn}>
            <GaTextInput
              name="discoveryURL"
              invalid={false}
              invalidText={t("general.required")}
              labelText={"login.website"}
              onChange={(e) => handleInputChange(e)}
              placeholder={t("login.website")}
              type="text"
              readOnly={!firstFormActive}
              value={discoveryURL}
              required
            />

            <GaTextInput
              name="VATRegistration"
              invalid={false}
              required
              invalidText={t("general.required")}
              labelText={"welcome.nif"}
              onChange={(e) => handleInputChange(e)}
              placeholder={t("welcome.nif")}
              type="text"
              readOnly={!firstFormActive}
              value={VATRegistration}
            />
          </div>
        </div>
      </div>
      {firstFormActive ? (
        <div className={styles.formButtons}>
          <GreyButton
            size={ButtonSizes.SM}
            label={t("general.cancel")}
            outlined
            action={() => (
              setFormState(emptyFirstFormData), setFirstFormActive(false)
            )}
          />
          <PurpleButton
            size={ButtonSizes.SM}
            label={t("general.save")}
            action={() => updateWithFirstFormData()}
            disabled={!(firstFormDataModified && firstFormIsValid)}
          />
        </div>
      ) : null}
    </LightPanel>
  );
};

export default ProfileForm;
