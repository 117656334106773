import React, { useEffect } from "react";
import styles from "./warningModal.module.scss";
import cx from "classnames";
import { Trans, useTranslation } from "react-i18next";
import { ButtonModel, ImageModel } from "../../../../interfaces/interfaces";
import GaImage from "../../../atoms/Image/Image";
import { gaImages } from "../../../../data/images";
import GreyButton from "../../../atoms/buttons/grey/GreyButton";
import CloseIcon from "../../../../assets/iconsLibrary/simple/close/closeIcon";
import { ButtonSizes, gaColors } from "../../../../data/display";
import RedButton from "../../../atoms/buttons/red/RedButton";

type IWarningModalProps = {
  title?: string;
  description?: string;
  item: any;
  descriptionExtraTranslationParams?: Object;
  icon?: ImageModel;
  primaryButton: ButtonModel;
  secondaryButton: ButtonModel;
  showBg?: boolean;
  headerClose?: boolean;
  hideModal: () => void;
};

const WarningModal: React.FC<IWarningModalProps> = (props) => {
  const {
    title,
    description,
    icon,
    item,
    primaryButton,
    secondaryButton,
    showBg,
    descriptionExtraTranslationParams,
    headerClose,
    hideModal,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {}, [descriptionExtraTranslationParams]);

  return (
    <>
      <div
        className={`${styles.backgroundLayer} ${
          showBg ? styles.darkBackgroundLayer : ""
        }`}
        onClick={() => (!headerClose ? hideModal() : null)}>
        <div>
          <div className={styles.modal}>
            {headerClose ? (
              <div className={styles.modal__close} onClick={() => hideModal()}>
                <CloseIcon size={24} color={gaColors.neutral1000} />
              </div>
            ) : null}
            <GaImage
              className={styles.modal__icon}
              image={icon || gaImages.redWarningPng}
            />
            <p className={cx("heading5 neutral1000", styles.modal__title)}>
              {t(title || "general.sureDelete")}
            </p>
            <p
              className={cx(
                "bodyRegularMD neutral700",
                styles.modal__description
              )}>
              <Trans
                i18nKey={description || "general.noAbleToRecover"}
                values={{ ...descriptionExtraTranslationParams }}
              />
            </p>
            <div>
              <GreyButton
                className={styles.modal__cancel}
                outlined
                size={ButtonSizes.MD}
                label={t(secondaryButton?.label)}
                disabled={false}
                action={secondaryButton?.action}
              />

              <RedButton
                size={ButtonSizes.MD}
                className={styles.modal__continue}
                label={t(primaryButton?.label)}
                disabled={false}
                action={(e: any) => primaryButton?.action(item)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarningModal;
