import { ITierModel } from "../../../interfaces/interfaces";
import {
  IGetTiers,
  IGetTiersFailed,
  IGetTiersSuccess,
  ICreateTier,
  ICreateTierSuccess,
  ICreateTierFailed,
  IDeleteTier,
  IDeleteTierSuccess,
  IDeleteTierFailed,
  TiersTypes,
  IUpdateTier,
  IUpdateTierSuccess,
  IUpdateTierFailed,
  SelectTier,
} from "./tiersTypes";

export const getTiers = (providerId: string): IGetTiers => {
  return {
    type: TiersTypes.GET_TIERS,
    payload: {
      providerId: providerId,
    },
  };
};

export const getTiersSuccess = (tiers: ITierModel[]): IGetTiersSuccess => {
  return {
    type: TiersTypes.GET_TIERS_SUCCESS,
    payload: {
      tiers: tiers,
    },
  };
};

export const getTiersFailed = (error: any): IGetTiersFailed => {
  return {
    type: TiersTypes.GET_TIERS_FAILED,
    payload: {
      error: error,
    },
  };
};

export const createTier = (tier: ITierModel): ICreateTier => {
  return {
    type: TiersTypes.CREATE_TIER,
    payload: {
      tier: tier,
    },
  };
};

export const createTierSuccess = (created: ITierModel): ICreateTierSuccess => {
  return {
    type: TiersTypes.CREATE_TIER_SUCCESS,
    payload: {
      message: "App created successfully",
      tier: created,
    },
  };
};

export const createTierFailed = (err: any): ICreateTierFailed => {
  return {
    type: TiersTypes.CREATE_TIER_FAILED,
    payload: {
      error: err,
    },
  };
};

export const deleteTier = (tierID: string, provider: string): IDeleteTier => {
  return {
    type: TiersTypes.DELETE_TIER,
    payload: {
      tierID: tierID,
      provider: provider,
    },
  };
};

export const deleteTierSuccess = (id: string): IDeleteTierSuccess => {
  return {
    type: TiersTypes.DELETE_TIER_SUCCESS,
    payload: {
      id: id,
    },
  };
};

export const deleteTierFailed = (err: any): IDeleteTierFailed => {
  return {
    type: TiersTypes.DELETE_TIER_FAILED,
  };
};

export const updateTier = (tier: ITierModel): IUpdateTier => {
  return {
    type: TiersTypes.UPDATE_TIER,
    payload: {
      tier: tier,
    },
  };
};

export const updateTierSuccess = (tier: ITierModel): IUpdateTierSuccess => {
  return {
    type: TiersTypes.UPDATE_TIER_SUCCESS,
    payload: {
      tier,
    },
  };
};

export const updateTierFailed = (err: any): IUpdateTierFailed => {
  return {
    type: TiersTypes.UPDATE_TIER_FAILED,
    payload: {
      error: err,
    },
  };
};

export const selectTier = (tier?: ITierModel): SelectTier => {
  return {
    type: TiersTypes.SELECT_TIER,
    payload: {
      tier: tier,
    },
  };
};
