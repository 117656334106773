import {
  MetricStatistic,
  Statistics,
} from "../../../../../../../interfaces/interfaces";
import {
  IGetStatistics,
  IGetStatisticsFailed,
  IGetStatisticsSuccess,
  ISetSelectedPeriodYear,
  StatisticsTypes,
} from "./metricsTypes";

export const getStatistics = (urlParams: string): IGetStatistics => {
  return {
    type: StatisticsTypes.GET_ALL_STATISTICS,
    payload: { params: urlParams },
  };
};

export const getStatisticsSuccess = (
  stats: Statistics
): IGetStatisticsSuccess => {
  return {
    type: StatisticsTypes.GET_ALL_STATISTICS_SUCCESS,
    payload: {
      statistics: stats,
    },
  };
};

export const getStatisticsFailed = (error: any): IGetStatisticsFailed => {
  return {
    type: StatisticsTypes.GET_ALL_STATISTICS_FAILED,
    payload: {
      error: error,
    },
  };
};

export const setSelectedPeriodIsYear = (
  isYear: boolean
): ISetSelectedPeriodYear => {
  return {
    type: StatisticsTypes.SET_SELECTED_PERIOD_YEAR,
    payload: {
      isYear,
    },
  };
};
