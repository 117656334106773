import { act } from "react-dom/test-utils";
import { ActionStatusTypes } from "../../../data/common";
import { IHomeState, IHomeActions, StatisticsTypes } from "./homeTypes";

const statisticsState: IHomeState = {
  getStaticsStatus: ActionStatusTypes.neutral,
  statistics: undefined,
  selectedPeriodIsYear: false,
  clientStats: undefined,
};
export const statisticsReducer = (
  state = statisticsState,
  action: IHomeActions
): IHomeState => {
  switch (action.type) {
    case StatisticsTypes.GET_ALL_STATISTICS:
      return {
        ...state,
        getStaticsStatus: ActionStatusTypes.loading,
      };
    case StatisticsTypes.GET_ALL_STATISTICS_SUCCESS:
      return {
        ...state,
        getStaticsStatus: ActionStatusTypes.success,
        statistics: action.payload.statistics,
      };
    case StatisticsTypes.GET_ALL_STATISTICS_FAILED:
      return {
        ...state,
        getStaticsStatus: ActionStatusTypes.failed,
        statistics: undefined,
      };
    case StatisticsTypes.SET_SELECTED_PERIOD_YEAR:
      return {
        ...state,
        selectedPeriodIsYear: action?.payload?.isYear,
      };
    case StatisticsTypes.GET_CLIENTS_STATISTICS_SUCCESS:
      return {
        ...state,
        clientStats: action.payload.statistics,
      };
    case StatisticsTypes.GET_CLIENTS_STATISTICS_FAILED:
      return {
        ...state,
        clientStats: undefined,
      };
    default:
      return state;
  }
};
