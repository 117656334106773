import React from "react";
import { PaginatorProps } from "../../../../../../components/elements/paginator/Paginator";
import {
  extProviderHeadersClientsTable,
  headersClientsTable,
} from "../../../../data/clients.constants";
import StripeTable from "../../../../components/table/stripeTable/StripeTable";
import ExternalProviderTable from "../../../../components/table/externalProviderTable/ExternalProviderTable";

export type ClientsTableProps = {
  userTenant?: string;
  tableData: any[];
  noResults?: boolean;
  showingModal?: boolean;
  paginationData?: PaginatorProps;
  canDelete?: boolean;
  deleteItem?: (x?: any) => void;
  editItem?: (x?: any) => void;
  removeType?: (x: any, y?: any) => void;
  addType?: (x: any, y?: any) => void;
  cancelItem?: (x?: any) => void;
  activateItem?: (x?: any) => void;
};

const ClientsTable: React.FC<ClientsTableProps> = (props) => {
  const {
    userTenant,
    tableData,
    paginationData,
    noResults,
    canDelete,
    cancelItem,
    activateItem,
    deleteItem,
    editItem,
  } = props;

  return userTenant === "gataca" ? (
    <StripeTable
      tableData={tableData}
      headerData={headersClientsTable}
      paginationData={paginationData}
      noResults={noResults}
      canDelete={canDelete}
      editItem={editItem}
      deleteItem={deleteItem}
    />
  ) : (
    <ExternalProviderTable
      tableData={tableData}
      headerData={extProviderHeadersClientsTable}
      paginationData={paginationData}
      noResults={noResults}
      canDelete={canDelete}
      editItem={editItem}
      cancelItem={cancelItem}
      activateItem={activateItem}
    />
  );
};

export default ClientsTable;
