import { ILoginItem } from "../features/Login/views/Login/store/loginTypes";
import {
  IChangeSubData,
  IGaasReq,
  ITierModel,
  OrganizationModel,
  StatisticsParams,
} from "../interfaces/interfaces";
import { gaasReq } from "./networkService";

export const app = {
  connect: "CONNECT",
  certify: "CERTIFY",
  gaas: "GAAS",
  nucleus: "NUCLEUS",
};

export const getHost = (name: string) => {
  if (name === app.nucleus) {
    return ""; //window?.location?.host
  }
  const url = process.env["REACT_APP_" + name + "_HOST"];
  return url;
};

export default {
  getAuthData() {
    const call: IGaasReq = {
      url: getHost(app.nucleus) + "/admin/v1/auth",
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    };
    return gaasReq(call);
  },
  // LOGIN
  emailLogIn(user: ILoginItem) {
    const call: IGaasReq = {
      method: "post",
      url: getHost(app.nucleus) + "/admin/v1/users/login",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + btoa(user.username + ":" + user.password),
      },
    };
    return gaasReq(call);
  },
  logout() {
    const call: IGaasReq = {
      method: "delete",
      url: getHost(app.nucleus) + "/admin/v1/users/logout",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  switchTenant(tenant: string) {
    const call = {
      method: "post",
      url: getHost(app.nucleus) + "/admin/v1/users/login/switch",
      headers: {
        "Content-Type": "application/json",
        tenant: tenant,
      },
    };
    return gaasReq(call);
  },
  logIn() {
    const redirectURI = getHost(app.connect) + "/";
    const scope = "openid email";
    const responseType = "code";
    const responseMode = "form_post";
    const state = "testState";
    const nonce = "testNonce";
    const call: IGaasReq = {
      vouch: true,
      url:
        "/oauth2/authclient_id=DEV-Gataca-4&redirect_uri=" +
        redirectURI +
        "&scope=" +
        scope +
        "&response_type=" +
        responseType +
        "&response_mode=" +
        responseMode +
        "&state=" +
        state +
        "&nonce=" +
        nonce,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  authToken(token: string) {
    const call: IGaasReq = {
      url: "/token",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  isInit() {
    const call = {
      method: "get",
      url: getHost(app.nucleus) + "/admin/v1/users/login",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  getRegistrationToken(tenant: string, role: string) {
    const call = {
      method: "get",
      url:
        getHost(app.gaas) +
        "/admin/v1/token/register?tenant=" +
        tenant +
        "&role=" +
        role,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  // USERS
  existingUsers() {
    const call = {
      method: "get",
      url: getHost(app.nucleus) + "/admin/v1/users/status",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },

  // ORGANIZATIONS
  getOrganizations() {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/organizations",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  getOrganizationById(orgID: string) {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/organizations/" + orgID,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  updateOrganization(org: OrganizationModel) {
    const call = {
      method: "put",
      url: getHost(app.gaas) + "/admin/v1/organizations/" + org?.id,
      headers: {
        "Content-Type": "application/json",
      },
      body: org,
    };
    return gaasReq(call);
  },
  deleteOrg(orgID: string) {
    const call = {
      method: "delete",
      url: getHost(app.gaas) + "/admin/v1/organizations/" + orgID,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },

  // LICENSES
  getLicenses() {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/licenses",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  getLicenseById(orgID: string) {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/licenses/" + orgID,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  updateLicense(licenseType: string, newPriceId: string, orgID: string) {
    const call = {
      method: "put",
      url: getHost(app.gaas) + "/admin/v1/licenses/" + orgID,
      headers: {
        "Content-Type": "application/json",
      },
      body: {
        licenseType,
        newPriceId,
      },
    };
    return gaasReq(call);
  },
  // SUBSCRIPTIONS
  getSubscriptions() {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/subscriptions",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  suspendSubscription(id: string) {
    const call = {
      method: "PUT",
      url: getHost(app.gaas) + "/admin/v1/subscriptions/" + id + "/cancel",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  reactivateSubscription(id: string) {
    const call = {
      method: "delete",
      url: getHost(app.gaas) + "/admin/v1/subscriptions/" + id + "/cancel",
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  changeSubscription(orgId: string, data: IChangeSubData) {
    const call = {
      method: "post",
      url: getHost(app.gaas) + "/admin/v1/subscriptions/" + orgId + "/period",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    };
    return gaasReq(call);
  },

  // Statistics
  getAllStatistics(params: string) {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/statistics" + params,
      headers: {
        "Content-Type": "application/json",
      },
      data: "",
    };
    return gaasReq(call);
  },
  getClientsStatistics(params: string) {
    const call = {
      method: "get",
      url: getHost(app.gaas) + "/admin/v1/statistics/clients" + params,
      headers: {
        "Content-Type": "application/json",
      },
      data: "",
    };
    return gaasReq(call);
  },

  // Tiers
  getTiers(provider?: string) {
    const call = {
      method: "get",
      url:
        getHost(app.gaas) +
        "/admin/v1/subscriptions/tiers?provider=" +
        provider +
        "&active=" +
        true,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  createTier(tier: ITierModel) {
    const call = {
      method: "post",
      url: getHost(app.gaas) + "/admin/v1/subscriptions/tiers",
      headers: {
        "Content-Type": "application/json",
      },
      body: tier,
    };
    return gaasReq(call);
  },
  deleteTier(id: string) {
    const call = {
      method: "delete",
      url: getHost(app.gaas) + "/admin/v1/subscriptions/tiers/" + id,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return gaasReq(call);
  },
  updateTier(tier: ITierModel, id: string) {
    const call = {
      method: "put",
      url: getHost(app.gaas) + "/admin/v1/subscriptions/tiers/" + id,
      headers: {
        "Content-Type": "application/json",
      },
      body: tier,
    };
    return gaasReq(call);
  },
};
