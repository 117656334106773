import React from "react";
import PanelScafoldingPrivate from "../../PanelScafolding/components/private/PanelScafoldingPrivate";
import { useTranslation } from "react-i18next";
import ScreenHeader from "../../../components/elements/headers/ScreenHeader/ScreenHeader";
import SubTiers from "./list/SubTiers";
import Tabs from "../../../components/elements/tabs/Tabs";

type IPlatformConfigProps = {};

const PlatformConfig: React.FC<IPlatformConfigProps> = (props) => {
  const { t } = useTranslation();

  // Sections
  const sections = [
    {
      id: "1",
      tabTitle: t("platformConfig.subcriptionTiers.title"),
      tabContent: <SubTiers />,
    },
    {
      id: "2",
      tabTitle: t("platformConfig.paymentMethods.title"),
      tabContent: <p>2</p>,
      disabled: true,
      info: t("general.comminSoon"),
    },
  ];

  return (
    <PanelScafoldingPrivate>
      <ScreenHeader
        title={"platformConfig.screenTitle"}
        subText={"platformConfig.description"}
      />
      <Tabs sections={sections} className={"marginTop32"} />
    </PanelScafoldingPrivate>
  );
};

PlatformConfig.defaultProps = {};

export default PlatformConfig;
