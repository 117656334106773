import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, switchMap } from "rxjs/operators";
import dataService from "../../../../services/dataService";
import { readSessionFailed, readSessionSucess } from "./sessionActions";
import { SessionActionTypes } from "./sessionTypes";
import {
  loginFailed,
  loginSuccess,
  qrSignupSuccess,
} from "../../../Login/views/Login/store/loginAction";
import { navigateAction } from "../../../PanelScafolding/store/scafoldingActions";
import { showNotification } from "../../../Notification/store/notificationActions";

export const setSessionEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(SessionActionTypes.SET_SESSION),
    switchMap((action: any): any => {
      let taskFunc = action?.signUp ? loginFailed : loginSuccess;
      return of(taskFunc());
    })
  );
};

export const readSessionEpic = (action$: any, state$: any): any => {
  return action$.pipe(
    ofType(SessionActionTypes.READ_SESSION),
    switchMap((action: any): any => {
      return dataService.getAuthData().pipe(
        map((res: any) => {
          return readSessionSucess(res.response);
        }),
        catchError((error) => of(readSessionFailed(error)))
      );
    })
  );
};

export const deleteSessionEpic = (action$: any): any => {
  return action$.pipe(
    ofType(SessionActionTypes.DELETE_SESSION),
    switchMap((action: any): any => {
      return dataService.logout().pipe(
        map((res: any) => {
          sessionStorage?.clear();
          return navigateAction("/login");
        }),
        catchError((error) => of(readSessionFailed(error)))
      );
    })
  );
};
