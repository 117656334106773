import { LinkModel } from "../../interfaces/interfaces";

// Markets URIs
export const marketAndroidURI =
  "https://play.google.com/store/apps/details?id=com.gataca.identity";
export const marketIosURI = "https://apps.apple.com/us/app/gataca/id1498607616";

export const loginLink: LinkModel = {
  label: "login.emailLoginText",
  route: "/email-login",
};
