import React from "react";
import cx from "classnames";
import { IClientModel } from "../../../../../../interfaces/interfaces";
import { getPeriodTranslations } from "../../../../../../utils/licensesUtil";
import { t } from "i18next";

type SubscriptionCellProps = {
  item: IClientModel;
};

export const SubscriptionCell: React.FC<SubscriptionCellProps> = (props) => {
  const { item } = props;

  return (
    <td>
      <span className={cx("buttonMD neutral1000")}>
        {!!item?.plan?.type
          ? item?.plan?.type +
            (item?.plan?.period
              ? " - " + t(getPeriodTranslations(item?.plan?.period))
              : "")
          : "-"}
      </span>
    </td>
  );
};
