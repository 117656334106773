import {
  subscriptionStatusDropdownOptions,
  subscriptionStatusOptions,
} from "../../../data/common";
import { CellContentTypes, TextAlignTypes } from "../../../data/display";

export const clientsScopes = {
  read: ["readProviders"],
  edit: ["updateProviders"],
  create: ["createProviders"],
  delete: ["deleteProviders"],
};

export const propertiesToSearch = [
  {
    text: "orgId",
    searchMultipleLanguages: false,
    latinize: false,
  },
  {
    text: "name",
    searchMultipleLanguages: false,
    latinize: true,
  },
];

export const headersClientsTable = [
  {
    key: "name",
    text: "clients.clientName",
    type: CellContentTypes.text,
  },
  {
    key: "createdAt",
    text: "clients.subscription",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.text,
  },
  {
    key: "status",
    text: "clients.status",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.action,
  },
  {
    key: "signUpDate",
    text: "clients.signUpDate",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.date,
  },
  {
    key: "type",
    text: "clients.renewalDate",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.date,
  },
  {
    key: "type",
    text: "clients.amount",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.number,
  },
  {
    key: "actions",
    text: "general.actions",
    textAlign: TextAlignTypes.right,
    type: CellContentTypes.action,
  },
];

export const extProviderHeadersClientsTable = [
  {
    key: "name",
    text: "clients.clientName",
    type: CellContentTypes.text,
  },
  {
    key: "createdAt",
    text: "clients.subscription",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.text,
  },
  {
    key: "status",
    text: "clients.status",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.action,
  },
  {
    key: "signUpDate",
    text: "clients.signUpDate",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.date,
  },
  {
    key: "type",
    text: "clientDetail.expirationDate",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.date,
  },
  {
    key: "type",
    text: "clients.amount",
    textAlign: TextAlignTypes.center,
    type: CellContentTypes.number,
  },
  {
    key: "actions",
    text: "general.actions",
    textAlign: TextAlignTypes.left,
    type: CellContentTypes.action,
  },
];

export const activeSubscriptionActions = [
  { text: "clients.suspend", value: "SUSPENDED", color: "yellow" },
];

export const suspendedSubscriptionActions = [
  { text: "clients.activate", value: "ISSUED", color: "green" },
];

export const selectPeriodOptions = [
  { text: "clientDetail.monthly", value: "month" },
  { text: "clientDetail.yearly", value: "year" },
  { text: "clientDetail.custom", value: "custom" },
];

export const licensesToSelect = ["Starter", "Essential", "Professional"];

export enum SubscriptionActionsEnum {
  activate = "activate",
  reactivate = "activate",
  change = "change",
  cancel = "cancel",
}

export const subscriptionStatusActions = {
  active: SubscriptionActionsEnum.cancel,
  incomplete: SubscriptionActionsEnum.activate,
  past_due: SubscriptionActionsEnum.activate,
  canceled: SubscriptionActionsEnum.reactivate,
  trialing: SubscriptionActionsEnum.cancel,
  unpaid: SubscriptionActionsEnum.activate,
};

export const subscriptionExpDateColorByStatus = {
  active: "neutral1000",
  incomplete: "neutral1000",
  past_due: "neutral1000",
  incomplete_expired: "alert300",
  canceled: "alert300",
  trialing: "neutral1000",
  unpaid: "neutral1000",
};

export const changeSubscriptionTypeTexts = {
  activate: {
    title: "clients.subscriptionActivation",
    desc1: "clients.subscriptionActivationDesc1",
    desc2: "clients.subscriptionActivationDesc2",
    primaryButtonLabel: "clients.activateSubscription",
  },
  reactivate: {
    title: "clients.subscriptionReactivation",
    desc1: "",
    desc2: "",
    primaryButtonLabel: "clients.reactivateSubscription",
  },
  change: {
    title: "clientDetail.changeSubscription",
    desc1: "",
    desc2: "clients.subscriptionChangeDesc2",
    primaryButtonLabel: "clientDetail.changeSubscription",
  },
  cancel: {
    title: "clientDetail.changeSubscription",
    desc1: "",
    desc2: "clients.subscriptionChangeDesc2",
    primaryButtonLabel: "clientDetail.changeSubscription",
  },
};

// Functions
export const getActionOptions = (currentStatus?: subscriptionStatusOptions) => {
  let options;
  let text;
  let color;
  const status = currentStatus && subscriptionStatusOptions[currentStatus];
  options = status
    ? subscriptionStatusDropdownOptions[status]?.options
    : undefined;
  color = status ? subscriptionStatusDropdownOptions[status]?.color : undefined;
  text = status ? subscriptionStatusDropdownOptions[status]?.text : "";

  return {
    options: options,
    color: color,
    text: text,
  };
};

export const getActionCellContent = (x: any) => {
  if (getActionOptions) {
    return {
      color: getActionOptions(x)?.color || "blue",
      text: getActionOptions(x)?.text || x?.text,
      selectOptions: getActionOptions(x)?.options,
    };
  }
};
