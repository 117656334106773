import React from 'react';
import {useTranslation} from 'react-i18next';
import style from './inputSkeleton.module.scss';

type InputSkeletonProps = {
  labelText?: string,
  required?: boolean,
  className?: string
}

const InputSkeleton: React.FC<InputSkeletonProps> = (props) => {
  const { labelText, required, className } = props
  const { t } = useTranslation();

  return (
    <>
      {labelText ? <label className={`${style.inputSkeleton__label} ${required ? style.inputSkeleton__required : ''}`} >{t(labelText)}</label> : null }
      <span className={`${style.inputSkeleton__span} ${style.skeleton} ${className ? className : ''}`} />
    </>
  
  )
}

export default InputSkeleton
