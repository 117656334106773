import React from "react";

const TrendingDownSVG: React.FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3337 12.8984L9.00033 6.5651L5.66699 9.89844L0.666992 4.89844"
        stroke="currentColor"
        strokeWidth="1.33333"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.333 12.8984H15.333V8.89844"
        stroke="currentColor"
        strokeWidth="1.33333"
        stroke-linecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TrendingDownSVG;
