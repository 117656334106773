import {
  ActionStatusTypes,
  subscriptionStatusOptions,
} from "../../../data/common";
import {
  IClientModel,
  ILicenseItem,
  ITierModel,
  ISubscriptionInfo,
  OrganizationModel,
} from "../../../interfaces/interfaces";
import { RootState } from "../../../store/indexReducers";
import { createSelector } from "reselect";

// ORGANIZATIONS
export const getOrganizations = (state: RootState) =>
  state.organizationsReducer.organizations;
export const getOrgsStatus = (state: RootState) =>
  state.organizationsReducer.loadingOrgsStatus;
export const getSelectedOrganization = (state: RootState) =>
  state.organizationsReducer.selectedOrg;
export const getOrgByIdStatus = (state: RootState) =>
  state.organizationsReducer.loadingOrgByIdStatus;

// LICENSES
export const getLicenses = (state: RootState) => state.licensesReducer.licenses;
export const gettingLicensesStatus = (state: RootState) =>
  state.licensesReducer.loadingLicensesStatus;
export const getSelectedLicenses = (state: RootState) =>
  state.licensesReducer.selectedLicense;
export const gettingLicenseByIdStatus = (state: RootState) =>
  state.licensesReducer.loadingLicenseByIdStatus;
export const getLicenseUpdateStatus = (state: RootState) =>
  state.licensesReducer.updatingLicenseStatus;
export const getSubscriptions = (state: RootState) =>
  state.subscriptionsReducer.subscriptions;
export const gettingSubscriptionStatus = (state: RootState) =>
  state.subscriptionsReducer.loadingSubscriptionsStatus;
export const getTiers = (state: RootState) => state.tiersReducer.tiers;

// CLIENTS
export const getClientsDataStatus = (state: RootState) => {
  return clientsAreLoading([
    state.subscriptionsReducer?.loadingSubscriptionsStatus,
    state.organizationsReducer?.loadingOrgsStatus,
    state.licensesReducer?.loadingLicensesStatus,
  ]);
};

export const getClients = createSelector(
  [getOrganizations, getLicenses, getSubscriptions, getTiers],
  (organizations, licenses, subscriptions, tiers) => {
    return getClientsList(organizations, licenses, subscriptions, tiers);
  }
);

export const getSelectedClient = (state: RootState) =>
  state.clientsReducer.selectedClient;

// FUNCTIONS
const clientsAreLoading = (status: ActionStatusTypes[]) => {
  return !!status?.some((el) => el === ActionStatusTypes?.loading);
};

const getClientsList = (
  organizations: OrganizationModel[],
  licenses?: ILicenseItem[],
  subscriptions?: ISubscriptionInfo[],
  tiers?: ITierModel[]
): IClientModel[] | undefined => {
  let clients;
  if (
    organizations?.length &&
    licenses?.length &&
    subscriptions?.length &&
    tiers?.length
  ) {
    clients = organizations?.map((org) => {
      const subData = getClientData(org, licenses, subscriptions, tiers);
      const { license, subscription, subscriptionInterval, tier } = subData;

      return {
        name: org?.orgInfo?.name || "",
        orgId: org?.id || "",
        plan: {
          type: tier?.name || subscription?.currentTierId,
          period: subscriptionInterval || "",
        },
        org: org,
        license: license,
        subscription: subscription,
        status: subscription?.subscriptionStatus
          ? subscriptionStatusOptions[
              subscription?.subscriptionStatus as subscriptionStatusOptions
            ]
          : undefined,
        amount: getPlanPrice(
          tiers,
          subscription?.currentTierId,
          subscription?.currentPriceId
        ),
        signUpDate: org?.createdAt,
        nextPaymentDate: subscription?.currentPeriodEnd,
      };
    });
  }

  return clients;
};

const getClientData = (
  org: OrganizationModel,
  licenses?: ILicenseItem[],
  subscriptions?: ISubscriptionInfo[],
  tiers?: ITierModel[]
) => {
  const license = licenses?.find((license) => org?.id === license?.orgId);

  const subscription = subscriptions?.find(
    (subscription) => org?.id === subscription?.orgId
  );
  const tier = tiers?.find((tier) => subscription?.currentTierId === tier?.id);
  const subscriptionInterval =
    tier &&
    tier?.prices?.find((el) => {
      return el.id === subscription?.currentPriceId;
    })?.recurringInterval;

  return {
    license,
    subscription,
    subscriptionInterval,
    tier,
  };
};

const getPlanPrice = (
  tiers?: ITierModel[],
  subscriptionPlan?: string,
  currentPriceId?: string
) => {
  const tier = tiers?.find((tier) => tier?.id === subscriptionPlan);
  const priceAmount = tier?.prices?.find(
    (price) => price?.id === currentPriceId
  )?.amount;
  return priceAmount;
};
