import { applyPolyfills, defineCustomElements } from '@gataca/qr/loader'
import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./App";
import { contentSP } from "./data/csp";
import store from "./store/store";

applyPolyfills().then(() => {
    defineCustomElements(window)
})

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <div id="main">
    <Helmet>
      <meta http-equiv="Content-Security-Policy" content={contentSP} /> 
    </Helmet>
    <React.StrictMode>
      <Provider store={store()}>
        <App />
      </Provider>
    </React.StrictMode>{" "}
  </div>
);
