import { expiredSessionNotification } from './notificationConstants'
import {
    INotificationState,
    NotificationActions,
    NotificationActionTypes,
} from './notificationTypes'

const sessionInitialState: INotificationState = {
    notification: undefined,
}

export const notificationReducer = (
    state = sessionInitialState,
    action: NotificationActions
) => {
    switch (action.type) {
        case NotificationActionTypes.NOTIFICATION_SHOW:
            const tokenExpired = action.payload.notification?.status === 401
            const { title, kind, subtitle, duration } =
                expiredSessionNotification
            return {
                ...state,
                notification: !tokenExpired
                    ? action.payload.notification
                    : { title, kind, subtitle, duration },
            }
        case NotificationActionTypes.NOTIFICATION_HIDE:
            return {
                ...state,
                notification: undefined,
            }
        default:
            return state
    }
}
