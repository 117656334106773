export interface INotificationItem {
    title: string
    kind:
        | 'error'
        | 'info'
        | 'info-square'
        | 'success'
        | 'warning'
        | 'warning-alt'
    subtitle: string
    status?: number
    duration?: number
}

export enum NotificationActionTypes {
    NOTIFICATION_SHOW = 'notifications/show',
    NOTIFICATION_HIDE = 'notifications/hide',
}

export interface IShowNotificationAction {
    type: NotificationActionTypes.NOTIFICATION_SHOW
    payload: {
        notification: INotificationItem
    }
}

export interface IHideNotificationAction {
    type: NotificationActionTypes.NOTIFICATION_HIDE
}

export interface INotificationState {
    notification?: INotificationItem
}

export type NotificationActions =
    | IShowNotificationAction
    | IHideNotificationAction
