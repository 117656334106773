import cx from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import LightPanel from "../../../../../../components/elements/panels/LightPanel/LightPanel";
import styles from "./subscriptionAndPayment.module.scss";
import {
  IClientModel,
  ITierModel,
} from "../../../../../../interfaces/interfaces";
import Moment from "react-moment";
import PurpleButton from "../../../../../../components/atoms/buttons/purple/PurpleButton";
import { showScreenLoaderAction } from "../../../../../Loader/loaderActions";
import {
  deleteOrgAction,
  suspendSubscriptionAction,
} from "../../../../store/clientsActions";
import { ButtonSizes, gaColors } from "../../../../../../data/display";
import RedButton from "../../../../../../components/atoms/buttons/red/RedButton";
import {
  filterTiersByProvider,
  getPeriodTranslations,
  getSubscriptionAction,
} from "../../../../../../utils/licensesUtil";
import GaTag from "../../../../../../components/atoms/chips/Tag";
import {
  SubscriptionActionsEnum,
  changeSubscriptionTypeTexts,
  getActionCellContent,
  getActionOptions,
  subscriptionStatusActions,
} from "../../../../data/clients.constants";
import { sessionSelectors } from "../../../../../Settings/Profile/store";
import BlackButton from "../../../../../../components/atoms/buttons/black/BlackButton";
import SkullIcon from "../../../../../../assets/iconsLibrary/simple/skull/skullIcon";
import Modals from "./modals/Modals";
import ActionPanel from "../../../../../../components/elements/panels/actionPanel/ActionPanel";
import moment from "moment";

type ISubscriptionAndPaymentProps = {
  client?: IClientModel;
  tiers?: ITierModel[];
};

const SubscriptionAndPayment: React.FC<ISubscriptionAndPaymentProps> = (
  props
) => {
  const { client, tiers } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // States
  const [showSuspendWarning, setShowSuspendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChangeSubscriptionModal, setShowChangeSubscriptionModal] =
    useState(false);

  // Selectors
  const userTenant = useSelector(sessionSelectors?.getUserTenant);

  // Functions
  const suspendOrg = (orgId: string) => {
    dispatch(showScreenLoaderAction());
    dispatch(suspendSubscriptionAction(orgId));
  };

  const deleteOrg = () => {
    dispatch(showScreenLoaderAction());
    dispatch(deleteOrgAction(client?.orgId || ""));
  };

  function getUpcomingAmount() {
    const currentTierId = client?.subscription?.currentTierId;
    const priceId = client?.subscription?.currentPriceId;
    const providerTiers = filterTiersByProvider(
      client?.org?.providerId || "",
      tiers
    );
    const clientProduct = providerTiers?.find(
      (product) => currentTierId && product?.id === currentTierId
    );
    const currentProductPrice = clientProduct?.prices?.find(
      (price) => priceId && price?.id === priceId
    );
    const currentProductAmount = currentProductPrice?.amount;
    return currentProductAmount ? currentProductAmount / 100 + "€" : undefined;
  }

  // Variables
  const actionType = getSubscriptionAction(client?.status);

  const pendingToCancelSub = client?.subscription?.cancelAtPeriodEnd;

  const subIsActive =
    client?.status &&
    subscriptionStatusActions[
      client?.status as keyof typeof subscriptionStatusActions
    ] === SubscriptionActionsEnum.cancel;

  const firstButtonLabel =
    changeSubscriptionTypeTexts[
      actionType as keyof typeof changeSubscriptionTypeTexts
    ]?.primaryButtonLabel;

  const suspendModalButtons = {
    primary: { label: "general.yesCancel", action: suspendOrg },
    secondary: {
      label: "general.noGoBack",
      action: () => {
        setShowSuspendModal(false);
      },
    },
  };

  const changeSubModalButtons = {
    primary: {
      label: "clientDetail.changeSubscription",
    },
    secondary: {
      label: "general.cancel",
      action: () => {
        setShowChangeSubscriptionModal(false);
      },
    },
  };

  const deleteModalButtons = {
    primary: { label: "clients.yesKillNow", action: deleteOrg },
    secondary: {
      label: "general.noGoBack",
      action: () => {
        setShowDeleteModal(false);
      },
    },
  };

  // View
  return (
    <React.Fragment>
      <div className={styles.panels}>
        {/* TODO DEV-3869  */}
        {/* {subIsActive && pendingToCancelSub ? (
          <ActionPanel
            className={`${cx("padding32", styles.warningPanelContainer)}`}
            title={"platformConfig.tierDetail.pendingVerification"}
            label={t("platformConfig.tierDetail.changesMade")}
            hasTitle={true}
            labelExtraTranslationParams={{
              date: moment(client?.nextPaymentDate)?.format("DD/MM/YYYY"),
            }}
            color={yellow}
            rightButton={{
              label: t("clients.reactivate"),
              action: () => console.log("maintain subscription"),
            }}
          />
        ) : null} */}
        <LightPanel className={styles.planPanel}>
          <div className={styles.planPanel__header}>
            {client?.plan?.type ? (
              <p className={cx("heading6 neutral1000")}>
                {" "}
                {t("clientDetail.currentPlan")}:{" "}
                <span className={cx("primary700")}>{client?.plan?.type}</span>
              </p>
            ) : (
              <p className={cx("heading6 neutral1000")}>
                {" "}
                {t("clientDetail.clientWithoutSubscription")}
              </p>
            )}

            {client?.status ? (
              <GaTag
                color={getActionCellContent(client?.status)?.color || "blue"}
                text={getActionOptions(client?.status)?.text || status || ""}
              />
            ) : null}
          </div>

          <div className={styles.dataPanelsContainer}>
            <LightPanel className={styles.dataPanel}>
              <p className={cx("buttonMD")}>{t("clients.signUpDate")}</p>
              <Moment
                className={cx("bodyRegularSM neutral800")}
                format="DD/MM/YYYY">
                {client?.signUpDate}
              </Moment>
            </LightPanel>
            {client?.nextPaymentDate ? (
              <LightPanel className={styles.dataPanel}>
                <p className={cx("buttonMD")}>{t("clients.nextPayment")}</p>
                <Moment
                  className={cx("bodyRegularSM neutral800")}
                  format="DD/MM/YYYY">
                  {client?.nextPaymentDate}
                </Moment>
              </LightPanel>
            ) : null}
            {client?.plan?.period ? (
              <LightPanel className={styles.dataPanel}>
                <p className={cx("buttonMD")}>{t("clientDetail.billed")}</p>
                <p>{t(getPeriodTranslations(client?.plan?.period))}</p>
              </LightPanel>
            ) : null}
            {!!getUpcomingAmount() ? (
              <LightPanel className={styles.dataPanel}>
                <p className={cx("buttonMD")}>
                  {t("clientDetail.upcomingAmount")}
                </p>
                {getUpcomingAmount()}
              </LightPanel>
            ) : null}
          </div>

          {client?.status ? (
            <>
              {userTenant !== "gataca" ? (
                <div className={styles.buttonContainer}>
                  <PurpleButton
                    size={ButtonSizes.MD}
                    label={t(firstButtonLabel)}
                    action={() => setShowChangeSubscriptionModal(true)}
                  />
                  {/* TODO DEV-3869  */}
                  {/* {subIsActive && !pendingToCancelSub ? (
                    <RedButton
                      size={ButtonSizes.MD}
                      outlined
                      label={t("clientDetail.cancelSubscription")}
                      action={() => setShowSuspendModal(true)}
                    />
                  ) : null} */}
                </div>
              ) : (
                <div className={styles.buttonContainer}>
                  <BlackButton
                    size={ButtonSizes.MD}
                    label={t("clients.killAccount")}
                    IconComponent={
                      <SkullIcon size={24} color={gaColors.neutral100} />
                    }
                    action={() => setShowDeleteModal(true)}
                  />
                </div>
              )}{" "}
            </>
          ) : null}
        </LightPanel>
      </div>

      <Modals
        showSuspendWarning={showSuspendWarning}
        selectedClient={client}
        suspendModalButtons={suspendModalButtons}
        selectedOrgId={client?.orgId}
        deleteModalButtons={deleteModalButtons}
        tiers={tiers}
        notActivating={!!subIsActive}
        actionType={actionType}
        changeSubModalButtons={changeSubModalButtons}
        showChangeSubscriptionModal={showChangeSubscriptionModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        setShowSuspendModal={setShowSuspendModal}
        setShowChangeSubscriptionModal={setShowChangeSubscriptionModal}
      />
    </React.Fragment>
  );
};

SubscriptionAndPayment.defaultProps = {};

export default SubscriptionAndPayment;
