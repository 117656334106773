import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { gaColors } from "../../../data/display";
import { gaImages } from "../../../data/images";
import { app, getHost } from "../../../services/dataService";
import { setSession } from "../../Settings/Profile/store/sessionActions";
import { loginFailed } from "../views/Login/store/loginAction";
import { HTMLStencilElement } from "@gataca/qr/dist/types/stencil-public-runtime";
import { Components } from "@gataca/qr/dist/types/components";
import jwt_decode from "jwt-decode";
import { userHasScopes } from "../../../utils/permissionsUtil";
import { showNotification } from "../../Notification/store/notificationActions";

export enum RESULT_STATUS {
  ONGOING = 0,
  SUCCESS = 1,
  FAILED = 2,
  NOT_STARTED,
}

type LoginQRProps = {
  stop?: (x?: boolean) => void;
  notifyNoPermissionsAndLogout: () => void;
  qrColor?: string;
  logoSrc?: string;
};

interface HTMLGatacaQrElement extends Components.GatacaQr, HTMLStencilElement {}
var HTMLGatacaQrElement: {
  prototype: HTMLGatacaQrElement;
  new (): HTMLGatacaQrElement;
};

export const LoginQR: React.FC<LoginQRProps> = (props: LoginQRProps) => {
  const { qrColor, logoSrc, stop, notifyNoPermissionsAndLogout } = props;
  const { t } = useTranslation();
  const qr = useRef(null);
  const dispatch = useDispatch();
  let connectToken: string | null;
  let token: string;
  let data: any;
  let sessionId: string;
  let signup: boolean;
  let displaying: boolean = false;

  const userCanReadProviders = (allowedScopes?: string[]) =>
    allowedScopes?.length && userHasScopes(["readProviders"], allowedScopes);

  const gatacaLoginSuccess = (newdata: any) => {
    //@ts-ignore
    qr?.current?.stop();
    dispatch(setSession(signup)); //Set Session will redirect to welcome if signup
  };

  const gatacaLoginError = (error: any) => {
    //@ts-ignore
    qr?.current?.stop();
    dispatch(loginFailed(error));
  };

  const createSessionId = async (): Promise<{
    sessionId: string;
    authenticationRequest?: string;
  }> => {
    let endpoint = getHost(app?.nucleus) + "/admin/v1/users/login/request";
    let response = await fetch(endpoint);
    let data = await response.json();
    connectToken = data.connect_token;
    sessionId = data.session_id;
    return {
      sessionId: sessionId,
      authenticationRequest: data.authentication_request,
    };
  };

  const checkStatus = async (): Promise<{
    result: RESULT_STATUS;
    data?: any;
  }> => {
    let endpoint = getHost(app.nucleus) + "/admin/v1/users/login/gataca";
    let response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "session-id": sessionId,
      },
      body: "{}",
    });
    switch (response.status) {
      case 200:
        token = response.headers.get("token") || "";
        data = (await response.json()).data;

        const decToken = jwt_decode(token) as any;
        const allowedScopes = decToken?.permissions?.scopes;

        !userCanReadProviders(allowedScopes) &&
          dispatch(showNotification("login.noAccessPermissions", "error", ""));
        return !!userCanReadProviders(allowedScopes)
          ? {
              result: RESULT_STATUS.SUCCESS,
              data: { token: token, data: data },
            }
          : {
              result: RESULT_STATUS.FAILED,
            };
      case 201:
        notifyNoPermissionsAndLogout();
        return {
          result: RESULT_STATUS.FAILED,
        };
      case 202:
        return { result: RESULT_STATUS.ONGOING };
      case 403:
        return { result: RESULT_STATUS.FAILED };
      default:
        return { result: RESULT_STATUS.FAILED };
    }
  };

  useEffect(() => {
    if (qr !== null && qr.current !== null && !displaying) {
      let gqr: HTMLGatacaQrElement = qr.current! as HTMLGatacaQrElement;
      displaying = true;

      gqr.qrModalTitle = t("login.qrTitle");
      gqr.qrModalDescription = t("login.qrAuxText");
      gqr.createSession = createSessionId;
      gqr.checkStatus = checkStatus;
      gqr.successCallback = gatacaLoginSuccess;
      gqr.errorCallback = gatacaLoginError;
    }
  });

  return (
    <>
      <section className="login-page">
        <div className="login-module">
          <div className="gatacaLogin-form-container">
            {
              // @ts-ignore
              <gataca-qr
                callback-server={process.env.REACT_APP_CONNECT_HOST}
                qr-role="connect"
                qr-modal-title={t("login.fastAccess")}
                hide-brand-title={true}
                ref={qr}
                logo-size="0.33"
                logo-src={gaImages?.logoGataca?.src}
                qr-code-expired-label={t("login.qrExpired")}
                credentials-not-validated-label={t(
                  "login.userCredNotValidated"
                )}
                click-inside-box-label={t("login.clickInsideBoxLabel")}
                refresh-qr-label={t("login.refreshQrLabel")}
                scan-qr-label={t("login.scanQrLabel")}
                user-not-scan-in-time-error-label={t(
                  "login.userNotScanInTimeErrorLabel"
                )}
                creds-not-validated-error-label={t(
                  "login.credsNotValidatedErrorLabel"
                )}
                modal-title-color={gaColors?.primary700}
                failed-login-error-label={t("login.failedLoginErrorLabel")}
                success-login-label={t("login.successLoginLabel")}
                by-brand-label={t("login.byBrandLabel", {
                  brand: "Gataca",
                })}
                waiting-start-session-label={t(
                  "login.waitingStartSessionLabel"
                )}
                hide-qr-modal-description="true"
                polling-frequency={3}
                session-timeout={175}
                v-2={process.env.REACT_APP_USE_V2}
              />
            }
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginQR;
