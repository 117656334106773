import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./tierDetail.module.scss";
import LightPanel from "../../../../components/elements/panels/LightPanel/LightPanel";
import cx from "classnames";
import PanelScafoldingPrivate from "../../../PanelScafolding/components/private/PanelScafoldingPrivate";
import InfoPanel from "../../../../components/elements/panels/infoPanel/infoPanel";
import WarningIcon from "../../../../assets/iconsLibrary/simple/warning/warningIcon";
import { ButtonSizes, gaColors } from "../../../../data/display";
import PurpleButton from "../../../../components/atoms/buttons/purple/PurpleButton";
import PreviewLicenseCard from "../../../../components/elements/cards/licenseCard/preview/PreviewLicenseCard";
import { useDispatch, useSelector } from "react-redux";
import { sessionSelectors } from "../../../Settings/Profile/store";
import ActionPanel from "../../../../components/elements/panels/actionPanel/ActionPanel";
import WarningModal from "../../../../components/elements/modals/warninModal/WarningModal";
import { tiersActions, tiersSelectors } from "../../store";
import {
  getTierLimits,
  getTierPriceAmount,
  getTierActivePriceMonthly,
  getTierActivePriceYearly,
  getTierMonthlyPrices,
  getTierYearlyPrices,
  modifyTiersIndexEditing,
} from "../../../../utils/licensesUtil";
import GeneralTierForm from "./forms/general/GeneralTierForm";
import TierLimitsForm from "./forms/limits/TierLimitsForm";
import { IPriceModel, ITierModel } from "../../../../interfaces/interfaces";
import dataService from "../../../../services/dataService";
import { showScreenLoaderAction } from "../../../Loader/loaderActions";

type ITierDetailProps = {};

const TierDetail: React.FC<ITierDetailProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const tier = useSelector(tiersSelectors.getSelectedTier);
  const tiers = useSelector(tiersSelectors.getSortedTiers);
  const provider = useSelector(sessionSelectors.getTokenData)?.currentTenant;

  // Functions
  const deleteTier = () => {
    dispatch(showScreenLoaderAction());
    dispatch(tiersActions.deleteTier(tier?.id || "", provider || ""));
  };

  // Variables
  const canDeleteTier = tiers && tiers?.length > 2;
  const isEditing = tier?.id;
  const currentTierPrices = tier?.prices;
  const tierMonthlyTrialDays =
    getTierActivePriceMonthly(currentTierPrices)?.numTrialDays;
  const tierYearlyTrialDays =
    getTierActivePriceYearly(currentTierPrices)?.numTrialDays;
  const tierPriceMonthly = getTierActivePriceMonthly(currentTierPrices);
  const tierPriceMonthlyFormatted = getTierPriceAmount(tierPriceMonthly);
  const tierPriceYearly = getTierActivePriceYearly(currentTierPrices);
  const tierPriceYearlyFormatted = getTierPriceAmount(tierPriceYearly);

  const deleteModalButtons = {
    primary: { label: "general.delete", action: deleteTier },
    secondary: {
      label: "general.noGoBack",
      action: () => {
        setShowDeleteModal(false);
      },
    },
  };

  // States
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);

  const [formData, setFormState] = useState({
    name: tier?.name || "",
    description: tier?.description || "",
    isPopular: tier?.isPopular || false,
    monthlyHasTrialDays: !!tierMonthlyTrialDays || false,
    yearlyHasTrialDays: !!tierYearlyTrialDays || false,
    priceMonthly: tierPriceMonthlyFormatted || "",
    monthlyNumTrialDays: tierMonthlyTrialDays?.toString() || "0",
    yearlyNumTrialDays: tierYearlyTrialDays?.toString() || "0",
    ebsiDids: tier?.ebsiDids || false,
    priceYearly: tierPriceYearlyFormatted || "",
    index: tier?.index?.toString() || "0",
    yearlyDiscountPercentage: 0,
  });

  const [limits, setLimits] = useState(getTierLimits(tier));

  const {
    issuanceTemplates,
    issuedCredentials,
    apiKeys,
    activeUsers,
    verificationTemplates,
    dids,
  } = limits;

  const {
    name,
    description,
    priceMonthly,
    priceYearly,
    ebsiDids,
    monthlyHasTrialDays,
    yearlyHasTrialDays,
    monthlyNumTrialDays,
    yearlyNumTrialDays,
    isPopular,
    index,
    yearlyDiscountPercentage,
  } = formData;

  const validForm = !!(
    name &&
    priceMonthly &&
    priceYearly &&
    issuedCredentials &&
    issuanceTemplates &&
    activeUsers &&
    verificationTemplates &&
    apiKeys &&
    dids
  );

  const previewMonthPrice = {
    id: tierPriceMonthly?.id || "",
    name: tierPriceMonthly?.name || "",
    currency: tierPriceMonthly?.currency || "eur",
    recurringInterval: "month",
    amount: priceMonthly ? parseInt(priceMonthly) : 0,
    type: tierPriceMonthly?.type || "recurring",
    numTrialDays: monthlyNumTrialDays || 0,
    active: true,
  };

  const previewYearPrice = {
    id: tierPriceYearly?.id || "",
    name: tierPriceYearly?.name || "",
    currency: tierPriceYearly?.currency || "eur",
    recurringInterval: "year",
    amount: priceYearly ? parseInt(priceYearly) : 0,
    type: tierPriceYearly?.type || "recurring",
    numTrialDays: yearlyNumTrialDays || 0,
    active: true,
  };

  const getFormattedLimits = () => {
    const limitArr = new Array(
      limits.verificationTemplates,
      limits.activeUsers,
      limits.issuedCredentials,
      limits.issuanceTemplates,
      limits.apiKeys,
      limits.dids
    );

    return limitArr;
  };

  const getPreviewLicenseData = {
    name: name || t("platformConfig.tierDetail.prevName"),
    description: description || t("platformConfig.tierDetail.prevDesc"),
    prices: [previewMonthPrice, previewYearPrice],
    provider: provider || "",
    isPopular: isPopular,
    ebsiDids: ebsiDids,
    index: 0,
    limits: getFormattedLimits(),
  };

  const createTier = () => {
    dispatch(showScreenLoaderAction());
    dispatch(tiersActions.createTier(formattedTier));
  };

  const tiersToChangeOrder = modifyTiersIndexEditing(
    index,
    tier?.index || 0,
    tier,
    tiers,
    !isEditing
  );

  const updateTier = () => {
    dispatch(showScreenLoaderAction());
    dispatch(tiersActions.updateTier(formattedTier));

    const tiersToChangeOrder = modifyTiersIndexEditing(
      index,
      tier?.index || 0,
      tier,
      tiers,
      !isEditing
    );
    tiersToChangeOrder?.map((tier) => {
      dispatch(tiersActions.updateTier(tier));
      updateOtherTiersOrder(tier, tier?.id);
    });
  };

  let tiersWithChangedIndex = 0;

  const updateOtherTiersOrder = async (tier: ITierModel, did: string) => {
    let updatingApiKeys = false;

    if (!updatingApiKeys) {
      updatingApiKeys = true;

      while (
        tiersToChangeOrder &&
        tiersWithChangedIndex < tiersToChangeOrder?.length
      ) {
        tiersWithChangedIndex = tiersWithChangedIndex + 1;
        const callDone = await dataService.updateTier(tier, tier?.id || "");

        if (callDone !== undefined) {
          updatingApiKeys = false;
        }
      }
    }
  };

  const getTierPrices = () => {
    const tierPrices = getNewPrices();

    const currentPrices = tier?.prices;
    const updatedPrices = new Array();

    const updatedMonthlyPrice = tierPrices?.find(
      (price) => price?.recurringInterval === "month"
    );
    const updatedYearlyPrice = tierPrices?.find(
      (price) => price?.recurringInterval === "year"
    );

    const updatedMonthlyAmount = updatedMonthlyPrice?.amount;
    const updatedYearlyAmount = updatedYearlyPrice?.amount;
    const currentMonthlyAmount = tierPriceMonthly?.amount;
    const currentYearlyAmount = tierPriceYearly?.amount;
    const updatedMonthlyTrialDays = updatedMonthlyPrice?.numTrialDays;
    const updatedYearlyTrialDays = updatedYearlyPrice?.numTrialDays;
    const monthlyPrices = getTierMonthlyPrices(currentTierPrices);
    const yearlyPrices = getTierYearlyPrices(currentTierPrices);

    const monthlyPricesClone = [...(monthlyPrices || [])];
    const yearlyPricesClone = [...(yearlyPrices || [])];

    if (currentPrices?.length && isEditing) {
      const oldPriceYearly = { ...tierPriceYearly };
      const oldPriceMonthly = { ...tierPriceMonthly };

      const mustModifyMonthly =
        !!oldPriceMonthly &&
        (currentMonthlyAmount !== updatedMonthlyAmount ||
          tierMonthlyTrialDays !== updatedMonthlyTrialDays);

      const mustModifyYearly =
        !!oldPriceYearly &&
        (currentYearlyAmount !== updatedYearlyAmount ||
          tierYearlyTrialDays !== updatedYearlyTrialDays);

      monthlyPricesClone?.map((price) => {
        const priceClone = { ...price };
        priceClone.active = mustModifyMonthly ? false : price?.active;

        updatedPrices?.push(priceClone);
      });

      yearlyPricesClone?.map((price) => {
        const priceClone = { ...price };
        priceClone.active = mustModifyMonthly ? false : price?.active;

        updatedPrices?.push(priceClone);
      });

      if (!!mustModifyMonthly || !!mustModifyYearly) {
        tierPrices?.map((price) => {
          updatedPrices?.push(price);
        });
      }
    }

    return isEditing ? updatedPrices : tierPrices;
  };

  const getNewPrices = () => {
    const tierPrices: IPriceModel[] = [
      {
        ...previewMonthPrice,
        id: undefined,
        amount: parseInt(priceMonthly) * 100,
        active: true,
        numTrialDays:
          monthlyNumTrialDays && typeof monthlyNumTrialDays === "string"
            ? parseInt(monthlyNumTrialDays)
            : 0,
      },
      {
        ...previewYearPrice,
        id: undefined,
        active: true,
        amount: parseInt(priceYearly) * 100,
        numTrialDays:
          yearlyNumTrialDays && typeof yearlyNumTrialDays === "string"
            ? parseInt(yearlyNumTrialDays)
            : 0,
      },
    ];

    return tierPrices;
  };

  const formattedTier = {
    id: tier?.id || undefined,
    name: name,
    description: description,
    prices: getTierPrices(),
    provider: tier?.provider || provider,
    isPopular: isPopular,
    ebsiDids: ebsiDids,
    index: parseInt(index),
    limits: getFormattedLimits(),
  };

  return (
    <PanelScafoldingPrivate hideNavBar={true}>
      <div className={styles.viewContainer}>
        <div className={styles.tierDetail}>
          <div>
            <p className={cx("heading4")}>
              {t("platformConfig.tierDetail.createTitle")}
            </p>
            <p className={cx("bodyRegularLG")}>
              {t("platformConfig.tierDetail.createDesc")}
            </p>
          </div>
          <InfoPanel
            className={styles.infoPanel}
            iconComponent={
              <WarningIcon size={24} color={gaColors?.warning400} />
            }
            label={
              <p className={cx("bodyRegularSM")}>
                <Trans i18nKey={"platformConfig.tierDetail.infoPanel"} />
              </p>
            }
            hasIcon={true}
          />
          <GeneralTierForm formData={formData} setFormState={setFormState} />
          <TierLimitsForm
            formData={formData}
            setFormState={setFormState}
            limits={limits}
            setLimits={setLimits}
          />

          {!isEditing ? (
            <PurpleButton
              size={ButtonSizes.MD}
              className={styles.modal__continue}
              label={t("general.create")}
              disabled={!validForm}
              action={createTier}
            />
          ) : null}
          {isEditing ? (
            <PurpleButton
              size={ButtonSizes.MD}
              className={styles.saveMobileButton}
              label={t("general.save")}
              disabled={!validForm}
              action={updateTier}
            />
          ) : null}
          {canDeleteTier && isEditing ? (
            <ActionPanel
              color={"red"}
              className={`${cx("padding32", styles.warningPanelContainer)}`}
              title={"platformConfig.tierDetail.deleteTier"}
              label={t("platformConfig.tierDetail.deleteTierSub")}
              hasTitle={true}
              rightButton={{
                label: t("platformConfig.tierDetail.deleteTier"),
                action: () => setShowDeleteModal(true),
              }}
            />
          ) : null}
        </div>
        <div className={styles.tierPreviewContainer}>
          <LightPanel className={styles.tierPreview}>
            <div className={styles.tierPreview__header}>
              <p className={cx("heading6")}>
                {t("platformConfig.tierDetail.subsPreview")}
              </p>
              <p className={cx("bodyRegularXM marginTop4 neutral700")}>
                {t("platformConfig.tierDetail.subsPreviewDesc")}
              </p>
            </div>

            <PreviewLicenseCard
              license={getPreviewLicenseData}
              period={"month"}></PreviewLicenseCard>
            {isEditing ? (
              <PurpleButton
                size={ButtonSizes.MD}
                className={styles.modal__continue}
                label={t("general.save")}
                disabled={!validForm}
                action={updateTier}
              />
            ) : null}
          </LightPanel>
        </div>
        {showDeleteModal ? (
          <WarningModal
            title={"platformConfig.tierDetail.sureDeteleTier"}
            description={"platformConfig.tierDetail.sureDeteleTierDesc"}
            primaryButton={deleteModalButtons.primary}
            secondaryButton={deleteModalButtons.secondary}
            hideModal={() => setShowDeleteModal(false)}
            item={tier}
          />
        ) : null}
      </div>
    </PanelScafoldingPrivate>
  );
};

export default TierDetail;
