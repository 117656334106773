import * as React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import styles from "./metricsSubBlocks.module.scss";

import {
  formatNumberToCmpct,
  formatNumberToEU,
} from "../../../../../../../../utils/formatDataUtil";
import CardListSkeleton from "../../../../../../../../components/elements/skeletons/cardListSkeleton/CardListSkeleton";
import { formatNumbersDefaultOptions } from "../../../../../../../../data/common";

export type IMetricsSubBlocksProps = {
  statsNumberApi?: {
    activeUsers: number;
    apiKeys: number;
    dids: number;
    issuanceTemplates: number;
    issuedCredentials: number;
    verifierTemplates: number;
  };
  subBlocksData: {
    id: string;
    title: string;
    statsNumber?: number;
  }[];
  dataLoading?: boolean;
};

const MetricsSubBlocks: React.FC<IMetricsSubBlocksProps> = (props) => {
  const { subBlocksData, statsNumberApi, dataLoading } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.metricsSubBlocks}>
      <p className={cx("heading6", "margin-bottom")}>
        {t("dashboard.allTotals")}
      </p>
      <div className={styles.metricsSubBlocks__container}>
        {!dataLoading ? (
          statsNumberApi &&
          subBlocksData?.map((el, index) => {
            const { id, title } = el;

            const number =
              (statsNumberApi &&
                statsNumberApi[id as keyof typeof statsNumberApi]) ||
              0;

            return (
              <div
                key={"subBlock__" + index}
                id={id}
                className={styles.metricsSubBlocks__each}
              >
                <div className={styles.metricsSubBlocks__each_container}>
                  {statsNumberApi && (
                    <div
                      className={`${styles.metricsSubBlocks__each_number} ${cx(
                        "heading5"
                      )}`}
                      aria-label={
                        number >= 1000 ? formatNumberToEU(number) : ""
                      }
                      aria-hidden={number >= 1000 ? false : true}
                      tooltip-position="top"
                    >
                      {formatNumberToCmpct(number, formatNumbersDefaultOptions)}
                    </div>
                  )}
                  <div
                    className={`${styles.metricsSubBlocks__each_title} ${cx(
                      "bodyRegularXS"
                    )}`}
                  >
                    {t(title)}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <CardListSkeleton
            cardsNumber={6}
            className={`${styles.metricsSubBlocks__each}`}
          />
        )}
      </div>
    </div>
  );
};

export default MetricsSubBlocks;
