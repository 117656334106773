import React from "react";
import styles from "./actionsCell.module.scss";
import { gaImages } from "../../../../../../data/images";
import { IClientModel } from "../../../../../../interfaces/interfaces";
import DropdownActions from "./dropdownActions/DropdownActions";

type ActionCellProps = {
  item: IClientModel;
  canDelete?: boolean;
  canEdit?: boolean;
  deleteItem?: (e?: any) => void;
  editItem?: (e?: any) => void;
};

export const ActionCell: React.FC<ActionCellProps> = (props) => {
  const { item, canDelete, canEdit, deleteItem, editItem } = props;

  return canEdit || canDelete ? (
    <td>
      <div className={styles.actions}>
        <DropdownActions
          item={item}
          icon={gaImages.moreVerticalIcon}
          iconClassName={styles.action__icon}
          className={styles.action__iconContainer}
          deleteItem={(item) =>
            item?.name !== "Gataca" && deleteItem && canDelete
              ? deleteItem(item)
              : undefined
          }
          seeDetails={canEdit ? editItem : undefined}
        />
      </div>
    </td>
  ) : null;
};
