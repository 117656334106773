import { ActionStatusTypes } from "../../../data/common";
import {
  IChangeSubData,
  IClientModel,
  ILicenseItem,
  ISubscriptionInfo,
  OrganizationModel,
} from "../../../interfaces/interfaces";

// Organizations
export interface IGetOrganizations {
  type: OrganizationsActionTypes.GET_ORGANIZATIONS;
}

export interface IGetOrganizationsSuccess {
  type: OrganizationsActionTypes.GET_ORGANIZATIONS_SUCCESS;
  payload: {
    organizations: OrganizationModel[];
  };
}

export interface IGetOrganizationsFailed {
  type: OrganizationsActionTypes.GET_ORGANIZATIONS_FAILED;
  error: string;
}

export interface IGetOrganizationById {
  type: OrganizationsActionTypes.GET_ORG_BY_ID;
  payload: {
    id: string;
  };
}

export interface IGetOrganizationByIdSuccess {
  type: OrganizationsActionTypes.GET_ORG_BY_ID_SUCCESS;
  payload: {
    org: OrganizationModel;
  };
}

export interface IGetOrganizationByIdFailed {
  type: OrganizationsActionTypes.GET_ORG_BY_ID_FAILED;
  error: string;
}

export interface IDeleteOrg {
  type: OrganizationsActionTypes.DELETE_ORG;
  payload: {
    id: string;
  };
}

export interface IDeleteOrgSuccess {
  type: OrganizationsActionTypes.DELETE_ORG_SUCCESS;
}

export interface IDeleteOrgFailed {
  type: OrganizationsActionTypes.DELETE_ORG_FAILED;
  error: string;
}

export interface IUpdateOrg {
  type: OrganizationsActionTypes.UPDATE_ORG;
  payload: {
    org: OrganizationModel;
  };
}

export interface IUpdateOrgSuccess {
  type: OrganizationsActionTypes.UPDATE_ORG_SUCCESS;
}

export interface IUpdateOrgFailed {
  type: OrganizationsActionTypes.UPDATE_ORG_FAILED;
  error: string;
}

export interface ISetSelectedOrg {
  type: OrganizationsActionTypes.SET_SELECTED_ORG;
  payload: { org?: OrganizationModel };
}

export type OrganizationsAction =
  | IGetOrganizations
  | IGetOrganizationsSuccess
  | IGetOrganizationsFailed
  | IGetOrganizationById
  | IGetOrganizationByIdSuccess
  | IGetOrganizationByIdFailed
  | ISetSelectedOrg
  | IDeleteOrg
  | IDeleteOrgSuccess
  | IDeleteOrgFailed
  | IUpdateOrg
  | IUpdateOrgSuccess
  | IUpdateOrgFailed;

export interface OrganizationsState {
  organizations: OrganizationModel[];
  loadingOrgsStatus: ActionStatusTypes;
  selectedOrg?: OrganizationModel;
  showOrgDetail: boolean;
  loadingOrgByIdStatus: ActionStatusTypes;
  deletingOrgByIdStatus: ActionStatusTypes;
  updatingOrgByIdStatus: ActionStatusTypes;
}

export enum OrganizationsActionTypes {
  GET_ORGANIZATIONS = "clients/get_organizations",
  GET_ORGANIZATIONS_SUCCESS = "clients/get_organizations_success",
  GET_ORGANIZATIONS_FAILED = "clients/get_organizations_failed",
  GET_ORG_BY_ID = "clients/get_org_by_id",
  GET_ORG_BY_ID_SUCCESS = "clients/get_org_by_id_success",
  GET_ORG_BY_ID_FAILED = "clients/get_org_by_id_failed",
  SET_SELECTED_ORG = "clients/set_selected_org",
  DELETE_ORG = "clients/delete_org",
  DELETE_ORG_SUCCESS = "clients/delete_org_success",
  DELETE_ORG_FAILED = "clients/delete_org_failed",
  UPDATE_ORG = "clients/update_org",
  UPDATE_ORG_SUCCESS = "clients/update_org_success",
  UPDATE_ORG_FAILED = "clients/update_org_failed",
}

// LICENSES
export interface IGetLicenses {
  type: LicensesActionTypes.GET_LICENSES;
}

export interface IGetLicensesSuccess {
  type: LicensesActionTypes.GET_LICENSES_SUCCESS;
  payload: {
    licenses: ILicenseItem[];
  };
}

export interface IGetLicensesFailed {
  type: LicensesActionTypes.GET_LICENSES_FAILED;
  error: string;
}

export interface IGetLicenseById {
  type: LicensesActionTypes.GET_LICENSE_BY_ID;
  payload: {
    id: string;
  };
}

export interface IGetLicenseByIdSuccess {
  type: LicensesActionTypes.GET_LICENSE_BY_ID_SUCCESS;
  payload: {
    license: ILicenseItem;
  };
}

export interface IGetLicenseByIdFailed {
  type: LicensesActionTypes.GET_LICENSE_BY_ID_FAILED;
  error: string;
}

export interface IUpdateLicense {
  type: LicensesActionTypes.UPDATE_LICENSE;
  payload: {
    license: ILicenseItem;
  };
}

export interface IUpdateLicenseSuccess {
  type: LicensesActionTypes.UPDATE_LICENSE_SUCCESS;
  payload: {
    license: ILicenseItem;
  };
}

export interface IUpdateLicenseFailed {
  type: LicensesActionTypes.UPDATE_LICENSE_FAILED;
  error: string;
}

export interface ISetSelectedLicense {
  type: LicensesActionTypes.SET_SELECTED_LICENSE;
  payload: { license?: ILicenseItem };
}

export interface LicensesState {
  licenses?: ILicenseItem[];
  loadingLicensesStatus: ActionStatusTypes;
  selectedLicense?: ILicenseItem;
  loadingLicenseByIdStatus: ActionStatusTypes;
  updatingLicenseStatus: ActionStatusTypes;
}

export type LicensesAction =
  | IGetLicenses
  | IGetLicensesSuccess
  | IGetLicensesFailed
  | IGetLicenseById
  | IGetLicenseByIdSuccess
  | IGetLicenseByIdFailed
  | IUpdateLicense
  | IUpdateLicenseSuccess
  | IUpdateLicenseFailed
  | ISetSelectedLicense;

export enum LicensesActionTypes {
  GET_LICENSES = "clients/get_licenses",
  GET_LICENSES_SUCCESS = "clients/get_licenses_success",
  GET_LICENSES_FAILED = "clients/get_licenses_failed",
  GET_LICENSE_BY_ID = "clients/get_license_by_id",
  GET_LICENSE_BY_ID_SUCCESS = "clients/get_license_by_id_success",
  GET_LICENSE_BY_ID_FAILED = "clients/get_license_by_id_failed",
  UPDATE_LICENSE = "clients/updated_licenses",
  UPDATE_LICENSE_SUCCESS = "clients/updated_licenses_success",
  UPDATE_LICENSE_FAILED = "clients/updated_licenses_failed",
  SET_SELECTED_LICENSE = "clients/set_selected_license",
}

// SUBSCRIPTIONS

export interface IGetSubscriptions {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS;
}

export interface IGetSubscriptionsSuccess {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_SUCCESS;
  payload: {
    subscriptions: ISubscriptionInfo[];
  };
}
export interface IGetSubscriptionsFailed {
  type: SubscriptionsActionTypes.GET_SUBSCRIPTIONS_FAILED;
}

export interface ISuspendSubscription {
  type: SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION;
  payload: {
    id: string;
  };
}

export interface ISuspendSubscriptionSuccess {
  type: SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION_SUCCESS;
}

export interface ISuspendSubscriptionFailed {
  type: SubscriptionsActionTypes.SUSPEND_SUBSCRIPTION_FAILED;
}

export interface IReactivateSubscription {
  type: SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION;
  payload: {
    id: string;
  };
}

export interface IReactivateSubscriptionSuccess {
  type: SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION_SUCCESS;
}

export interface IReactivateSubscriptionFailed {
  type: SubscriptionsActionTypes.REACTIVATE_SUBSCRIPTION_FAILED;
}

export interface IChangeSubscription {
  type: SubscriptionsActionTypes.CHANGE_SUBSCRIPTION;
  payload: {
    orgId: string;
    data: IChangeSubData;
  };
}

export interface IChangeSubscriptionSuccess {
  type: SubscriptionsActionTypes.CHANGE_SUBSCRIPTION_SUCCESS;
}

export interface IChangeSubscriptionFailed {
  type: SubscriptionsActionTypes.CHANGE_SUBSCRIPTION_FAILED;
}

export type SubscriptionsAction =
  | IGetSubscriptions
  | IGetSubscriptionsSuccess
  | IGetSubscriptionsFailed
  | ISuspendSubscription
  | ISuspendSubscriptionSuccess
  | ISuspendSubscriptionFailed
  | IReactivateSubscription
  | IReactivateSubscriptionSuccess
  | IReactivateSubscriptionFailed
  | IChangeSubscription
  | IChangeSubscriptionSuccess
  | IChangeSubscriptionFailed;

export interface SubscriptionsState {
  subscriptions?: ISubscriptionInfo[];
  loadingSubscriptionsStatus: ActionStatusTypes;
  updatingSubscriptionsStatus: ActionStatusTypes;
}

export enum SubscriptionsActionTypes {
  GET_SUBSCRIPTIONS = "clients/get_subscription",
  GET_SUBSCRIPTIONS_SUCCESS = "clients/get_subscription_success",
  GET_SUBSCRIPTIONS_FAILED = "clients/get_subscription_failed",
  SUSPEND_SUBSCRIPTION = "clients/suspend_subscription",
  SUSPEND_SUBSCRIPTION_SUCCESS = "clients/suspend_subscription_success",
  SUSPEND_SUBSCRIPTION_FAILED = "clients/suspend_subscription_failed",
  REACTIVATE_SUBSCRIPTION = "clients/reactivate_subscription",
  REACTIVATE_SUBSCRIPTION_SUCCESS = "clients/reactivate_subscription_success",
  REACTIVATE_SUBSCRIPTION_FAILED = "clients/reactivate_subscription_failed",
  CHANGE_SUBSCRIPTION = "clients/change_subscription",
  CHANGE_SUBSCRIPTION_SUCCESS = "clients/change_subscription_success",
  CHANGE_SUBSCRIPTION_FAILED = "clients/change_subscription_failed",
}

// CLIENTS
export interface ISetSelectedClient {
  type: ClientsActionTypes.SET_SELECTED_CLIENT;
  payload: { client?: IClientModel };
}

export type ClientsAction = ISetSelectedClient;

export interface ClientsState {
  selectedClient?: IClientModel;
}

export enum ClientsActionTypes {
  SET_SELECTED_CLIENT = "clients/set_selected",
}
