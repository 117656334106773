import React from "react";
import {
  filterTiersByProvider,
  getNextPaymentDate,
} from "../../../../../../../utils/licensesUtil";
import WarningModal from "../../../../../../../components/elements/modals/warninModal/WarningModal";
import {
  IModalButtons,
  ITierModel,
} from "../../../../../../../interfaces/interfaces";
import { gaImages } from "../../../../../../../data/images";
import ChangeSubscriptionModal from "../../../../../components/modals/changeSubscriptionModal/ChangeSubscriptionModal";
import { ClientType } from "../../../../list/Clients";
import { SubscriptionActionsEnum } from "../../../../../data/clients.constants";

export type ModalsProps = {
  showSuspendWarning: boolean;
  selectedClient: ClientType;
  suspendModalButtons: IModalButtons;
  selectedOrgId?: string;
  deleteModalButtons: IModalButtons;
  tiers?: ITierModel[];
  changeSubModalButtons: IModalButtons;
  showChangeSubscriptionModal: boolean;
  showDeleteModal: boolean;
  actionType: SubscriptionActionsEnum;
  notActivating?: boolean;
  setShowDeleteModal: (x?: any) => void;
  setShowSuspendModal: (x?: any) => void;
  setShowChangeSubscriptionModal: (x?: any) => void;
};

const Modals: React.FC<ModalsProps> = (props) => {
  const {
    showSuspendWarning,
    selectedClient,
    suspendModalButtons,
    actionType,
    deleteModalButtons,
    tiers,
    changeSubModalButtons,
    showChangeSubscriptionModal,
    showDeleteModal,
    notActivating,
    setShowDeleteModal,
    setShowSuspendModal,
    setShowChangeSubscriptionModal,
  } = props;

  return (
    <>
      {showSuspendWarning ? (
        <WarningModal
          showBg={true}
          title={"clientDetail.youAreCancellingYourClientSubscription"}
          description={
            "clientDetail.youAreCancellingYourClientSubscriptionDesc"
          }
          descriptionExtraTranslationParams={{
            date: getNextPaymentDate(selectedClient?.nextPaymentDate),
          }}
          primaryButton={{
            label: suspendModalButtons.primary?.label,
            action: (e: any) => {
              suspendModalButtons?.primary?.action &&
                suspendModalButtons?.primary?.action(e),
                setShowSuspendModal(false);
            },
          }}
          secondaryButton={suspendModalButtons.secondary}
          headerClose
          hideModal={() => setShowSuspendModal(false)}
          item={selectedClient?.org?.id}
        />
      ) : null}
      {showDeleteModal ? (
        <WarningModal
          title={"clients.sureDeleteTitle"}
          description={"clients.sureDeleteDesc"}
          primaryButton={deleteModalButtons.primary}
          secondaryButton={deleteModalButtons.secondary}
          icon={gaImages?.skullPng}
          headerClose
          item={selectedClient?.orgId}
          hideModal={() => setShowDeleteModal(false)}
        />
      ) : null}
      {showChangeSubscriptionModal ? (
        <ChangeSubscriptionModal
          showBg={true}
          actionType={actionType}
          secondaryButton={changeSubModalButtons.secondary}
          headerClose
          tiers={filterTiersByProvider(
            selectedClient?.org?.providerId || "",
            tiers
          )}
          hideModal={() => setShowChangeSubscriptionModal(false)}
          item={selectedClient?.org}
          selectedLicense={selectedClient?.plan?.type}
          selectedPeriodChecked={selectedClient?.plan?.period || ""}
        />
      ) : null}
    </>
  );
};

export default Modals;
