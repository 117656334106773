import { ILoaderState, LoaderActions, LoaderActionTypes } from "./loaderTypes";

const sessionInitialState: ILoaderState = {
  showScreenLoader: false,
};

export const loaderReducer = (
  state = sessionInitialState,
  action: LoaderActions
) => {
  switch (action.type) {
    case LoaderActionTypes.SHOW_SCREEN_LOADER:
      return {
        ...state,
        showScreenLoader: true,
      };
    case LoaderActionTypes.HIDE_SCREEN_LOADER:
      return {
        ...state,
        showScreenLoader: false,
      };
    default:
      return state;
  }
};
