import { createSelector } from "reselect";
import { ActionStatusTypes } from "../../../data/common";
import {
  IDataChart,
  MetricStatistic,
  MetricValue,
  Statistics,
} from "../../../interfaces/interfaces";
import { RootState } from "../../../store/indexReducers";
import moment from "moment";

export const getStastisticsStatus = (state: RootState): ActionStatusTypes =>
  state.statisticsReducer.getStaticsStatus;

export const getStastistics = (state: RootState) =>
  state.statisticsReducer.statistics;

export const getClientsStastistics = (state: RootState) =>
  state.statisticsReducer.clientStats;

export const getUsedFeaturesNumber = createSelector(
  [getStastistics, getStastisticsStatus],
  (statistics, getStatisticsStatus) => {
    return getFeaturesNumber(statistics, getStatisticsStatus);
  }
);

export const getUsedFeaturesPercentages = createSelector(
  [getStastistics, getStastisticsStatus, getClientsStastistics],
  (statistics, getStatisticsStatus, getClientsStatistics) => {
    return getFeaturesPercentages(
      statistics,
      getStatisticsStatus,
      getClientsStatistics
    );
  }
);

export const getUsedFeaturesVariations = createSelector(
  [getStastistics, getStastisticsStatus],
  (statistics, getStatisticsStatus) => {
    return getFeaturesVariations(statistics, getStatisticsStatus);
  }
);

export const getIsYearPeriodSelected = (state: RootState) =>
  state.statisticsReducer.selectedPeriodIsYear;

export const getDids = createSelector(
  [getStastistics, getIsYearPeriodSelected],
  (statistics, isYearlyPeriod) => {
    return getLastValueOfEachDay(statistics?.Cmn1?.values, isYearlyPeriod);
  }
);

export const getApiKeys = createSelector(
  [getStastistics, getIsYearPeriodSelected],
  (statistics, isYearlyPeriod) => {
    return getLastValueOfEachDay(statistics?.Cmn2.values, isYearlyPeriod);
  }
);

export const getActiveUsers = createSelector(
  [getStastistics, getIsYearPeriodSelected],
  (statistics, isYearlyPeriod) => {
    return getLastValueOfEachDay(statistics?.Cnt2.values, isYearlyPeriod);
  }
);

export const getIssuedCredentials = createSelector(
  [getStastistics, getIsYearPeriodSelected],
  (statistics, isYearlyPeriod) => {
    return getLastValueOfEachDay(statistics?.Ctf2.values, isYearlyPeriod);
  }
);

export const getVerifieableTemplates = createSelector(
  [getStastistics, getIsYearPeriodSelected],
  (statistics, isYearlyPeriod) => {
    return getLastValueOfEachDay(statistics?.Cnt1.values, isYearlyPeriod);
  }
);

export const getIssuanceTemplates = createSelector(
  [getStastistics, getIsYearPeriodSelected],
  (statistics, isYearlyPeriod) => {
    return getLastValueOfEachDay(statistics?.Ctf1?.values, isYearlyPeriod);
  }
);

export const dataFeaturesForChart = createSelector(
  [
    getDids,
    getApiKeys,
    getActiveUsers,
    getIssuedCredentials,
    getVerifieableTemplates,
    getIssuanceTemplates,
  ],
  (
    dids,
    apiKeys,
    activeUsers,
    issuedCredentials,
    verifierTemplates,
    issuanceTemplates
  ) => {
    return {
      verifierTemplates: verifierTemplates,
      issuanceTemplates: issuanceTemplates,
      activeUsers: activeUsers,
      issuedCredentials: issuedCredentials,
      dids: dids,
      apiKeys: apiKeys,
    };
  }
);

const getFeaturesNumber = (
  statistics: Statistics | undefined,
  getStaticsStatus: ActionStatusTypes
) => {
  if (getStaticsStatus !== ActionStatusTypes.loading) {
    return {
      verifierTemplates: getLastValue(statistics?.Cnt1) || 0,
      issuanceTemplates: getLastValue(statistics?.Ctf1) || 0,
      activeUsers: getLastValue(statistics?.Cnt2) || 0,
      issuedCredentials: getLastValue(statistics?.Ctf2) || 0,
      dids: getLastValue(statistics?.Cmn1) || 0,
      apiKeys: getLastValue(statistics?.Cmn2) || 0,
    };
  }
};

export const getFeaturesPercentages = (
  statistics: Statistics | undefined,
  getStaticsStatus: ActionStatusTypes,
  getClientsStatistics: MetricStatistic | undefined
) => {
  if (getStaticsStatus !== ActionStatusTypes.loading) {
    return {
      verifierTemplates: statistics?.Cnt1?.percentage,
      issuanceTemplates: statistics?.Ctf1?.percentage,
      activeUsers: statistics?.Cnt2?.percentage,
      issuedCredentials: statistics?.Ctf2?.percentage,
      dids: statistics?.Cmn1?.percentage,
      apiKeys: statistics?.Cmn2?.percentage,
      clients: getClientsStatistics?.percentage,
    };
  }
};

export const getFeaturesVariations = (
  statistics: Statistics | undefined,
  getStaticsStatus: ActionStatusTypes,
  getClientsStatistics?: MetricStatistic
) => {
  if (getStaticsStatus !== ActionStatusTypes.loading) {
    return {
      verifierTemplates: statistics?.Cnt1?.variation,
      issuanceTemplates: statistics?.Ctf1?.variation,
      activeUsers: statistics?.Cnt2?.variation,
      issuedCredentials: statistics?.Ctf2?.variation,
      dids: statistics?.Cmn1?.variation,
      apiKeys: statistics?.Cmn2?.variation,
      clients: getClientsStatistics?.variation,
    };
  }
};

const getLastValue = (data: MetricStatistic | undefined) => {
  const lastData = data?.values?.filter((value) => value?.time === data?.end);
  return lastData && lastData?.length > 0 ? lastData[0]?.value : 0;
};

const getLastValueOfEachDay = (
  periodValues: MetricValue[] | undefined,
  isYearlyPeriod?: boolean
) => {
  let lastValues = periodValues?.reduce((acc: any, cur: any) => {
    let curDate = new Date(cur.time);
    let curDateStr = moment(curDate)?.format("YYYY-MM-DD");

    let existDateObj = acc.find((f: any) => {
      return curDateStr && f.dStr && f.dStr === curDateStr;
    });

    let existDateObjIndex = acc.findIndex(
      (f: any) => curDateStr && f.dStr && f.dStr === curDateStr
    );

    const curDisplayData = {
      x: new Date(curDateStr),
      y: cur?.value,
      dStr: curDateStr,
    };

    if (existDateObj) {
      let existDate = new Date(existDateObj.time);

      if (curDate.getTime() > existDate.getTime()) {
        acc[existDateObjIndex] = curDisplayData;
      }
    } else {
      acc.push(curDisplayData);
    }

    return acc;
  }, new Array());

  return isYearlyPeriod && lastValues?.length > 30
    ? getLastValueOfMonth(lastValues)
    : lastValues;
};

const getLastValueOfMonth = (data: any) => {
  const grouped = {};
  data?.forEach((obj: any) => {
    const date = new Date(obj?.dStr);
    const key = date?.getFullYear() + "-" + (date?.getMonth() + 1);
    if (!grouped[key as keyof typeof grouped]) {
      // @ts-ignore
      grouped[key as keyof typeof grouped] = [];
    }

    const newDate = new Date(obj?.dStr);
    newDate?.setDate(1);
    obj.x = newDate;
    // @ts-ignore
    grouped[key as keyof typeof grouped]?.push(obj);
  });

  const result = Object.values(grouped)?.map((month: any) => {
    return month?.reduce((last: any, curr: any) => {
      return new Date(curr.dStr) > new Date(last?.dStr) ? curr : last;
    });
  });

  return sortEntriesByDate(result);
};

const sortEntriesByDate = (entries: any): IDataChart[] => {
  return entries?.sort(function (a: any, b: any) {
    return moment.utc(a.dStr).diff(moment.utc(b.dStr));
  });
};
