import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./dropdownActions.module.scss";
import cx from "classnames";
import MoreVerticalIcon from "../../../../../../../assets/iconsLibrary/simple/moreVertical/moreVerticalIcon";
import {
  ImageModel,
  LinkModel,
} from "../../../../../../../interfaces/interfaces";
import { Link } from "react-router-dom";
import { getValueForURL } from "../../../../../../../utils/formatDataUtil";
import { gaColors } from "../../../../../../../data/display";

type IDropdownActionsProps = {
  icon?: ImageModel;
  iconClassName?: string;
  className?: string;
  menuOptions?: LinkModel[];
  left?: boolean;
  noLayer?: boolean;
  selectOptions?: {
    text: string;
    value: string;
    color?: string;
  }[];
  item: any;
  onHandleChange?: (x: any, y?: any, z?: any) => void;
  deleteItem: ((x: any, y?: any, z?: any) => void) | undefined;
  seeDetails: ((x?: any) => void) | undefined;
};

const DropdownActions: React.FC<IDropdownActionsProps> = (props) => {
  const {
    left,
    noLayer,
    item,
    className,
    selectOptions,
    onHandleChange,
    deleteItem,
    seeDetails,
  } = props;
  const [active, setIsActive] = useState(false);
  const { t } = useTranslation();

  return (
    <div className={styles.dropdownActionsContainer}>
      <button
        className={styles.dropdownActionsButton + " " + className}
        onClick={() => {
          setIsActive(!active);
        }}
      >
        <MoreVerticalIcon color={gaColors.neutral1000} size={34} />
      </button>
      {active && (
        <div
          className={
            (active ? styles.active : styles.inActive) +
            " " +
            styles.menuOptionsCard +
            " " +
            (left ? styles.alignLeft : "")
          }
          id="dropdownOptions"
        >
          <ul className={styles.menuOptionsCard__list}>
            {selectOptions && selectOptions?.length ? (
              <p
                className={cx(
                  "bodyRegularXS neutral700",
                  styles.menuOptionsCard__item__section
                )}
              >
                {t("credentials.status")}
              </p>
            ) : null}
            {selectOptions?.map((e, index) => {
              return (
                <li
                  key={index}
                  className={styles.menuOptionsCard__item}
                  onClick={() => {
                    setIsActive(false);
                    onHandleChange && onHandleChange(item, e);
                  }}
                >
                  <p className={cx("buttonMD neutral1000 marginLeft8")}>
                    {t(e.text)}
                  </p>
                </li>
              );
            })}
            {seeDetails ? (
              <li
                className={`${styles.menuOptionsCard__item} `}
                onClick={() => {
                  setIsActive(false);
                  seeDetails(item);
                }}
              >
                <Link
                  className={cx("buttonMD neutral1000")}
                  to={getValueForURL(item?.name || "detail")}
                >
                  {t("general.viewDetail")}
                </Link>
              </li>
            ) : null}
            {deleteItem ? (
              <li
                className={`${styles.menuOptionsCard__item} `}
                onClick={() => {
                  setIsActive(false);
                  deleteItem(item);
                }}
              >
                <p className={cx("buttonMD alert300")}>
                  {t("clients.killAccount")}
                </p>
              </li>
            ) : null}
          </ul>
        </div>
      )}
      {!noLayer ? (
        <div
          className={
            active
              ? styles.backgroundLayerActive
              : styles.backgroundLayerInactive
          }
          onClick={() => {
            setIsActive(!active);
          }}
        ></div>
      ) : null}
    </div>
  );
};

export default DropdownActions;
