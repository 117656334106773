import { ActionStatusTypes } from "../../../../../../../data/common";
import { Statistics } from "../../../../../../../interfaces/interfaces";

export interface IMetricsState {
  getStaticsStatus: ActionStatusTypes;
  statistics?: Statistics;
  selectedPeriodIsYear?: boolean;
}
export enum StatisticsTypes {
  GET_ALL_STATISTICS = "stats/get",
  GET_ALL_STATISTICS_SUCCESS = "stats/get_success",
  GET_ALL_STATISTICS_FAILED = "stats/get_failed",
  SET_SELECTED_PERIOD_YEAR = "stats/set_selected_period_year",
}
export interface IGetStatistics {
  type: StatisticsTypes.GET_ALL_STATISTICS;
  payload: { params: string };
}

export interface IGetStatisticsSuccess {
  type: StatisticsTypes.GET_ALL_STATISTICS_SUCCESS;
  payload: {
    statistics: Statistics;
  };
}

export interface IGetStatisticsFailed {
  type: StatisticsTypes.GET_ALL_STATISTICS_FAILED;
  payload: {
    error: string;
  };
}

export interface ISetSelectedPeriodYear {
  type: StatisticsTypes.SET_SELECTED_PERIOD_YEAR;
  payload: { isYear: boolean };
}

export type IMetricsActions =
  | IGetStatistics
  | IGetStatisticsSuccess
  | IGetStatisticsFailed
  | ISetSelectedPeriodYear;
