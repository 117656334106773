import React, { useState } from "react";
import styles from "./quantitiesChart.module.scss";
import { gaColors, gaFonts } from "../../../../data/display";
// @ts-ignore
import CanvasJSReact from "@canvasjs/react-charts"; // We don’t have CanvasJS React Charts TypeScript definition as of now, so we have to use ts-ignore.
import { featureOptions } from "../../data/home.constants";
import TotalFeatures from "../totalFeatures/totalFeatures";
import PercentagesChart from "../percentagesFeatures/percentagesChart/percentagesChart";
import FeaturesFilter from "../filters/featuresFilter/featuresFilter";
import CardListSkeleton from "../../../../components/elements/skeletons/cardListSkeleton/CardListSkeleton";
import { useSelector } from "react-redux";
import { homeSelectors } from "../../store";
import {
  IDataChart,
  IPercentageVariationChart,
} from "../../../../interfaces/interfaces";
import moment from "moment";

type IQuantitiesChartProps = {
  dataLoading?: boolean;
  selectedPeriodDays: string;
  featuresPercentages?: IPercentageVariationChart;
  featuresVariations?: IPercentageVariationChart;
};

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const QuantitiesChart: React.FC<IQuantitiesChartProps> = (props) => {
  const {
    dataLoading,
    selectedPeriodDays,
    featuresPercentages,
    featuresVariations,
  } = props;

  // Selectors
  const dataFeatures = useSelector(homeSelectors.dataFeaturesForChart);

  // States
  const [selectedFeature, setSelectedfeature] = useState(
    featureOptions[0]?.value
  );

  // Functions
  const getAxisYFormat = () => {
    const max = selectedFeatureData?.reduce(function (
      prev: IDataChart,
      current: IDataChart
    ) {
      return prev && prev.y > current.y ? prev : current;
    });
    return max > 999 ? "#,##0,.##K" : "#";
  };

  const getAxisXFormat = () => {
    const periodDays = selectedPeriodDays ? parseInt(selectedPeriodDays) : 0;

    return periodDays > 30
      ? "MMM"
      : startDateYear === endDateYear
      ? "DD/MMM"
      : "DD/MM/YY";
  };

  // Variables
  const selectedFeatureData =
    dataFeatures[selectedFeature as keyof typeof dataFeatures];
  const firstEntry = selectedFeatureData?.length && selectedFeatureData[0];
  const lastEntry =
    selectedFeatureData?.length &&
    selectedFeatureData[selectedFeatureData?.length - 1];
  const startDate =
    firstEntry?.dStr && moment(firstEntry?.dStr)?.format("DD/MM/YY");
  const endDate =
    lastEntry?.dStr && moment(lastEntry?.dStr)?.format("DD/MM/YY");

  const startDateYear = moment(firstEntry?.dStr)?.format("YY");
  const endDateYear = moment(lastEntry?.dStr)?.format("YY");

  const percentage =
    (featuresPercentages &&
      featuresPercentages[
        selectedFeature as keyof typeof featuresPercentages
      ]) ||
    undefined;

  const variation =
    (featuresVariations &&
      featuresVariations[
        selectedFeature as keyof typeof featuresPercentages
      ]) ||
    undefined;

  const options = {
    animationEnabled: true,
    axisX: {
      valueFormatString: getAxisXFormat(),
      labelFontColor: gaColors.neutral500,
      labelFontSize: "12",
      labelFontWeight: "400",
      lineColor: gaColors.neutral300,
      tickLength: 0,
      fontFamily: gaFonts.base,
    },
    axisY: {
      valueFormatString: getAxisYFormat(),
      prefix: "",
      gridDashType: "dot",
      gridThickness: 0,
      labelFontColor: gaColors.neutral500,
      labelFontSize: "12",
      labelFontWeight: "400",
      lineColor: gaColors.neutral300,
      tickLength: 0,
      minimum: 0,
      fontFamily: gaFonts.base,
    },
    toolTip: {
      cornerRadius: 8,
      labelFontSize: "12",
      labelFontWeight: "400",
      fontFamily: gaFonts.base,
      borderColor: gaColors.neutral300,
      fontColor: gaColors.primary700,
      content:
        "<span style='\"'color: {gaColors.neutral100};'\"'>{x}</span>: {y}",
    },

    data: [
      {
        markerColor: gaColors.primary700,
        yValueFormatString: "####",
        xValueFormatString: getAxisXFormat(),
        type: "spline",
        lineColor: gaColors.primary700,
        highlightEnabled: false,
        dataPoints: selectedFeatureData,
      },
    ],
    creditText: undefined,
    creditHref: undefined,
  };

  return (
    <div className={styles.metricsContainer}>
      {!dataLoading && selectedFeatureData ? (
        <>
          <FeaturesFilter
            options={featureOptions}
            selectedFeature={selectedFeature}
            selectFeature={setSelectedfeature}
            startDate={startDate}
            endDate={endDate}
          />
          <div className={styles.featuresDataContainer}>
            <TotalFeatures feature={selectedFeature} total={lastEntry?.y} />
            {variation ? (
              <PercentagesChart
                selectedPeriodDays={selectedPeriodDays}
                percentage={percentage}
                variation={variation}
              />
            ) : null}
          </div>
          <div className={styles.chartContainer}>
            <CanvasJSChart options={options} />
          </div>
        </>
      ) : (
        <CardListSkeleton cardsNumber={1} className={`${styles.skeleton}`} />
      )}
    </div>
  );
};

export default QuantitiesChart;
