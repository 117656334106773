import React, { useState } from "react";
import styles from "./tabs.module.scss";
import { t } from "i18next";
import cx from "classnames";
import HoverTooltip from "../tooltips/HoverTooltip/HoverTootltip";
import InfoIcon from "../../../assets/iconsLibrary/simple/info/infoIcon";
import { gaColors } from "../../../data/display";

type TabsProps = {
  sections: {
    id: string;
    tabTitle: string;
    tabContent?: React.ReactNode;
    disabled?: boolean;
    info?: string;
  }[];
  className?: string;
  onChangeFunction?: () => void;
};

const Tabs: React.FC<TabsProps> = (props) => {
  const { sections, className, onChangeFunction } = props;

  const [visibleTab, setVisibleTab] = useState(sections[0]);

  const listTitles = sections?.map((item, index) => (
    <li
      key={"tabSection__" + index}
      onClick={() =>
        !item?.disabled
          ? (setVisibleTab(item), onChangeFunction && onChangeFunction())
          : null
      }
      className={cx(
        styles.tabTitle,
        "bodyRegularSM",
        visibleTab?.id === item?.id && styles.tabTitleActive,
        item?.disabled && styles.disabled
      )}
    >
      {item?.info ? (
        <a
          className={cx("bodyRegularSM")}
          href="#"
          aria-label={t(item?.info)}
          tooltip-position="top"
        >
          {t(item?.tabTitle)}
        </a>
      ) : (
        t(item?.tabTitle)
      )}
    </li>
  ));

  return (
    <div className={cx(styles.tabs, className && className)}>
      <ul className={styles.tabs__titles}>{listTitles}</ul>
      <div className={styles.tabContent}>{visibleTab?.tabContent}</div>
    </div>
  );
};

export default Tabs;
