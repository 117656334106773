import React, { useState } from "react";
import styles from "./datesFilter.module.scss";
import CardListSkeleton from "../../../../../../../../../components/elements/skeletons/cardListSkeleton/CardListSkeleton";
import { gaImages } from "../../../../../../../../../data/images";
import cx from "classnames";
import DropdownMenu from "../dropwdownMenu/DropdownMenu";
import { IGeneralSelectOption } from "../../../../../../../../../interfaces/interfaces";

export type IDatesFilterProps = {
  activePeriodLabel: string;
  selectedFilterDate: string;
  datesOptions: IGeneralSelectOption[];
  setSelectedFilterDate: (x: any) => void;
  dataLoading?: boolean;
};

const DatesFilter: React.FC<IDatesFilterProps> = (props) => {
  const { activePeriodLabel, datesOptions, dataLoading } = props;

  const [active, setIsActive] = useState(false);

  return (
    <div className={`${styles.datesFilter}`}>
      {!dataLoading ? (
        <>
          <div
            className={cx(styles.datesFilterContainer)}
            onClick={() => setIsActive(!active)}
          >
            {activePeriodLabel ? (
              <p className={cx("bodyRegularSM neutral1000")}>
                {activePeriodLabel}
              </p>
            ) : null}

            <DropdownMenu
              item={""}
              icon={gaImages.angleDown}
              iconClassName={styles.action__icon}
              selectOptions={datesOptions}
              active={active}
              // activeLabel={selectedFilterDate}
              setIsActive={setIsActive}
            />
          </div>
        </>
      ) : (
        <CardListSkeleton cardsNumber={1} className={` ${styles.skeleton}`} />
      )}
    </div>
  );
};

export default DatesFilter;
