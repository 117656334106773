import { ActionStatusTypes } from "../../../data/common";
import { ITiersActions, ITiersState, TiersTypes } from "./tiersTypes";

const tiersState: ITiersState = {
  tiers: undefined,
  loadingStatus: false,
  error: "",
  selectedTier: undefined,
  getTiersStatus: ActionStatusTypes.neutral,
  createTierStatus: ActionStatusTypes.neutral,
  deleteTierStatus: ActionStatusTypes.neutral,
  updateTierStatus: ActionStatusTypes.neutral,
};
export const tiersReducer = (
  state = tiersState,
  action: ITiersActions
): ITiersState => {
  switch (action.type) {
    case TiersTypes.GET_TIERS:
      return {
        ...state,
        getTiersStatus: ActionStatusTypes.loading,
      };
    case TiersTypes.GET_TIERS_SUCCESS:
      return {
        ...state,
        getTiersStatus: ActionStatusTypes.success,
        tiers: action.payload.tiers,
      };
    case TiersTypes.GET_TIERS_FAILED:
      return {
        ...state,
        getTiersStatus: ActionStatusTypes.failed,
        tiers: undefined,
      };
    case TiersTypes.SELECT_TIER:
      return {
        ...state,
        selectedTier: action?.payload?.tier,
      };
    case TiersTypes.CREATE_TIER:
      return {
        ...state,
        loadingStatus: true,
        createTierStatus: ActionStatusTypes.loading,
        error: "",
      };
    case TiersTypes.CREATE_TIER_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        createTierStatus: ActionStatusTypes.success,
        selectedTier: action.payload.tier,
        error: "",
      };
    case TiersTypes.CREATE_TIER_FAILED:
      return {
        ...state,
        createTierStatus: ActionStatusTypes.failed,
        error: action.payload?.error,
      };
    case TiersTypes.DELETE_TIER:
      return {
        ...state,
        deleteTierStatus: ActionStatusTypes.loading,
      };
    case TiersTypes.DELETE_TIER_SUCCESS:
      return {
        ...state,
        selectedTier: undefined,
        deleteTierStatus: ActionStatusTypes.success,
      };
    case TiersTypes.DELETE_TIER_FAILED:
      return {
        ...state,
        deleteTierStatus: ActionStatusTypes.failed,
      };
    case TiersTypes.UPDATE_TIER:
      return {
        ...state,
        loadingStatus: true,
        updateTierStatus: ActionStatusTypes.loading,
        error: "",
      };
    case TiersTypes.UPDATE_TIER_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        selectedTier: action?.payload?.tier,
        updateTierStatus: ActionStatusTypes.success,
        error: "",
      };
    case TiersTypes.UPDATE_TIER_FAILED:
      return {
        ...state,
        updateTierStatus: ActionStatusTypes.failed,
        error: action.payload?.error,
      };
    default:
      return state;
  }
};
