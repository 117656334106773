import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, mergeMap, map, switchMap } from "rxjs/operators";
import dataService from "../../../services/dataService";
import {
  createTierFailed,
  createTierSuccess,
  deleteTierFailed,
  getTiers,
  getTiersFailed,
  getTiersSuccess,
  updateTierFailed,
  updateTierSuccess,
} from "./tiersActions";
import { TiersTypes } from "./tiersTypes";
import { showNotification } from "../../Notification/store/notificationActions";
import { hideScreenLoaderAction } from "../../Loader/loaderActions";
import { history } from "../../../services/history";
import { setSelectedRoute } from "../../PanelScafolding/store/scafoldingActions";

export const getTiersEpic: Epic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(TiersTypes.GET_TIERS),
    switchMap((action: any): any => {
      const provider = action?.payload?.providerId;
      return from(dataService.getTiers(provider)).pipe(
        map((response?: any) => {
          return getTiersSuccess(response?.response);
        }),
        catchError((error) =>
          of(
            getTiersFailed(error),
            showNotification(
              "platformConfig.subcriptionTiers.getTiersError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const createTierEpic: Epic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(TiersTypes.CREATE_TIER),
    switchMap((action: any): any => {
      const tier = action?.payload?.tier;
      return from(dataService.createTier(tier)).pipe(
        mergeMap((response?: any) => {
          history?.push("/platform-configuration");
          return of(
            getTiers(tier?.provider),
            setSelectedRoute("/platform-configuration"),
            createTierSuccess(response?.response),
            hideScreenLoaderAction(),
            showNotification(
              "platformConfig.subcriptionTiers.createTierSuccess",
              "success",
              ""
            )
          );
        }),
        catchError((error) =>
          of(
            createTierFailed(error),
            hideScreenLoaderAction(),
            showNotification(
              "platformConfig.subcriptionTiers.errorCreatingTier",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};
export const deleteTierEpic: Epic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(TiersTypes.DELETE_TIER),
    switchMap((action: any): any => {
      const id = action.payload.tierID;
      const provider = action.payload.provider;
      return from(dataService.deleteTier(id)).pipe(
        mergeMap((response?: any) => {
          history?.push("/platform-configuration");
          return of(
            getTiers(provider),
            setSelectedRoute("/platform-configuration"),
            hideScreenLoaderAction(),
            showNotification(
              "platformConfig.subcriptionTiers.deleteTierSuccess",
              "success",
              ""
            )
          );
        }),
        catchError((error) =>
          of(
            deleteTierFailed(error),
            hideScreenLoaderAction(),
            showNotification(
              "platformConfig.subcriptionTiers.deleteTierError",
              "error",
              error.response?.message,
              error?.status
            )
          )
        )
      );
    })
  );
};

export const updateTierEpic: Epic = (action$: any, state$: any) => {
  return action$.pipe(
    ofType(TiersTypes.UPDATE_TIER),
    switchMap((action: any): any => {
      const tier = action.payload.tier;
      const id = tier?.id;

      return from(dataService.updateTier(tier, id)).pipe(
        mergeMap((response?: any) => {
          const provider = tier?.provider;
          history?.push("/platform-configuration");
          return of(
            getTiers(provider),
            setSelectedRoute("/platform-configuration"),
            updateTierSuccess(response?.response),
            hideScreenLoaderAction(),
            showNotification(
              "platformConfig.subcriptionTiers.updateTierSuccess",
              "success",
              ""
            )
          );
        }),
        catchError((error) => {
          return of(
            updateTierFailed(error),
            hideScreenLoaderAction(),
            showNotification(
              tier?.name,
              "error",
              "platformConfig.subcriptionTiers.updateTierError",
              error?.status
            )
          );
        })
      );
    })
  );
};
