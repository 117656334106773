// NAVIGATION

export interface ISetActiveRoute {
  type: ScafoldingActionsTypes.SET_ACTIVE_ROUTE;
  payload: {
    route: string;
  };
}

export interface ISetSettings {
  type: ScafoldingActionsTypes.SET_SETTINGS_PANEL;
  payload: {
    settings: boolean;
  };
}

export interface INavigate {
  type: ScafoldingActionsTypes.NAVIGATE;
  path?: string;
}

export type ScafoldingActions = ISetActiveRoute | ISetSettings | any;

export enum ScafoldingActionsTypes {
  SET_ACTIVE_ROUTE = "scafolding/set_active_route",
  SET_SETTINGS_PANEL = "scafolding/set_settings_panel",
  NAVIGATE = "scafolding/navigate",
}

export interface IScafoldingState {
  activeRoute: string;
  isSettingsPanel?: boolean;
}
