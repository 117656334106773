import { OrganizationModel } from "../../../../../interfaces/interfaces";
import {
  ICheckLoginAction,
  ILoginDone,
  ILoginFailedAction,
  ILoginSuccessAction,
  ILogOutAction,
  IQRSignupSuccessAction,
  ISubmitEmailLoginAction,
  ISubmitLoginAction,
  ISwitchTenantAction,
  ISwitchTenantToChangeAction,
  LoginActionTypes,
} from "./loginTypes";

export const switchTenant = (tenant: string): ISwitchTenantAction => {
  return {
    type: LoginActionTypes.SWITCH_TENANT,
    payload: {
      tenant: tenant,
    },
  };
};

export const switchTenantAndChangeOrg = (
  org: OrganizationModel,
  tenant: string
): ISwitchTenantToChangeAction => {
  return {
    type: LoginActionTypes.SWITCH_TENANT_TO_CHANGE_ORG,
    payload: {
      tenant,
      org,
    },
  };
};

export function submitEmailLogin(
  username: string,
  password: string
): ISubmitEmailLoginAction {
  return {
    type: LoginActionTypes.SUBMIT_EMAIL_LOGIN,
    payload: {
      user: {
        username: username,
        password: password,
      },
    },
  };
}

export function submitLogin(token: string): ISubmitLoginAction {
  return {
    type: LoginActionTypes.SUBMIT_LOGIN,
    token: token,
  };
}

export function checkLogin(): ICheckLoginAction {
  return {
    type: LoginActionTypes.CHECK_LOGIN,
  };
}

export const loginSuccess = (): ILoginSuccessAction => {
  return {
    type: LoginActionTypes.LOGIN_SUCCESS,
    payload: {},
  };
};

export const qrSignupSuccess = (): IQRSignupSuccessAction => {
  return {
    type: LoginActionTypes.QR_SIGNUP_SUCCESS,
    payload: {},
  };
};

export const loginDone = (): ILoginDone => {
  return {
    type: LoginActionTypes.LOGIN_DONE,
  };
};

export const loginFailed = (err?: any): ILoginFailedAction => {
  return {
    type: LoginActionTypes.LOGIN_FAILED,
  };
};

export const loginOut = (): ILogOutAction => {
  return {
    type: LoginActionTypes.LOGOUT,
  };
};
