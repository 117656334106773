import React, { useState } from "react";
import PanelScafoldingPrivate from "../../../PanelScafolding/components/private/PanelScafoldingPrivate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  reactivateSubscriptionAction,
  suspendSubscriptionAction,
} from "../../store/clientsActions";
import { clientsSelectors } from "../../store";
import { ActionStatusTypes } from "../../../../data/common";
import { clientsScopes } from "../../data/clients.constants";
import ScreenHeader from "../../../../components/elements/headers/ScreenHeader/ScreenHeader";
import Description from "../../../../components/atoms/texts/Description/Description";
import TableSkeleton from "../../../../components/elements/skeletons/tableSkeleton/TableSkeleton";
import WarningModal from "../../../../components/elements/modals/warninModal/WarningModal";
import { sessionSelectors } from "../../../Settings/Profile/store";
import { userHasScopes } from "../../../../utils/permissionsUtil";
import styles from "./clientDetail.module.scss";
import { showScreenLoaderAction } from "../../../Loader/loaderActions";
import Tabs from "../../../../components/elements/tabs/Tabs";
import Information from "./sections/information/Information";
import SubscriptionAndPayment from "./sections/subscriptionAndPayment/SubscriptionAndPayment";
import Metrics from "./sections/metrics/views/Metrics";
import { getPeriodTranslations } from "../../../../utils/licensesUtil";
import moment from "moment";
import { tiersSelectors } from "../../../PlatformConfig/store";

type IClientDetailProps = {};

const ClientDetail: React.FC<IClientDetailProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const getSelectedClientOrgStatus = useSelector(
    clientsSelectors.getOrgsStatus
  );
  const selectedClient = useSelector(clientsSelectors.getSelectedClient);
  const getOrgStatus = useSelector(clientsSelectors.getOrgByIdStatus);
  const tiers = useSelector(tiersSelectors.getTiers);
  const availableScopes = useSelector(sessionSelectors?.getAllowedScopes);

  // Variabes
  const gettingSelectedClientOrg = getOrgStatus === ActionStatusTypes?.loading;
  const hasReadScope = !!(
    availableScopes && userHasScopes(clientsScopes?.read, availableScopes)
  );
  const isLoading = gettingSelectedClientOrg;

  // States
  const [showSuspendWarning, setShowSuspendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrgId, setSelectedOrg] = useState(undefined);

  // Functions
  const suspendOrg = (orgId: string) => {
    dispatch(showScreenLoaderAction());
    dispatch(suspendSubscriptionAction(orgId));
  };

  const activateOrg = (orgId: string) => {
    dispatch(showScreenLoaderAction());
    dispatch(reactivateSubscriptionAction(orgId));
  };

  const suspendModalButtons = {
    primary: { label: "clients.suspend", action: suspendOrg },
    secondary: {
      label: "general.cancel",
      action: () => {
        setShowSuspendModal(false), setSelectedOrg(undefined);
      },
    },
  };

  const deleteModalButtons = {
    primary: { label: "general.delete", function: activateOrg },
    secondary: {
      label: "general.noGoBack",
      function: () => {
        setShowDeleteModal(false), setSelectedOrg(undefined);
      },
    },
  };

  // Sections
  const detailSections = [
    {
      id: "1",
      tabTitle: t("clientDetail.information"),
      tabContent: <Information org={selectedClient?.org} />,
    },
    {
      id: "2",
      tabTitle: t("clientDetail.subscriptionAndPayment"),
      tabContent: (
        <SubscriptionAndPayment client={selectedClient} tiers={tiers} />
      ),
    },
    {
      id: "3",
      tabTitle: t("clientDetail.metrics"),
      tabContent: <Metrics org={selectedClient?.org} />,
    },
  ];

  // Views
  return (
    <PanelScafoldingPrivate>
      <ScreenHeader
        title={selectedClient?.name || ""}
        subText={
          !!selectedClient?.plan?.type
            ? selectedClient?.plan?.type +
              (selectedClient?.plan?.period
                ? " - " + t(getPeriodTranslations(selectedClient?.plan?.period))
                : "")
            : ""
        }
        nestedRouteWithBackButton={{
          parentRoute: {
            label: "clients.screenTitle",
            route: "/clients",
          },
          currentRouteName: selectedClient?.name || "",
        }}
      />

      <Tabs sections={detailSections} className={"marginTop32"} />
      {hasReadScope ? (
        !isLoading ? (
          <>
            {showSuspendWarning ? (
              <WarningModal
                showBg={true}
                title={"clients.sureSuspendTitle"}
                description={"clients.sureSuspendDesc"}
                descriptionExtraTranslationParams={{
                  date: selectedClient?.nextPaymentDate
                    ? t("clients.the") +
                      " " +
                      moment(selectedClient?.nextPaymentDate)?.format(
                        "DD/MM/YYYY"
                      )
                    : t("clients.currentPeriodEnds"),
                }}
                primaryButton={suspendModalButtons.primary}
                secondaryButton={suspendModalButtons.secondary}
                headerClose
                hideModal={() => (
                  setShowSuspendModal(false), setSelectedOrg(undefined)
                )}
                item={selectedOrgId}
              />
            ) : null}
            {showDeleteModal ? (
              <WarningModal
                title={"clients.sureDeleteTitle"}
                description={"clients.sureDeleteDesc"}
                primaryButton={deleteModalButtons.primary}
                secondaryButton={deleteModalButtons.secondary}
                hideModal={() => (
                  setShowDeleteModal(false), setSelectedOrg(undefined)
                )}
                item={selectedOrgId}
              />
            ) : null}
          </>
        ) : (
          <Description text={t("clients.noClientDetailYet")} />
        )
      ) : (
        <TableSkeleton
          className={styles.skeleton}
          columnsNumber={3}
          rowsNumber={10}
          responsiveCards={true}
        />
      )}
    </PanelScafoldingPrivate>
  );
};

ClientDetail.defaultProps = {};

export default ClientDetail;
