import React, { useEffect, useState } from "react";
import PanelScafoldingPrivate from "../../../PanelScafolding/components/private/PanelScafoldingPrivate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrgAction,
  getOrganizationsAction,
  setSelectedClientAction,
  suspendSubscriptionAction,
} from "../../store/clientsActions";
import { clientsSelectors } from "../../store";
import {
  clientsScopes,
  propertiesToSearch,
} from "../../data/clients.constants";
import SearchableListHeader from "../../../../components/elements/headers/SearchableListHeader/SearchableListHeader";
import ScreenHeader from "../../../../components/elements/headers/ScreenHeader/ScreenHeader";
import Description from "../../../../components/atoms/texts/Description/Description";
import TableSkeleton from "../../../../components/elements/skeletons/tableSkeleton/TableSkeleton";
import PermissionsPanel from "../../../../components/elements/panels/permissionsPanel/PermissionsPanel";
import { sessionSelectors } from "../../../Settings/Profile/store";
import { userHasScopes } from "../../../../utils/permissionsUtil";
import { IClientModel, ISearchState } from "../../../../interfaces/interfaces";
import { paginate } from "../../../../utils/paginationUtil";
import styles from "./clients.module.scss";
import { showScreenLoaderAction } from "../../../Loader/loaderActions";
import ClientsTable from "./sections/table/ClientsTable";
import Modals from "./sections/modals/Modals";
import { tiersSelectors } from "../../../PlatformConfig/store";

export type ClientsType = IClientModel[] | undefined;
export type ClientType = IClientModel | undefined;

const Clients: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Selectors
  const getClientsDataStatus = useSelector(
    clientsSelectors.getClientsDataStatus
  );
  const clientsList = useSelector(clientsSelectors.getClients);
  const availableScopes = useSelector(sessionSelectors?.getAllowedScopes);
  const userTenant = useSelector(sessionSelectors?.getUserTenant);
  const tiers = useSelector(tiersSelectors.getTiers);

  // Variables
  const hasReadScope = !!userHasScopes(clientsScopes?.read, availableScopes);
  const canDelete = !!userHasScopes(clientsScopes?.delete, availableScopes);
  const isLoading = getClientsDataStatus || !clientsList;

  // States
  const [paginatedClients, setPaginatedClients] = useState(new Array());
  const initialPurposeListState = {
    searched: "",
    searchedResult: paginatedClients?.slice(),
  };
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showActivateSubModal, setShowActivateSubModal] = useState(false);
  const [clients, setClients] = useState<ClientsType>(clientsList);
  const [totalItems, setTotalItems] = useState(clients?.length);
  const [selectedClient, setSelectedClient] = useState<ClientType>(undefined);
  const [showSuspendWarning, setShowSuspendModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOrgId, setSelectedOrg] = useState(undefined);
  const [state, setState] = useState<ISearchState>(initialPurposeListState);

  // Effects
  useEffect(() => {}, [selectedClient]);

  useEffect(() => {
    !getClientsDataStatus && dispatch(getOrganizationsAction());
  }, []);

  useEffect(() => {
    setClients(clientsList);
    setTotalItems(clientsList?.length);
    setPaginatedClients(paginate(clientsList, pageSize, pageIndex));
  }, [clientsList]);

  useEffect(() => {
    setState(initialPurposeListState);
  }, [paginatedClients]);

  // Functions
  const handleSearchedChange = (items: any[]) => {
    if (!!items) {
      setState({
        searched: state.searched,
        searchedResult: paginate(items, pageSize, 1),
      });
      setTotalItems(items.length);
    }
  };

  const handlePageChange = (e: any) => {
    setPageIndex(e.page);
    setPageSize(e.pageSize);
    setPaginatedClients(paginate(clients, e.pageSize, e.page));
    setState(initialPurposeListState);
  };

  const suspendOrg = () => {
    dispatch(showScreenLoaderAction());
    dispatch(suspendSubscriptionAction(selectedOrgId || ""));
    setShowSuspendModal(false);
  };

  const deleteOrg = () => {
    dispatch(showScreenLoaderAction());
    dispatch(deleteOrgAction(selectedOrgId || ""));
    setSelectedOrg(undefined);
    setShowDeleteModal(false);
  };

  const seeItemDetails = (item: IClientModel) => {
    dispatch(setSelectedClientAction(item));
  };

  // ButtonsData
  const changeSubModalButtons = {
    primary: {
      label: "clientDetail.changeSubscription",
    },
    secondary: {
      label: "general.cancel",
      action: () => {
        setShowActivateSubModal(false);
      },
    },
  };

  const suspendModalButtons = {
    primary: { label: "clients.suspend", action: suspendOrg },
    secondary: {
      label: "general.noGoBack",
      action: () => {
        setShowSuspendModal(false), setSelectedOrg(undefined);
      },
    },
  };

  const deleteModalButtons = {
    primary: { label: "clients.yesKillNow", action: deleteOrg },
    secondary: {
      label: "general.noGoBack",
      action: () => {
        setShowDeleteModal(false), setSelectedOrg(undefined);
      },
    },
  };

  // View
  return (
    <PanelScafoldingPrivate>
      <ScreenHeader
        title={"clients.screenTitle"}
        subText="clients.description"
      />

      {hasReadScope ? (
        <>
          <SearchableListHeader
            title={""}
            isLoading={isLoading}
            options={clients}
            propertiesToSearch={propertiesToSearch}
            handleSearchedChange={handleSearchedChange}
          />

          {!isLoading ? (
            !!clients && clients?.length && !!clients[0] ? (
              <>
                <ClientsTable
                  userTenant={userTenant}
                  tableData={state.searchedResult}
                  paginationData={{
                    page: pageIndex,
                    totalItems: totalItems || 0,
                    onHandleChange: (e) => handlePageChange(e),
                  }}
                  deleteItem={(item) => (
                    setSelectedOrg(item?.orgId), setShowDeleteModal(true)
                  )}
                  cancelItem={(item) => (
                    setSelectedOrg(item?.orgId),
                    setSelectedClient(item),
                    setShowSuspendModal(true)
                  )}
                  activateItem={(item) => (
                    setSelectedClient(item), setShowActivateSubModal(true)
                  )}
                  editItem={seeItemDetails}
                  noResults={
                    clients &&
                    clients?.length > 0 &&
                    !!state.searchedResult &&
                    !state.searchedResult?.length
                  }
                  canDelete={canDelete}
                />

                <Modals
                  showSuspendWarning={showSuspendWarning}
                  selectedClient={selectedClient}
                  suspendModalButtons={suspendModalButtons}
                  selectedOrgId={selectedOrgId}
                  deleteModalButtons={deleteModalButtons}
                  tiers={tiers}
                  changeSubModalButtons={changeSubModalButtons}
                  showActivateSubModal={showActivateSubModal}
                  showDeleteModal={showDeleteModal}
                  setSelectedOrg={setSelectedOrg}
                  setShowDeleteModal={setShowDeleteModal}
                  setShowSuspendModal={setShowSuspendModal}
                  setShowActivateSubModal={setShowActivateSubModal}
                />
              </>
            ) : (
              <Description text={t("clients.noClientsYet")} />
            )
          ) : (
            <TableSkeleton
              className={styles.skeleton}
              columnsNumber={3}
              rowsNumber={10}
              responsiveCards={true}
            />
          )}
        </>
      ) : (
        <>
          <PermissionsPanel scopes={clientsScopes?.read} readScope />
        </>
      )}
    </PanelScafoldingPrivate>
  );
};

Clients.defaultProps = {};

export default Clients;
