import { Form } from "carbon-components-react";
import cx from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import formPanelStyles from "./formPanel.module.scss";
import {
  ButtonModel,
  LinkModel,
  StepModel,
  ctaLinkModel,
} from "../../../../interfaces/interfaces";
import GaImage from "../../../atoms/Image/Image";
import Description from "../../../atoms/texts/Description/Description";
import GreyButton from "../../../atoms/buttons/grey/GreyButton";
import PurpleButton from "../../../atoms/buttons/purple/PurpleButton";

type FormPanelProps = {
  topLink?: LinkModel;
  title?: string;
  stepsParams?: {
    steps: StepModel[];
    steperFunction: Function;
  };
  legend?: string;
  link?: LinkModel;
  primaryButton: ButtonModel;
  primaryFunction: Function | void;
  primaryButtonDisabled?: boolean;
  ctaLink?: ctaLinkModel;
  ctaSeparator?: string;
  secondaryButton?: ButtonModel | undefined;
  secondaryFunction?: Function | void;
  children?: React.ReactNode;
  onSubmit?: (e?: any) => void;
};

const FormPanel: React.FC<FormPanelProps> = React.memo((props) => {
  const {
    topLink,
    title,
    legend,
    link,
    primaryButton,
    primaryFunction,
    primaryButtonDisabled,
    ctaLink,
    ctaSeparator,
    secondaryButton,
    onSubmit,
    secondaryFunction,
    children,
  } = props;
  const { t } = useTranslation();

  const handleKeyPress = (e: any) => {
    if (e.keyCode === 13 || e.which === 13) {
      return onSubmit ? onSubmit(e) : null;
    }
  };

  return (
    <>
      <div className={formPanelStyles.formContainer}>
        {topLink ? (
          <div className={cx("text-align-left")}>
            <Link className={formPanelStyles.topLink} to={topLink.route}>
              {t(topLink.label)}
            </Link>
          </div>
        ) : null}
        <div className={formPanelStyles.formContainer__panel}>
          {title ? (
            <h3 className={cx("neutral1000 heading6")}>{t(title)}</h3>
          ) : null}

          <Form onSubmit={onSubmit} onKeyDown={handleKeyPress}>
            {legend ? (
              <Description
                className={cx(
                  "neutral700 bodyRegularSM",
                  formPanelStyles.legend
                )}
                text={legend}
              />
            ) : null}

            {children}

            <div>
              {link ? (
                <Link className={formPanelStyles.link} to={link.route}>
                  {t(link.label)}
                </Link>
              ) : null}
            </div>

            <div className={formPanelStyles.ctaBar}>
              {ctaLink ? (
                <Link className={formPanelStyles.ctaLink} to={ctaLink.route}>
                  {ctaLink.image ? <GaImage image={ctaLink.image} /> : null}
                  <p>{t(ctaLink.label)}</p>
                </Link>
              ) : null}
              {ctaSeparator ? (
                <div className={formPanelStyles.ctaSeparator}>
                  <span className={formPanelStyles.ctaSeparator__text}>
                    {t(ctaSeparator)}
                  </span>
                </div>
              ) : null}
              {secondaryButton ? (
                <GreyButton
                  outlined
                  className={formPanelStyles.ctaLink}
                  action={secondaryFunction ? secondaryFunction : null}
                  label={secondaryButton.label}
                  icon={secondaryButton.icon}
                  disabled={false}
                />
              ) : null}
              <PurpleButton
                className={`${formPanelStyles.ctaButton} ${
                  primaryButtonDisabled ? formPanelStyles.disabled : ""
                }`}
                action={primaryFunction ? primaryFunction : null}
                label={t(primaryButton.label)}
                icon={primaryButton.icon}
                disabled={primaryButtonDisabled || false}
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
});

FormPanel.defaultProps = {};

export default FormPanel;
