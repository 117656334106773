export const tierOrderOptions = [
  {
    text: "general.first",
    value: "0",
  },
  {
    text: "general.second",
    value: "1",
  },
  {
    text: "general.third",
    value: "2",
  },
  {
    text: "general.fourth",
    value: "3",
  },
];
