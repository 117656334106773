import React from "react";
import style from "./cardListSkeleton.module.scss";

type CardListSkeletonProps = {
  cardsNumber: number;
  className?: string;
};

const CardListSkeleton: React.FC<CardListSkeletonProps> = (props) => {
  const { cardsNumber, className } = props;

  const cards = Array.from(Array(cardsNumber), (_, x) => x);

  return (
    <>
      {cards?.map((i, index) => (
        <div
          key={"card-skeleton" + index.toString()}
          className={`${style.cardListSkeleton} ${style.skeleton} ${
            className ? className : ""
          }`}
        >
          <br />
        </div>
      ))}
    </>
  );
};

export default CardListSkeleton;
