import cx from "classnames";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import styles from "./permissionsPanel.module.scss";
import GaImage from "../../../atoms/Image/Image";
import { gaImages } from "../../../../data/images";

type IPermissionsPanelProps = {
  scopes?: string[];
  title?: string;
  labelText?: string;
  content?: string;
  readScope?: boolean;
  requiredLabel?: boolean;
  specialViewPermission?: boolean;
};

const PermissionsPanel: React.FC<IPermissionsPanelProps> = (props) => {
  const {
    scopes,
    title,
    labelText,
    requiredLabel,
    content,
    readScope,
    specialViewPermission,
  } = props;
  const { t } = useTranslation();

  return (
    <>
      {labelText && (
        <label
          className={`${
            requiredLabel ? styles.permissionsRequiredInputLabel : ""
          } ${styles.permissionsInputLabel}`}
        >
          {t(labelText || "")}
        </label>
      )}
      <div
        className={`${styles.permissionsPanel} ${
          specialViewPermission
            ? cx("margin-bottom-min", "margin-top-min")
            : !labelText
            ? cx("margin-top")
            : ""
        }`}
      >
        <div className={styles.permissionsPanel__header}>
          <GaImage image={gaImages.failCircle} />
          <p className={styles.permissionsPanel__header__title}>
            <Trans
              i18nKey={
                title
                  ? title
                  : readScope
                  ? "public.permissionsWarningRead"
                  : !specialViewPermission
                  ? "public.permissionsWarning"
                  : "public.viewSSIConfigPermissionWarning"
              }
              values={{ scopes: scopes?.join(", ") }}
            />
          </p>
          {content && (
            <p className={styles.permissionsPanel__header__content}>
              <Trans
                i18nKey={content}
                values={{ scopes: scopes?.join(", ") }}
              />
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default PermissionsPanel;
