import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./modalDatePicker.module.scss";
import { userHasScopes } from "../../../../../utils/permissionsUtil";
import { sessionSelectors } from "../../../../../features/Settings/Profile/store";
import InputSkeleton from "../../../skeletons/inlineSkeleton/InputSkeleton";
import PermissionsPanel from "../../../panels/permissionsPanel/PermissionsPanel";
import { PermissionsDataModel } from "../../../../../interfaces/interfaces";
import moment, { Moment } from "moment";
import cx from "classnames";
import { DateRange } from "react-date-range";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

// @ts-ignore
export type dateState = {
  startDate?: Date;
  endDate?: Date | null;
  key: string;
}[];

const ModalDatePicker: React.FC = (props: any) => {
  const [state, setState] = useState<dateState>([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  return (
    <div className={styles.modalDatePicker}>
      <DateRange
        color={"#fabada"}
        editableDateInputs={true}
        // @ts-ignore
        onChange={(item) => setState([item.selection])}
        moveRangeOnFirstSelection={false}
        // @ts-ignore
        ranges={state}
      />
    </div>
  );
};

export default ModalDatePicker;
