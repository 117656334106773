import React from "react";
import cx from "classnames";

type AmountCellProps = {
  amount?: number;
  status?: string;
};

export const AmountCell: React.FC<AmountCellProps> = (props) => {
  const { amount } = props;

  return (
    <td>
      <span className={cx("buttonMD neutral1000")}>
        {amount ? amount / 100 + "€" : "-"}
      </span>
    </td>
  );
};
