import * as React from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import styles from "./homeBlocks.module.scss";
import {
  formatNumberToCmpct,
  formatNumberToEU,
} from "../../../../utils/formatDataUtil";
import CardListSkeleton from "../../../../components/elements/skeletons/cardListSkeleton/CardListSkeleton";
import PercentagesHomeBlocks from "../percentagesFeatures/percentagesHomeBlocks/percetagesHomeBlocks";
import { IPercentageVariationChart } from "../../../../interfaces/interfaces";
import { formatNumbersDefaultOptions } from "../../../../data/common";

export type IHomeBlocksProps = {
  statsNumberApi?: {
    activeUsers: number;
    apiKeys: number;
    dids: number;
    issuanceTemplates: number;
    issuedCredentials: number;
    verifierTemplates: number;
  };
  featuresPercentages?: IPercentageVariationChart;
  blocksData: {
    id: string;
    classNameBox: string;
    classNameSubBox: string;
    title: string;
    statsNumber?: number;
  }[];
  dataLoading?: boolean;
  selectedPeriodDays: string;
  clientsTotal?: number;
};

const HomeBlocks: React.FC<IHomeBlocksProps> = (props) => {
  const {
    blocksData,
    statsNumberApi,
    dataLoading,
    selectedPeriodDays,
    featuresPercentages,
    clientsTotal,
  } = props;
  const { t } = useTranslation();

  return (
    <div className={styles.homeBlocks__container}>
      {!dataLoading ? (
        statsNumberApi &&
        blocksData?.map((el, index) => {
          const { id, classNameBox, classNameSubBox, title, statsNumber } = el;

          const number =
            id === "clients"
              ? clientsTotal || 0
              : (statsNumberApi &&
                  statsNumberApi[id as keyof typeof statsNumberApi]) ||
                0;
          const percentage =
            (featuresPercentages &&
              featuresPercentages[id as keyof typeof featuresPercentages]) ||
            undefined;
          return (
            <div
              key={"block__" + index}
              id={id}
              className={`${styles.homeBlocks__each} ${
                classNameBox && classNameBox
              }`}>
              <div className={styles.homeBlocks__each_container}>
                <div
                  className={`${styles.homeBlocks__each_title} ${cx(
                    "heading6"
                  )}`}>
                  {t(title)}
                </div>
                <div className={styles.homeBlocks__each_data}>
                  {statsNumberApi && (
                    <div
                      className={`${styles.each_data__number} ${cx(
                        "heading3"
                      )}`}
                      aria-label={
                        number >= 1000 ? formatNumberToEU(number) : ""
                      }
                      aria-hidden={number >= 1000 ? false : true}
                      tooltip-position="top">
                      {formatNumberToCmpct(number, formatNumbersDefaultOptions)}
                    </div>
                  )}
                  <PercentagesHomeBlocks
                    classNameSubBox={classNameSubBox}
                    selectedPeriodDays={selectedPeriodDays}
                    percentage={percentage}
                  />
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <CardListSkeleton
          cardsNumber={3}
          className={`${styles.homeBlocks__each} ${styles.skeleton}`}
        />
      )}
    </div>
  );
};

export default HomeBlocks;
