import React from "react";
import cx from "classnames";
import PurpleButton from "../../../../atoms/buttons/purple/PurpleButton";
import { IPriceModel, ITierModel } from "../../../../../interfaces/interfaces";
import styles from "./previewLicenseCard.module.scss";
import Tag from "../../../../atoms/chips/Tag";
import { t } from "i18next";
import { getValueForURL } from "../../../../../utils/formatDataUtil";
import { Link } from "react-router-dom";
import LicenseCardFeature from "../licenseCardFeature/LicenseCardFeature";

type IPreviewLicenseCardProps = {
  license: ITierModel | any;
  period: string;
  isCurrentLicense?: boolean;
  showEditButton?: boolean;
  editLicense?: (x?: any) => void;
};

const PreviewLicenseCard: React.FC<IPreviewLicenseCardProps> = (props) => {
  const { license, period, isCurrentLicense, showEditButton, editLicense } =
    props;

  const getPrice = (item: any) => {
    const price = item?.prices?.find(
      (price: IPriceModel) => price?.recurringInterval === period
    );
    return price?.amount || 0;
  };

  return (
    <div
      className={`${styles?.prevLicense} ${
        isCurrentLicense ? styles?.currentLicense : ""
      }`}>
      <div className={styles?.prevLicense__header}>
        {license?.isPopular && (
          <Tag
            text={"Popular"}
            className={styles?.prevLicense__header__popularTag}
            color={"blue"}
          />
        )}
        <p
          className={`${styles?.prevLicense__header__title} ${cx(
            "heading6",
            license?.isPopular && styles.withTag
          )}`}>
          {license?.name}
        </p>
      </div>
      {license?.description && (
        <p
          className={`${styles?.prevLicense__description} ${cx(
            "marginTop8 bodyRegularSM neutral700"
          )}`}>
          {license?.description}
        </p>
      )}

      <div className={`${styles?.prevLicense__price}`}>
        <p className={`${cx("heading3")}`}>
          <span>{getPrice(license) || 0}€</span>
          <span className={`${cx("neutral700 bodyRegularSM marginLeft8")}`}>
            {"/ " + t(`general.${period}`)}
          </span>
        </p>
      </div>

      <div className={styles?.prevLicense__features}>
        <div>
          <p
            className={`${styles?.prevLicense__features__title} ${styles?.verificationFeatures} ${cx(
              "bodyBoldXS neutral700"
            )}`}>
            {t("platformConfig.subcriptionTiers.verificationFeatures")}
          </p>
          <ul className={styles?.prevLicense__features__list}>
            <LicenseCardFeature
              featureCode={"Cnt1"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.verificationTemplates")}
            />
            <LicenseCardFeature
              featureCode={"Cnt2"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.activeUsers")}
              info={t("platformConfig.tierDetail.activeUsersInfo")}
            />
          </ul>
        </div>
        <div>
          <p
            className={`${styles?.prevLicense__features__title}   ${styles?.issuanceFeatures} ${cx(
              " bodyBoldXS neutral700"
            )}`}>
            {t("platformConfig.subcriptionTiers.issuanceFeatures")}
          </p>
          <ul className={styles?.prevLicense__features__list}>
            <LicenseCardFeature
              featureCode={"Ctf1"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.issuanceTemplates")}
            />

            <LicenseCardFeature
              featureCode={"Ctf2"}
              limits={license?.limits}
              label={t("platformConfig.subcriptionTiers.issuedCredentials")}
              info={t("platformConfig.tierDetail.issuedCredentialsInfo")}
            />
          </ul>
        </div>
        <div>
          <p
            className={`${styles?.prevLicense__features__title}  ${styles?.otherFeatures} ${cx(
              " bodyBoldXS neutral700"
            )}`}>
            {t("platformConfig.tierDetail.otherFeaturesPrev")}
          </p>
          <ul className={styles?.prevLicense__features__list}>
            <LicenseCardFeature
              featureCode={"Cmn1"}
              limits={license?.limits}
              label={t("dashboard.dids")}
            />

            <LicenseCardFeature
              featureCode={"Cmn2"}
              limits={license?.limits}
              label={t("dashboard.apiKeys")}
              info={t("platformConfig.tierDetail.apiKeysTemplatesInfo")}
            />
          </ul>
        </div>
      </div>
      {showEditButton && editLicense ? (
        <PurpleButton
          className={styles?.prevLicense__button}
          outlined
          label={t("general.edit")}
          action={() => editLicense && editLicense(license)}
        />
      ) : null}
    </div>
  );
};

export default PreviewLicenseCard;
