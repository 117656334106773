import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageModel } from "../../../interfaces/interfaces";
import SearchIcon from "../../../assets/iconsLibrary/simple/search/searchIcon";
import {
  getSearchableStringfiedOptions,
  latinize,
} from "../../../utils/formatDataUtil";
import style from "./searchBar.module.scss";
import cx from "classnames";

export type IPropertiesToSearch = {
  text: string;
  searchMultipleLanguages: boolean;
  latinize: boolean;
};

export type SearchBarProps = {
  options: any[];
  propertiesToSearch: IPropertiesToSearch[];
  icon?: ImageModel;
  placeholder?: string;
  searched?: string;
  className?: string;
  handleSearchedChange: (e: any) => void;
};

export type SearchState = {
  searchableOptions: any[];
  searched: string;
  searchedResult: any[];
};

const SearchBar: React.FC<SearchBarProps> = React.memo((props) => {
  const {
    options,
    icon,
    placeholder,
    propertiesToSearch,
    searched,
    className,
    handleSearchedChange,
  } = props;

  const { t } = useTranslation();

  const initialState = {
    searchableOptions: [],
    searched: "",
    searchedResult: [],
  };
  const [state, setState] = useState<SearchState>(initialState);

  useEffect(() => {
    setState({
      ...state,
      searchableOptions: getSearchableStringfiedOptions(
        options,
        propertiesToSearch
      ),
    });
  }, [options]);

  const handleTextSearchChange = (e: any) => {
    setState({
      ...state,
      searched: e.target.value,
      searchedResult: state.searchedResult,
    });

    let result = options;
    const searchString = latinize(e.target.value.toString());

    if (searchString && searchString?.length) {
      const filteredOptions = state.searchableOptions?.filter(
        (option) =>
          option._searchableText
            ?.toLowerCase()
            .match(searchString.toLowerCase())
      );
      result = filteredOptions;
    }

    setState({ ...state, searched: e.target.value, searchedResult: result });
    handleSearchedChange(result);
  };

  return (
    <div className={`${style.searchBar} ${className}`}>
      {icon ? (
        <SearchIcon
          className={style.searchBar__icon}
          color="#8C8C90"
          size={16}
        />
      ) : null}
      <input
        type="text"
        className={cx(style.searchBar__input, "bodyRegularMD neutral600")}
        placeholder={t(placeholder || "general.search")}
        value={searched}
        onChange={handleTextSearchChange}
      />
    </div>
  );
});

export default SearchBar;
