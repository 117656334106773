import * as React from "react";
import { Trans } from "react-i18next";
import cx from "classnames";
import styles from "./percentagesChart.module.scss";
import TrendingUpSVG from "../../../../../assets/images/icon-trending-up";
import TrendingDownSVG from "../../../../../assets/images/icon-trending-down";

export type IPercentageChart = {
  selectedPeriodDays: string;
  percentage?: number;
  variation: number;
};
const PercentagesChart: React.FC<IPercentageChart> = (props) => {
  const { selectedPeriodDays, percentage, variation } = props;
  const roundedPercentage = Math.round(percentage || 0);

  return (
    <div className={styles.each_data__stats}>
      <div
        className={`${styles.stats__percentage} ${
          roundedPercentage > 0 ? styles.positiveBg : styles.negativeBg
        }`}
      >
        {roundedPercentage > 0 ? <TrendingUpSVG /> : <TrendingDownSVG />}
        <span className={cx("buttonSM")}>
          <span>
            {variation > 0 ? "+" : ""}
            {variation}
          </span>
          <span>
            ({roundedPercentage > 0 ? "+" : ""}
            {roundedPercentage}%)
          </span>
        </span>
      </div>
      {selectedPeriodDays ? (
        <div className={`${styles.stats__label} ${cx("bodyRegularXS")}`}>
          <Trans
            i18nKey={"dashboard.vsLastDays"}
            components={{}}
            values={{
              days: parseInt(selectedPeriodDays),
            }}
          ></Trans>
        </div>
      ) : null}
    </div>
  );
};

export default PercentagesChart;
