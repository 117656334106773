import cx from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styles from "./rightNav.module.scss";
import { LinkModel } from "../../../../interfaces/interfaces";
import { setSelectedRoute } from "../../../../features/PanelScafolding/store/scafoldingActions";
import ExpandableMenu from "../ExpandableMenu/ExpandableMenu";
import HoverTooltip from "../../tooltips/HoverTooltip/HoverTootltip";
import { gaColors } from "../../../../data/display";
import GaImage from "../../../atoms/Image/Image";
import { deleteSession } from "../../../../features/Settings/Profile/store/sessionActions";
import { history } from "../../../../services/history";
import { sessionSelectors } from "../../../../features/Settings/Profile/store";
import { userHasScopes } from "../../../../utils/permissionsUtil";
import CardListSkeleton from "../../skeletons/cardListSkeleton/CardListSkeleton";

type IRightNavProps = {
  open: Boolean;
  menuOptions: LinkModel[];
  menuExtraMobileOptions?: LinkModel[];
  userEmail: string;
  userImage: string;
  expanded?: string[];
  selected: string;
  setExpanded: (x: any) => void;
};

const RightNav: React.FC<IRightNavProps> = (props) => {
  const {
    open,
    menuOptions,
    menuExtraMobileOptions,
    userEmail,
    userImage,
    expanded,
    selected,
    setExpanded,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes);
  useEffect(() => {}, [allowedScopes]);

  function navigateTo(e: any, url: string) {
    e?.stopPropagation();
    setActiveRoute(url);
    history?.push(url, { replace: true });
  }

  const setActiveRoute = (route: string) => {
    // dispatch(setGeneralDataLoaded(false));
    dispatch(setSelectedRoute(route));
  };

  // allowedScopes
  const getIsActive = (option: LinkModel) => {
    let isActive = false;
    if (allowedScopes && allowedScopes?.length) {
      isActive = userHasScopes(option.scopes, allowedScopes);
    }
    return isActive;
  };

  return (
    <>
      <div
        className={
          (open ? styles.navOpen : styles.navClosed) + " " + styles.sideNav
        }
      >
        <div className={styles.user}>
          <GaImage
            className={styles.userImage}
            image={{ src: userImage, alt: "general.noprofilePic" }}
          />
          <div>
            <p>{t("navigation.welcome")}</p>
            <p>{userEmail}</p>
          </div>
        </div>
        <ul className={styles.sideNav__list}>
          {menuOptions.map((e, index) => {
            return allowedScopes ? (
              e.subRoutes ? (
                <li
                  key={"subRoute" + e?.route + index}
                  className={`${cx(
                    !getIsActive(e) ? styles.sideNav__link__disabled : null
                  )} ${styles.sideNav__link} ${e.subRoutes ? "column" : "row"}`}
                >
                  <ExpandableMenu
                    item={e}
                    expanded={expanded}
                    selected={selected}
                    setExpanded={setExpanded}
                    setSelected={setActiveRoute}
                  />
                </li>
              ) : (
                <li
                  key={"menuOptions" + index}
                  onClick={(event) =>
                    !!getIsActive(e)
                      ? e.id && e.id === "logout"
                        ? dispatch(deleteSession())
                        : !e.route?.includes("no") && navigateTo(event, e.route)
                      : null
                  }
                  className={`${cx(
                    !getIsActive(e) ? styles.sideNav__link__disabled : null
                  )} ${styles.sideNav__link} ${e.subRoutes ? "column" : "row"}`}
                >
                  <div
                    className={`${
                      selected === e.route ||
                      (selected === "profile" && e.route === "settings")
                        ? styles.selected
                        : ""
                    }`}
                  >
                    <div>
                      {!getIsActive(e) ? (
                        <>
                          <HoverTooltip
                            positionMobile={{
                              align: "center",
                              direction: "bottom",
                            }}
                            Label={
                              <>
                                {e.iconComponent ? (
                                  <e.iconComponent size="16" />
                                ) : null}
                                <span className={`${cx("buttonSM")}`}>
                                  {t(e.label)}
                                </span>
                              </>
                            }
                            info={"general.notNecessaryPermission"}
                            blue={true}
                          />
                        </>
                      ) : (
                        <>
                          {e.iconComponent ? (
                            <e.iconComponent
                              size="16"
                              type={selected === e.route && "filled"}
                              color={
                                selected === e.route
                                  ? gaColors?.primary700
                                  : "#1E1E20"
                              }
                            />
                          ) : null}
                          <span className={`${cx("buttonSM")}`}>
                            {t(e.label)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              )
            ) : (
              <CardListSkeleton
                className={styles.optionsSkeleton}
                cardsNumber={2}
              />
            );
          })}
        </ul>

        <ul className={styles.extraMobileOptions + " " + styles.sideNav__list}>
          {menuExtraMobileOptions ? (
            menuExtraMobileOptions.map((e, index) => {
              return e.subRoutes ? (
                <li
                  key={"extraMenu__nav__subRoute" + e?.route + index}
                  className={`${cx(
                    !getIsActive(e) ? styles.sideNav__link__disabled : null
                  )} ${styles.sideNav__link} ${e.subRoutes ? "column" : "row"}`}
                >
                  <ExpandableMenu
                    item={e}
                    expanded={expanded}
                    selected={selected}
                    setExpanded={setExpanded}
                    setSelected={setActiveRoute}
                  />
                </li>
              ) : (
                <li
                  key={"extraMenu__nav__option" + index}
                  onClick={(event) =>
                    getIsActive(e)
                      ? e.id && e.id === "logout"
                        ? dispatch(deleteSession())
                        : !e.route?.includes("no") && navigateTo(event, e.route)
                      : null
                  }
                  className={`${cx(
                    !getIsActive(e) ? styles.sideNav__link__disabled : null
                  )} ${styles.sideNav__link} ${e.subRoutes ? "column" : "row"}`}
                >
                  <div
                    className={`${
                      selected === e.route ||
                      (selected === "profile" && e.route === "settings")
                        ? styles.selected
                        : ""
                    }`}
                  >
                    <div>
                      {!getIsActive(e) ? (
                        <>
                          <HoverTooltip
                            positionMobile={{
                              align: "center",
                              direction: "bottom",
                            }}
                            Label={
                              <>
                                {e.iconComponent ? (
                                  <e.iconComponent
                                    size="16"
                                    type={selected === e.route && "filled"}
                                  />
                                ) : null}
                                <span className={`${cx("buttonSM")}`}>
                                  {t(e.label)}
                                </span>
                              </>
                            }
                            info={"general.notNecessaryPermission"}
                            blue={true}
                          />
                        </>
                      ) : (
                        <>
                          {e.iconComponent ? (
                            <e.iconComponent
                              size="16"
                              type={selected === e.route && "filled"}
                            />
                          ) : null}
                          <span className={`${cx("buttonSM")}`}>
                            {t(e.label)}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              );
              // </>
            })
          ) : (
            <CardListSkeleton
              className={styles.optionsSkeleton}
              cardsNumber={2}
            />
          )}
        </ul>
      </div>
    </>
  );
};

export default RightNav;
