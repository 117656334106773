import add from "../assets/images/add.svg";
import angleDown from "../assets/images/angle-down.svg";
import whiteAngleDown from "../assets/images/angle-down-white.svg";
import apiKey from "../assets/images/api-keys.svg";
import arrowCircleDown from "../assets/images/arrowCircleDown.svg";
import arrowForward from "../assets/images/arrow_forward.svg";
import checkBadge from "../assets/images/check-badge.svg";
import checkCircle from "../assets/images/check-circle.svg";
import check from "../assets/images/check.svg";
import checkbox from "../assets/images/checkbox.svg";
import chevronLeft from "../assets/images/chevron-left.svg";
import chevronRight from "../assets/images/chevron-right.svg";
import clock from "../assets/images/clock.svg";
import closeLight from "../assets/images/close-light.svg";
import whiteClose from "../assets/images/close-white.svg";
import close from "../assets/images/close.svg";
import credential from "../assets/images/credential.svg";
import credentialsLight from "../assets/images/credentials-light.svg";
import credentials from "../assets/images/credentials.svg";
import did from "../assets/images/did.svg";
import editCard from "../assets/images/edit-card.svg";
import errorCircle from "../assets/images/errorCircle.svg";
import failCircle from "../assets/images/failCircle.svg";
import helpCircle from "../assets/images/helpCircle.svg";
import info from "../assets/images/info.svg";
import infoCircle from "../assets/images/infoCircle.svg";
import infoTable from "../assets/images/infoTable.svg";
import issuance from "../assets/images/issuance.svg";
import lightPlus from "../assets/images/light-plus.svg";
import link from "../assets/images/link.svg";
import loadingCircle from "../assets/images/loadingCircle.svg";
import logoPlatform from "../assets/images/logo-gataca-v2.png";
import logoGataca from "../assets/images/logo_gataca.svg";
import number from "../assets/images/number.svg";
import plus from "../assets/images/plus.svg";
import redWarning from "../assets/images/redWarning.svg";
import rightArrow from "../assets/images/rightArrow.svg";
import searchBar from "../assets/images/search-bar.svg";
import settings from "../assets/images/settingsMenuIcon.svg";
import textFile from "../assets/images/textFile.svg";
import verifier from "../assets/images/verifier.svg";
import warningFlag from "../assets/images/warning-flag.svg";
import warning from "../assets/images/warning.svg";
import appleBrandButton from "../assets/modalImages/appleBrandButton.svg";
import blackTrashPng from "../assets/modalImages/blackTrash.png";
import blackTrashSvg from "../assets/modalImages/blackTrash.svg";
import googleBrandButton from "../assets/modalImages/googleBrandButton.svg";
import greenArrowsPng from "../assets/modalImages/greenArrows.png";
import greenArrowsSvg from "../assets/modalImages/greenArrows.svg";
import greenCheckPng from "../assets/modalImages/greenCheck.png";
import greenCheckSvg from "../assets/modalImages/greenCheck.svg";
import greenMailCloudPng from "../assets/modalImages/greenMailCloud.png";
import greenMailCloudSvg from "../assets/modalImages/greenMailCloud.svg";
import greyTrashPng from "../assets/modalImages/greyTrash.png";
import greyTrashSvg from "../assets/modalImages/greyTrash.svg";
import redArrowsPng from "../assets/modalImages/redArrows.png";
import redArrowsSvg from "../assets/modalImages/redArrows.svg";
import redErrorPng from "../assets/modalImages/redError.png";
import redErrorSvg from "../assets/modalImages/redError.svg";
import unlockImgBlue from "../assets/modalImages/unlock-img-blue.png";
import smartphoneIcon from "../assets/iconsLibrary/simple/smartphone/smartphoneIcon.svg";
import mapPinIcon from "../assets/iconsLibrary/simple/mapPin/mapPinIcon.svg";
import governmentIcon from "../assets/iconsLibrary/simple/government/governmentIcon.svg";
import idIconDefault from "../assets/iconsLibrary/id/default/idIcon.svg";
import educationIcon from "../assets/iconsLibrary/simple/education/educationIcon.svg";
import profilePhotoIcon from "../assets/iconsLibrary/simple/profilePhoto/profilePhotoIcon.svg";
import chipIcon from "../assets/iconsLibrary/simple/chip/chipIcon.svg";
import awardIcon from "../assets/iconsLibrary/simple/award/awardIcon.svg";
import atIcon from "../assets/iconsLibrary/simple/at/atIcon.svg";
import credentialIconDef from "../assets/iconsLibrary/credential/default/credentialIcon.svg";
import healthIcon from "../assets/iconsLibrary/simple/health/healthIcon.svg";
import moreVerticalIcon from "../assets/iconsLibrary/simple/moreVertical/moreVerticalIcon.svg";
import pencilIcon from "../assets/iconsLibrary/simple/pencil/pencilIcon.svg";
import checkIcon from "../assets/iconsLibrary/simple/check/checkIcon.svg";
import chevronUp from "../assets/iconsLibrary/simple/chevronUp/chevronUpIcon.svg";
import chevronDown from "../assets/iconsLibrary/simple/chevronDown/chevronDownIcon.svg";
import warningIcon from "../assets/iconsLibrary/simple/warning/warningIcon.svg";
import unlockImg from "../assets/modalImages/unlock-img.png";
import updatePng from "../assets/modalImages/update.png";
import updateSvg from "../assets/modalImages/update.svg";
import yellowWarningPng from "../assets/modalImages/yellowWarning.png";
import yellowWarningSvg from "../assets/modalImages/yellowWarning.svg";
import redWarningSvg from "../assets/images/warning-red.svg";
import redWarningPng from "../assets/images/warning-red.png";
import skullPng from "../assets/images/skull.png";
import iconTrendingDown from "../assets/images/icon-trending-down.svg";
import iconTrendingUp from "../assets/images/icon-trending-up.svg";

export const gaImages = {
  arrowForward: { src: arrowForward, alt: "images.arrowForwardIcon" },
  angleDown: { src: angleDown, alt: "images.angleDownIcon" },
  whiteAngleDown: { src: whiteAngleDown, alt: "images.angleDownIcon" },
  credential: { src: credentials, alt: "images.credentialIcon" },
  credentials: { src: credential, alt: "images.credentialIcon" },
  verifier: { src: verifier, alt: "images.verifierIcon" },
  close: { src: close, alt: "images.closeIcon" },
  plus: { src: plus, alt: "images.plusIcon" },
  searchBar: { src: searchBar, alt: "images.searchIcon" },
  checkCircle: { src: checkCircle, alt: "images.circleWidthCheckIcon" },
  checkBadge: { src: checkBadge, alt: "images.badgeCheckIcon" },
  issuance: { src: issuance, alt: "images.issuanceIcon" },
  apiKeys: { src: apiKey, alt: "images.keyIcon" },
  did: { src: did, alt: "images.didIcon" },
  info: { src: info, alt: "images.infoIcon" },
  helpCircle: { src: helpCircle, alt: "images.supportIcon" },
  editCard: { src: editCard, alt: "images.editCardIcon" },
  number: { src: number, alt: "images.numberIcon" },
  link: { src: link, alt: "images.linkIcon" },
  arrowCircleDown: {
    src: arrowCircleDown,
    alt: "images.arrowCircleDownIcon",
  },
  checkbox: { src: checkbox, alt: "images.checkboxIcon" },
  clock: { src: clock, alt: "images.clockIcon" },
  textFile: { src: textFile, alt: "images.textFileIcon" },
  closeLight: { src: closeLight, alt: "images.closeIcon" },
  loadingCircle: { src: loadingCircle, alt: "images.loading" },
  infoTable: { src: infoTable, alt: "images.menu" },
  credentialsLight: { src: credentialsLight, alt: "images.credentialsIcon" },
  warning: { src: warning, alt: "images.error" },
  lightPlus: { src: lightPlus, alt: "images.plusIcon" },
  whiteClose: { src: whiteClose, alt: "images.closeIcon" },
  check: { src: check, alt: "images.checkIcon" },
  rightArrow: { src: rightArrow, alt: "images.arrowForwardIcon" },
  warningFlag: { src: warningFlag, alt: "images.warning" },
  failCircle: { src: failCircle, alt: "images.warning" },
  infoCircle: { src: infoCircle, alt: "images.warning" },
  settingsIcon: { src: settings, alt: "images.appsIcon" },
  chevronRight: { src: chevronRight, alt: "Right Icon" },
  chevronLeft: { src: chevronLeft, alt: "Left Icon" },
  addIcon: { src: add, alt: "Add Icon" },
  errorCircleIcon: { src: errorCircle, alt: "Error Icon" },
  redWarning: { src: redWarning, alt: "Warning icon" },
  greenCheckPng: { src: greenCheckPng, alt: "" },
  greenCheckSvg: { src: greenCheckSvg, alt: "" },
  redErrorPng: { src: redErrorPng, alt: "" },
  redErrorSvg: { src: redErrorSvg, alt: "" },
  updatePng: { src: updatePng, alt: "" },
  updateSvg: { src: updateSvg, alt: "" },
  greenArrowsPng: { src: greenArrowsPng, alt: "" },
  greenArrowsSvg: { src: greenArrowsSvg, alt: "" },
  redArrowsPng: { src: redArrowsPng, alt: "" },
  redArrowsSvg: { src: redArrowsSvg, alt: "" },
  greenMailCloudPng: { src: greenMailCloudPng, alt: "" },
  greenMailCloudSvg: { src: greenMailCloudSvg, alt: "" },
  blackTrashPng: { src: blackTrashPng, alt: "" },
  blackTrashSvg: { src: blackTrashSvg, alt: "" },
  yellowWarningPng: { src: yellowWarningPng, alt: "" },
  yellowWarningSvg: { src: yellowWarningSvg, alt: "" },
  greyTrashPng: { src: greyTrashPng, alt: "" },
  greyTrashSvg: { src: greyTrashSvg, alt: "" },
  unlockImg: { src: unlockImg, alt: "" },
  unlockImgBlue: { src: unlockImgBlue, alt: "" },
  googleBrandButton: { src: googleBrandButton, alt: "" },
  appleBrandButton: { src: appleBrandButton, alt: "" },
  smartphoneIcon: { src: smartphoneIcon, alt: "" },
  mapPinIcon: { src: mapPinIcon, alt: "" },
  governmentIcon: { src: governmentIcon, alt: "" },
  idIconDefault: { src: idIconDefault, alt: "" },
  educationIcon: { src: educationIcon, alt: "" },
  profilePhotoIcon: { src: profilePhotoIcon, alt: "" },
  chipIcon: { src: chipIcon, alt: "" },
  awardIcon: { src: awardIcon, alt: "" },
  atIcon: { src: atIcon, alt: "" },
  credentialIconDefault: { src: credentialIconDef, alt: "" },
  healthIcon: { src: healthIcon, alt: "" },
  moreVerticalIcon: { src: moreVerticalIcon, alt: "" },
  pencilIcon: { src: pencilIcon, alt: "" },
  checkIcon: { src: checkIcon, alt: "" },
  logoGataca: { src: logoGataca, alt: "" },
  chevronUpIcon: { src: chevronUp, alt: "" },
  chevronDownIcon: { src: chevronDown, alt: "" },
  warningIcon: { src: warningIcon, alt: "" },
  logoPlatform: { src: logoPlatform, alt: "images.gatacaLogo" },
  redWarningSvg: { src: redWarningSvg, alt: "" },
  redWarningPng: { src: redWarningPng, alt: "" },
  skullPng: { src: skullPng, alt: "" },
  iconTrendingDown: { src: iconTrendingDown, alt: "images.iconTrendingDown" },
  iconTrendingUp: { src: iconTrendingUp, alt: "images.iconTrendingUp" },
};
