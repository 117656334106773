import { combineReducers } from "redux";
import { navigationReducer } from "../features/PanelScafolding/store";
import { loaderReducer } from "../features/Loader/store";
import { notificationReducer } from "../features/Notification/store";
import { sessionReducer } from "../features/Settings/Profile/store";
import { loginReducer } from "../features/Login/views/Login/store";
import { usersReducer } from "../features/Settings/Users/store";
import {
  licensesReducer,
  organizationsReducer,
  subscriptionsReducer,
} from "../features/Clients/store";
import { clientsReducer } from "../features/Clients/store/clientsReducers";
import { statisticsReducer } from "../features/Home/store";
import { statisticsMetricsReducer } from "../features/Clients/views/detail/sections/metrics/store/metricsReducers";
import { tiersReducer } from "../features/PlatformConfig/store";

const rootState = {
  navigationReducer,
  loaderReducer,
  loginReducer,
  notificationReducer,
  sessionReducer,
  usersReducer,
  organizationsReducer,
  licensesReducer,
  subscriptionsReducer,
  clientsReducer,
  statisticsReducer,
  statisticsMetricsReducer,
  tiersReducer,
};

const rootReducer = combineReducers(rootState);

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
