import { initialLang } from "../i18n";
import { getDefaultCountry } from "./formatDataUtil";

/**
 * Returns the country of an org or the default country translated
 */
export const getOrgCountryTranslated = (
  deafultCountryIndex: number,
  orgCountry?: {
    name_es: string;
    name_en: string;
    name_pt: string;
    states: string[];
  }
) =>
  orgCountry &&
  initialLang &&
  orgCountry[("name_" + initialLang) as keyof typeof orgCountry]
    ? orgCountry[("name_" + initialLang) as keyof typeof orgCountry]?.toString()
    : orgCountry?.name_es || getDefaultCountry(deafultCountryIndex)?.toString();
