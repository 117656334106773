import { ActionStatusTypes } from "../../../data/common";
import { MetricStatistic, Statistics } from "../../../interfaces/interfaces";

export interface IHomeState {
  getStaticsStatus: ActionStatusTypes;
  statistics?: Statistics;
  selectedPeriodIsYear?: boolean;
  clientStats?: MetricStatistic;
}
export enum StatisticsTypes {
  GET_ALL_STATISTICS = "stats/get",
  GET_ALL_STATISTICS_SUCCESS = "stats/get_success",
  GET_ALL_STATISTICS_FAILED = "stats/get_failed",
  GET_CLIENTS_STATISTICS = "stats/get_clients",
  GET_CLIENTS_STATISTICS_SUCCESS = "stats/get_clients_success",
  GET_CLIENTS_STATISTICS_FAILED = "stats/get_clients_failed",
  SET_SELECTED_PERIOD_YEAR = "stats/set_selected_period_year",
}
export interface IGetStatistics {
  type: StatisticsTypes.GET_ALL_STATISTICS;
  payload: { params: string };
}

export interface IGetStatisticsSuccess {
  type: StatisticsTypes.GET_ALL_STATISTICS_SUCCESS;
  payload: {
    statistics: Statistics;
  };
}

export interface IGetStatisticsFailed {
  type: StatisticsTypes.GET_ALL_STATISTICS_FAILED;
  payload: {
    error: string;
  };
}

export interface IGetClientsStatistics {
  type: StatisticsTypes.GET_CLIENTS_STATISTICS;
  payload: { params: string };
}

export interface IGetClientsStatisticsSuccess {
  type: StatisticsTypes.GET_CLIENTS_STATISTICS_SUCCESS;
  payload: {
    statistics: MetricStatistic;
  };
}

export interface IGetClientsStatisticsFailed {
  type: StatisticsTypes.GET_CLIENTS_STATISTICS_FAILED;
  payload: {
    error: string;
  };
}

export interface ISetSelectedPeriodYear {
  type: StatisticsTypes.SET_SELECTED_PERIOD_YEAR;
  payload: { isYear: boolean };
}

export type IHomeActions =
  | IGetStatistics
  | IGetStatisticsSuccess
  | IGetStatisticsFailed
  | ISetSelectedPeriodYear
  | IGetClientsStatistics
  | IGetClientsStatisticsSuccess
  | IGetClientsStatisticsFailed;
