import * as React from "react";
import { Trans } from "react-i18next";
import cx from "classnames";
import styles from "./percentagesHomeBlocks.module.scss";
import TrendingUpSVG from "../../../../../assets/images/icon-trending-up";
import TrendingDownSVG from "../../../../../assets/images/icon-trending-down";

export type IPercentagesHomeBlocksProps = {
  selectedPeriodDays: string;
  classNameSubBox: string;
  percentage?: number;
};

const PercentagesHomeBlocks: React.FC<IPercentagesHomeBlocksProps> = (
  props
) => {
  const { selectedPeriodDays, classNameSubBox, percentage } = props;

  const roundedPercentage = Math.round(percentage || 0);

  return percentage ? (
    <div className={styles.each_data__stats}>
      <div
        className={`${styles.stats__percentage} ${
          classNameSubBox && classNameSubBox
        }`}
      >
        {roundedPercentage > 0 ? <TrendingUpSVG /> : <TrendingDownSVG />}
        <span className={cx("buttonSM")}>
          {roundedPercentage > 0 ? "+" : ""}
          {roundedPercentage} %
        </span>
      </div>
      <div className={`${styles.stats__label} ${cx("bodyRegularXS")}`}>
        <Trans
          i18nKey={"dashboard.vsLastDays"}
          components={{}}
          values={{
            days: selectedPeriodDays,
          }}
        ></Trans>
      </div>
    </div>
  ) : null;
};

export default PercentagesHomeBlocks;
