import { Epic, ofType } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, delay, map, switchMap } from "rxjs/operators";
import dataService from "../../../services/dataService";
import {
  getClientStatisticsFailed,
  getClientStatisticsSuccess,
  getStatisticsFailed,
  getStatisticsSuccess,
} from "./homeActions";
import { StatisticsTypes } from "./homeTypes";

export const getStatisticsEpic: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(StatisticsTypes.GET_ALL_STATISTICS),
    delay(100),
    switchMap((action: any): any => {
      const params = action.payload?.params;
      return from(dataService.getAllStatistics(params)).pipe(
        map((response?: any) => {
          return getStatisticsSuccess(response?.response);
        }),
        catchError((error) => of(getStatisticsFailed(error)))
      );
    })
  );
};

export const getClientStatisticsEpic: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(StatisticsTypes.GET_CLIENTS_STATISTICS),
    delay(100),
    switchMap((action: any): any => {
      const params = action.payload?.params;
      return from(dataService.getClientsStatistics(params)).pipe(
        map((response?: any) => {
          return getClientStatisticsSuccess(response?.response);
        }),
        catchError((error) => of(getClientStatisticsFailed(error)))
      );
    })
  );
};
