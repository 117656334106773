import cx from "classnames";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./expandableMenu.module.scss";
import { LinkModel } from "../../../../interfaces/interfaces";
import HoverTooltip from "../../tooltips/HoverTooltip/HoverTootltip";
import { gaImages } from "../../../../data/images";
import { gaColors } from "../../../../data/display";
import GaImage from "../../../atoms/Image/Image";
import { history } from "../../../../services/history";
import { useSelector } from "react-redux";
import { sessionSelectors } from "../../../../features/Settings/Profile/store";

type IExpandableMenuProps = {
  item: LinkModel;
  expanded?: string[];
  selected: string;
  setExpanded: (x: any) => void;
  setSelected: (x: any) => void;
};

const ExpandableMenu: React.FC<IExpandableMenuProps> = (props) => {
  const { item, expanded, selected, setExpanded, setSelected } = props;

  const { t } = useTranslation();

  const supportLink = process.env.REACT_APP_SUPPORT_URL;
  const allowedScopes = useSelector(sessionSelectors?.getAllowedScopes);

  useEffect(() => {}, [allowedScopes]);

  const getIfIsExpanded = () =>
    !!expanded && !!expanded?.filter((route) => route === item.route)[0];
  const getIfIsSelected = (item: LinkModel) => {
    return (
      selected === item?.route ||
      (item.subRoutes &&
        item.subRoutes?.some((element) => element?.route === selected))
    );
  };

  const setExpandenToggle = (e: any, changeRoute?: boolean) => {
    e?.stopPropagation();
    const isCurrentlyExpanded = getIfIsExpanded();
    let newExpandedValue = [""];
    if (isCurrentlyExpanded) {
      newExpandedValue = expanded
        ? expanded.filter((e) => e !== item.route)
        : [];
    } else {
      newExpandedValue.push(item.route);
    }
    setExpanded(newExpandedValue);
  };

  function navigateTo(e: any, url: string) {
    e?.stopPropagation();
    setSelected(url);
    history?.push(url);
  }

  const handleSelectOption = (e: any, item: LinkModel) => {
    e?.stopPropagation();
    setExpandenToggle(e);
    !item?.route?.includes("no") && setSelected(item.route);
  };

  const getIsActive = (option: LinkModel) => {
    let isActive = false;

    if (option.scopes?.length) {
      if (allowedScopes?.length) {
        isActive = allowedScopes?.some(
          (ai: any) => option.scopes?.includes(ai)
        );
      }
    } else {
      isActive = true;
    }

    return isActive;
  };

  return (
    <>
      <div
        className={`${styles.expandableMenu} ${
          getIfIsSelected(item) ? styles.selected : ""
        }`}
      >
        <div
          className={styles.expandableMenu__text}
          onClick={(e) => handleSelectOption(e, item)}
        >
          {item.iconComponent ? (
            <item.iconComponent
              size="16"
              style={getIfIsSelected(item) && "filled"}
              color={getIfIsSelected(item) ? gaColors?.primary700 : "#1E1E20"}
            />
          ) : null}
          <span className={cx("buttonSM neutral1000")}> {t(item.label)}</span>
        </div>
        <div onClick={(e) => handleSelectOption(e, item)}>
          <GaImage
            className={`${
              getIfIsExpanded() ? styles.active : styles.inactive
            } ${styles.angle}`}
            image={gaImages.angleDown}
          />
        </div>
      </div>
      {getIfIsExpanded() ? (
        <ul className={`${styles.expandableMenu__list}`}>
          {item?.subRoutes?.map((i, index) => {
            return !(i.id === "support") ? (
              <li
                key={"expandable__" + i.label + index + i.route}
                onClick={(event) =>
                  getIsActive(i) ? navigateTo(event, i.route) : null
                }
                className={`${cx(
                  !getIsActive(i) ? styles.expandableMenu__link__disabled : null
                )} ${styles.expandableMenu__link} ${
                  item.subRoutes ? "column" : "row"
                }
              `}
              >
                <div className={`${getIfIsSelected(i) ? styles.selected : ""}`}>
                  {!getIsActive(i) ? (
                    <>
                      <HoverTooltip
                        positionMobile={{
                          align: "center",
                          direction: "bottom",
                        }}
                        Label={
                          <>
                            {i.icon ? (
                              <GaImage image={i.icon} className={styles.icon} />
                            ) : null}
                            <span className={cx("buttonSM neutral1000")}>
                              {t(i.label)}
                            </span>
                          </>
                        }
                        info={"general.notNecessaryPermission"}
                        blue={true}
                      />
                    </>
                  ) : (
                    <span className={cx("buttonSM neutral1000")}>
                      {t(i.label)}
                    </span>
                  )}
                </div>
              </li>
            ) : (
              <a
                key={"expandableLink__" + i.label + index + i.route}
                className={`${cx(
                  !getIsActive(i) ? styles.expandableMenu__link__disabled : null
                )} ${styles.expandableMenu__link} ${
                  item.subRoutes ? "column" : "row"
                }`}
                href={supportLink}
                target="_blank"
              >
                <div>
                  <li
                    className={`${cx(
                      !getIsActive(i)
                        ? styles.expandableMenu__link__disabled
                        : null
                    )} ${styles.expandableMenu__link} ${
                      item.subRoutes ? "column" : "row"
                    }`}
                    key={i.route + i.id + index}
                  >
                    {i.icon ? (
                      <GaImage
                        className={styles.menuOptions__icon}
                        image={i.icon}
                      />
                    ) : null}
                    <span
                      className={`${selected ? "font-weight-bold" : ""} ${cx(
                        "buttonSM neutral1000"
                      )}`}
                    >
                      {t(i.label)}
                    </span>
                  </li>
                </div>
              </a>
            );
          })}
        </ul>
      ) : null}
    </>
  );
};

export default ExpandableMenu;
