import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styles from "./gaDatePicker.module.scss";
import { userHasScopes } from "../../../../../utils/permissionsUtil";
import { sessionSelectors } from "../../../../../features/Settings/Profile/store";
import InputSkeleton from "../../../skeletons/inlineSkeleton/InputSkeleton";
import PermissionsPanel from "../../../panels/permissionsPanel/PermissionsPanel";
import { PermissionsDataModel } from "../../../../../interfaces/interfaces";

type GaDatePickerProps = {
  id?: string;
  labelText?: string;
  value?: string;
  disabled?: boolean;
  readOnly?: boolean;
  name?: string;
  min?: string;
  max?: string;
  required?: boolean;
  className?: string;
  autoComplete?: string;
  isLoading?: boolean;
  permissions?: PermissionsDataModel;
  onChange?: (e?: any) => void;
};

const GaDatePicker: React.FC<GaDatePickerProps> = React.memo((props) => {
  const {
    id,
    labelText,
    className,
    disabled,
    name,
    value,
    permissions,
    min,
    max,
    required,
    readOnly,
    isLoading,
    onChange,
  } = props;
  const { t } = useTranslation();

  const availableScopes = useSelector(sessionSelectors?.getAllowedScopes);
  const hasScopes = !permissions?.scopes
    ? true
    : availableScopes
    ? userHasScopes(
        permissions?.scopes || [],
        availableScopes,
        permissions?.every
      )
    : false;

  useEffect(() => {}, [availableScopes]);

  return (
    <div
      className={`${className} ${styles.gaDatePickerContainer} ${
        required ? styles.required : ""
      } ${disabled || readOnly ? styles.readOnly : ""}`}
    >
      {!permissions?.scopes || hasScopes ? (
        !isLoading ? (
          <div className={styles.gaDatePicker}>
            {labelText ? <label>{t(labelText)}</label> : null}
            <input
              id="datefield"
              type="date"
              min={min}
              max={max}
              onInvalid={() => console.log("invalid")}
              disabled={disabled}
              required
              name={name}
              value={value}
              onChange={(e) => onChange && onChange(e)}
            ></input>
          </div>
        ) : (
          <InputSkeleton
            className={className || ""}
            labelText={labelText}
            required={required}
          />
        )
      ) : (
        <PermissionsPanel
          scopes={permissions?.scopes}
          labelText={labelText}
          requiredLabel={required}
        />
      )}
    </div>
  );
});

export default GaDatePicker;
